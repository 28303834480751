import { fetch } from '../../utils/fetch'
import { ApplicationException } from '../../components/common/ApplicationException'
import {
  EnrichedUser,
  ExistingUserForUpdate,
  singleSortQueryFromPaginatedRequestParams,
  UsersRequestParams,
} from '../nm-types'
import { Group, ListResult, LoginResult, NewUser, User, UserFilter } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { omit } from 'common/api/v1/helpers'

export interface IUserApi {
  createUser(user: NewUser): Promise<User>

  getUser(userId: User['id']): Promise<EnrichedUser>

  getUsers(params: UsersRequestParams): Promise<ListResult<EnrichedUser>>

  loginUser(username: string, password: string, otp?: string): Promise<EnrichedUser>

  logoutUser(): Promise<boolean>

  impersonateUser(userId: User['id']): Promise<EnrichedUser>

  stopImpersonation(): Promise<EnrichedUser>

  removeUser(userID: string): Promise<{ id: string }>

  updateUser(user: ExistingUserForUpdate): Promise<User>

  // /api/current-user
  getCurrentEnrichedUser(): Promise<EnrichedUser>
  configureTOTP(): ReturnType<EdgeClient['totpCurrentUsers']>
  verifyTOTP(token: string): Promise<boolean>
  deleteTOTP(): Promise<void>
}

export class UserApi implements IUserApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async loginUser(username: string, password: string, otp?: string): Promise<EnrichedUser> {
    const res = await fetch<LoginResult>('/api/login/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, otp }),
    })
    if (res.status === 200) {
      const result: LoginResult = res.body
      const userGroup = await this.edgeClient.getGroup(result.user.group)
      return {
        ...result.user,
        _group: omit(userGroup, 'applianceSecret'),
      }
    }
    throw new ApplicationException({
      fatal: true,
      errorCode: 'authentication_fail',
      details: 'Failed to login',
      text: 'Failed to login',
      origin: res,
    })
  }

  async logoutUser(): Promise<boolean> {
    const { success } = await this.edgeClient.logout()
    return success
  }

  async impersonateUser(userId: User['id']): Promise<EnrichedUser> {
    const { user } = await this.edgeClient.impersonate(userId)
    const userGroup = await this.edgeClient.getGroup(user.group)
    return {
      ...user,
      _group: omit(userGroup, 'applianceSecret'),
    }
  }

  async stopImpersonation(): Promise<EnrichedUser> {
    const { user } = await this.edgeClient.unimpersonate()
    const userGroup = await this.edgeClient.getGroup(user.group)
    return {
      ...user,
      _group: omit(userGroup, 'applianceSecret'),
    }
  }

  createUser(user: NewUser): Promise<User> {
    return this.edgeClient.createUser(user)
  }

  removeUser(userID: string): Promise<{ id: string }> {
    return this.edgeClient.deleteUser(userID)
  }

  updateUser(user: ExistingUserForUpdate): Promise<User> {
    return this.edgeClient.updateUser(user.id, user)
  }

  /**
   * Returns user with group object populated
   * @param userId
   */
  async getUser(userId: string): Promise<EnrichedUser> {
    const user = await this.edgeClient.getUser(userId)
    return {
      ...user,
      _group: await this.edgeClient.getGroup(user.group),
    }
  }

  /**
   * Returns ListResult of users with group object populated
   * @param userId
   */
  async getUsers({ owner, filter: searchName, ...params }: UsersRequestParams): Promise<ListResult<EnrichedUser>> {
    const filter: UserFilter = { group: owner, searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { items: users, total } = await this.edgeClient.listUsers(query)

    const groupIds = users.map((user) => user.group)
    const groups = (await this.edgeClient.listGroups({ filter: { ids: Array.from(new Set(groupIds)) } })).items

    return {
      items: users.map((user) => ({
        ...user,
        _group: groups.find(({ id }) => id === user.group) as Group,
      })),
      total,
    }
  }

  async getCurrentEnrichedUser() {
    const currentUser = await this.edgeClient.getCurrentUser()
    const group = await this.edgeClient.getGroup(currentUser.group)
    return { ...currentUser, _group: group }
  }

  async configureTOTP() {
    return this.edgeClient.totpCurrentUsers()
  }

  async verifyTOTP(token: string) {
    return this.edgeClient
      .setCurrentUserTotpVerify({ token })
      .then(() => true)
      .catch(() => false)
  }

  async deleteTOTP() {
    await this.edgeClient.deleteCurrentUserTotps()
  }
}
