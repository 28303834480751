import { BroadcastStandard, HandoverMethod, GlobalSettings, LogLevel } from 'common/api/v1/types'
import { ISettingsApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'

const globalSettings: GlobalSettings = {
  ristTunnelBasePort: 20000,
  ntpEnabled: true,
  ntpServer: 'pool.ntp.org',
  vaUpdateUrl: '',
  logLevel: LogLevel.info,
  defaultDelay: 1000,
  zixiFeederKey: '',
  zixiReceiverKey: '',
  defaultBroadcastStandard: BroadcastStandard.dvb,
  defaultHandoverMethod: HandoverMethod.udp,
  showAllNimbra400Alarms: false,
  sslRedirectEnabled: false,
  expFeatures: {},
}

export class MockSettingsApi implements ISettingsApi {
  getSettings(): Promise<GlobalSettings> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(globalSettings)
      }, MOCK_DELAY_MS),
    )
  }
  saveSettings(): Promise<GlobalSettings> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(globalSettings)
      }, MOCK_DELAY_MS),
    )
  }
}
