import React, { useCallback, useEffect } from 'react'
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from 'formik'
import { get } from 'lodash'
import Grid from '@mui/material/Grid'

import {
  ArrayKeys,
  AudioStream,
  DecoderFeatures,
  EncoderFeatures,
  GeneralEncoderSettings,
  RawVideo,
  SupportedAudioCodec,
  SupportedVideoCodec,
  VideoCodec,
} from 'common/api/v1/types'
import { Checkbox, Paper, Select, TextInput } from './Form'
import AudioStreamForm from '../inputs/Edit/AudioStream'
import { EnrichedInputWithEnrichedPorts } from '../inputs/Edit'
import { RichOption } from 'src/components/common/Form/Select'
import { assertExclude } from 'common/util'
import { applyCodecRestrictions, isNameValueOption } from 'common/api/v1/helpers'

const initialAudioStream = {
  codec: '',
  pair: '',
  bitrate: '',
  type: 'stereo',
  bitDepth: '',
} as unknown as AudioStream

const AudioStreamsPick: React.FunctionComponent<
  FieldArrayRenderProps & {
    supportedCodecs: SupportedAudioCodec[]
  }
> = ({ form, name, remove, supportedCodecs }) => {
  const streams = get(form, `values.${name}`)
  return (
    <Grid item xs={12}>
      {streams &&
        streams.length > 0 &&
        streams.map((audioStream: AudioStream, index: number) => (
          <AudioStreamForm
            key={index}
            audioStream={audioStream}
            namePrefix={`${name}.${index}`}
            supportedCodecs={supportedCodecs}
            onRemove={() => remove(index)}
            setFieldValue={form.setFieldValue}
          />
        ))}
    </Grid>
  )
}

interface EncoderSettingsProps {
  namePrefix: string
  encoderFeatures?: EncoderFeatures
  decoderFeatures?: DecoderFeatures
  setFieldValue: FormikProps<any>['setFieldValue']
}

function makeOptions(
  propertyName: ArrayKeys<SupportedVideoCodec>,
  codecFeatures: SupportedVideoCodec | undefined,
  encoderSettings: GeneralEncoderSettings,
): RichOption[] {
  const allOptions = codecFeatures?.[propertyName]
  if (!allOptions || !Array.isArray(allOptions) || allOptions.length === 0) {
    return []
  }
  const allowedOptions = applyCodecRestrictions(allOptions, propertyName, encoderSettings, codecFeatures?.restrictions)
  return allOptions.map((o): RichOption => {
    const hasNameAndValue = isNameValueOption(o)
    const name = hasNameAndValue ? o.name : o.toString()
    const value = hasNameAndValue ? o.value : o
    const disabled = !allowedOptions.some((allowed) => value === (isNameValueOption(allowed) ? allowed.value : allowed))
    return { name, value, disabled }
  })
}

const GeneralEncoderDecoderSettings = ({
  namePrefix,
  encoderFeatures,
  decoderFeatures,
  setFieldValue,
}: EncoderSettingsProps) => {
  const capabilities = encoderFeatures ?? decoderFeatures
  if (!capabilities) {
    throw new Error(`Missing props encoderFeatures and decoderFeatures`)
  }

  const { values } = useFormikContext<EnrichedInputWithEnrichedPorts>()
  const encoderSettings: GeneralEncoderSettings = get(values, namePrefix)
  if ('type' in encoderSettings) {
    throw new Error('Invalid encoder settings type: ' + (encoderSettings as any).type)
  }

  const maxNumberOfAudioStreamsAllowed = capabilities.audio.maxAudioStreams ?? 8
  const audioStreams: AudioStream[] = encoderSettings.audioStreams || []
  const canAddAudioStream = audioStreams.length < maxNumberOfAudioStreamsAllowed

  const codecKey = `${namePrefix}.videoCodec`
  const selectedVideoCodec = encoderSettings.videoCodec as VideoCodec | RawVideo
  const codecOptions: RichOption[] =
    capabilities.video.codecs?.map((c) => ({ name: c.name == RawVideo ? 'Uncompressed' : c.name, value: c.name })) ?? []
  useEffect(
    () => ensureSelectedValueIsAvailable(codecKey, selectedVideoCodec, codecOptions),
    [selectedVideoCodec, codecOptions],
  )
  const codecFeatures = capabilities.video.codecs.find((c) => c.name == selectedVideoCodec)

  const profileKey = `${namePrefix}.profile`
  const selectedProfile = encoderSettings.profile
  const profileOptions = makeOptions('profile', codecFeatures, encoderSettings)
  useEffect(
    () => ensureSelectedValueIsAvailable(profileKey, selectedProfile, profileOptions),
    [selectedProfile, profileOptions],
  )

  const pixelFormatKey = `${namePrefix}.pixelFormat`
  const selectedPixelFormat = encoderSettings.pixelFormat
  const pixelFormatOptions = makeOptions('pixelFormat', codecFeatures, encoderSettings)
  useEffect(
    () => ensureSelectedValueIsAvailable(pixelFormatKey, selectedPixelFormat, pixelFormatOptions),
    [selectedPixelFormat, pixelFormatOptions],
  )

  const resolutionKey = `${namePrefix}.resolution`
  const selectedResolution = encoderSettings.resolution
  const resolutionOptions = makeOptions('resolution', codecFeatures, encoderSettings)
  useEffect(
    () => ensureSelectedValueIsAvailable(resolutionKey, selectedResolution, resolutionOptions),
    [selectedResolution, resolutionOptions],
  )

  const scanRateKey = `${namePrefix}.scanRate`
  const selectedScanRate = encoderSettings.scanRate
  const scanRateOptions = makeOptions('scanRate', codecFeatures, encoderSettings)
  useEffect(
    () => ensureSelectedValueIsAvailable(scanRateKey, selectedScanRate, scanRateOptions),
    [selectedScanRate, scanRateOptions],
  )

  const scanKey = `${namePrefix}.scan`
  const selectedScan = encoderSettings.scan
  const scanOptions = makeOptions('scan', codecFeatures, encoderSettings)
  useEffect(() => ensureSelectedValueIsAvailable(scanKey, selectedScan, scanOptions), [selectedScan, scanOptions])

  const bitDepthKey = `${namePrefix}.bitDepth`
  const selectedBitDepth = encoderSettings.bitDepth
  const bitDepthOptions = makeOptions('bitDepth', codecFeatures, encoderSettings)
  useEffect(
    () => ensureSelectedValueIsAvailable(bitDepthKey, selectedBitDepth, bitDepthOptions),
    [selectedBitDepth, bitDepthOptions],
  )

  const colorSamplingKey = `${namePrefix}.colorSampling`
  const selectedColorSampling = encoderSettings.colorSampling
  const colorSamplingOptions = makeOptions('colorSampling', codecFeatures, encoderSettings)
  useEffect(
    () => ensureSelectedValueIsAvailable(colorSamplingKey, selectedColorSampling, colorSamplingOptions),
    [selectedColorSampling, colorSamplingOptions],
  )

  const latencyModeKey = `${namePrefix}.latencyMode`
  const selectedLatencyMode = encoderSettings.latencyMode
  const latencyModeOptions: RichOption[] = capabilities.video.latencyModes ?? []
  useEffect(
    () => ensureSelectedValueIsAvailable(latencyModeKey, selectedLatencyMode, latencyModeOptions),
    [selectedLatencyMode, latencyModeOptions],
  )

  const scalingModeKey = `${namePrefix}.scalingMode`
  const selectedScalingMode = encoderSettings.scalingMode
  const scalingModeOptions: RichOption[] = capabilities.video.scalingModes ?? []
  useEffect(
    () => ensureSelectedValueIsAvailable(scalingModeKey, selectedScalingMode, scalingModeOptions),
    [selectedScalingMode, scalingModeOptions],
  )

  const videoFlags = capabilities.video.flags || []
  const enabledVideoFlags = videoFlags.filter(
    (flag) =>
      codecFeatures !== undefined && !flag.disabledForCodecs.includes(assertExclude(codecFeatures?.name, RawVideo)),
  )
  for (const flag of videoFlags) {
    if (!(flag.value in (encoderSettings?.videoFlags || {})) || !enabledVideoFlags.includes(flag)) {
      if (encoderSettings) {
        if (!encoderSettings.videoFlags) {
          encoderSettings.videoFlags = {}
        }
        encoderSettings.videoFlags[flag.value] = false
      }
    }
  }

  useEffect(() => {
    const hasTooManyAudioStreams = audioStreams.length > maxNumberOfAudioStreamsAllowed
    if (hasTooManyAudioStreams) {
      const deleteCount = audioStreams.length - maxNumberOfAudioStreamsAllowed //8 - 1
      const deleteFromIndex = audioStreams.length - deleteCount
      audioStreams.splice(deleteFromIndex, deleteCount)
      setFieldValue(`${namePrefix}.audioStreams`, [...audioStreams], false)
    }
  }, [audioStreams, maxNumberOfAudioStreamsAllowed, setFieldValue])

  const ensureSelectedValueIsAvailable = useCallback(
    (formikFieldKey: string, currentValue: RichOption['value'], availableValues: RichOption[]) => {
      const isCurrentlySelectedOptionAvailable = availableValues
        .filter((o) => !o.disabled)
        .map((o) => o.value)
        .includes(currentValue)
      const firstAvailableValue = availableValues[0]?.value
      if (!isCurrentlySelectedOptionAvailable && firstAvailableValue !== undefined) {
        setFieldValue(formikFieldKey, firstAvailableValue, false)
      }
    },
    [setFieldValue],
  )

  return (
    <>
      <Paper className="outlined" title={`${encoderFeatures ? 'Encoder' : 'Decoder'} Settings`} collapsible>
        <Grid item xs={12}>
          <Paper>
            <Select
              label="Video codec"
              name={codecKey}
              required
              options={codecOptions}
              disabled={!!selectedVideoCodec && codecOptions.length === 1}
              validators={{
                oneOf: { validValues: new Set(codecOptions.filter((o) => !o.disabled).map((o) => o.value)) },
              }}
            />

            {profileOptions.length > 0 && (
              <Select
                label="Profile"
                name={profileKey}
                required
                options={profileOptions}
                disabled={!!selectedProfile && profileOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(profileOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {pixelFormatOptions.length > 0 && (
              <Select
                label="Pixel format"
                name={pixelFormatKey}
                required
                options={pixelFormatOptions}
                disabled={!!selectedPixelFormat && pixelFormatOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(pixelFormatOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {resolutionOptions.length > 0 && (
              <Select
                label="Resolution"
                name={resolutionKey}
                required
                options={resolutionOptions}
                disabled={!!selectedResolution && resolutionOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(resolutionOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {scanRateOptions.length > 0 && (
              <Select
                label="Frame rate"
                name={scanRateKey}
                required
                options={scanRateOptions}
                disabled={!!selectedScanRate && scanRateOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(scanRateOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {scanOptions.length > 0 && (
              <Select
                label="Scan"
                name={scanKey}
                required
                options={scanOptions}
                disabled={!!selectedScan && scanOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(scanOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {bitDepthOptions.length > 0 && (
              <Select
                label="Bit depth"
                name={bitDepthKey}
                required
                options={bitDepthOptions}
                disabled={!!selectedBitDepth && bitDepthOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(bitDepthOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {colorSamplingOptions.length > 0 && (
              <Select
                label="Color sampling"
                name={colorSamplingKey}
                required
                options={colorSamplingOptions}
                disabled={!!selectedColorSampling && colorSamplingOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(colorSamplingOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {latencyModeOptions.length > 0 && (
              <Select
                label="Latency mode"
                name={latencyModeKey}
                required
                options={latencyModeOptions}
                disabled={!!selectedLatencyMode && latencyModeOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(latencyModeOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {scalingModeOptions.length > 0 && (
              <Select
                label="Scaling mode"
                name={scalingModeKey}
                required
                options={scalingModeOptions}
                disabled={!!selectedScalingMode && scalingModeOptions.length === 1}
                validators={{
                  oneOf: { validValues: new Set(scalingModeOptions.filter((o) => !o.disabled).map((o) => o.value)) },
                }}
              />
            )}

            {selectedVideoCodec !== RawVideo && (
              <TextInput
                label="Total bitrate (Mbps)"
                name={`${namePrefix}.totalBitrate`}
                required
                type="number"
                noNegative
                validators={{
                  number: {
                    greaterThanOrEqualTo: 1,
                    lessThanOrEqualTo: 60,
                    message: `Must be 1 - 60`,
                    noStrings: false,
                  },
                }}
              />
            )}

            {selectedVideoCodec !== RawVideo && (
              <TextInput
                name={`${namePrefix}.gopSizeFrames`}
                label="GOP Size Frames"
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: 1,
                    lessThanOrEqualTo: 500,
                    message: `Must be 1 - 500`,
                    noStrings: false,
                  },
                }}
              />
            )}

            {encoderSettings?.videoFlags &&
              enabledVideoFlags.map((flag) => (
                <Checkbox
                  disabled={
                    codecFeatures !== undefined &&
                    flag.disabledForCodecs.includes(assertExclude(codecFeatures.name, RawVideo))
                  }
                  key={flag.value}
                  name={`${namePrefix}.videoFlags.${flag.value}`}
                  label={flag.name}
                />
              ))}
          </Paper>
        </Grid>
      </Paper>
      <Paper
        className="outlined"
        title="Audio Streams"
        collapsible
        actionsPane={
          canAddAudioStream
            ? [
                {
                  title: 'Add Audio Stream',
                  onClick: () =>
                    setFieldValue(`${namePrefix}.audioStreams`, audioStreams.concat({ ...initialAudioStream }), false),
                  id: 'add-audio-btn',
                },
              ]
            : []
        }
      >
        <FieldArray
          name={`${namePrefix}.audioStreams`}
          render={(formikArrayHelpers: FieldArrayRenderProps) => {
            return <AudioStreamsPick {...formikArrayHelpers} supportedCodecs={capabilities.audio.codecs || []} />
          }}
        />
      </Paper>
    </>
  )
}

export default GeneralEncoderDecoderSettings
