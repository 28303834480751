import fastSafeStringify from 'fast-safe-stringify'

const bigintRegex = /^bigint\((-?[\d]*)\)$/
const buffer64Regex = /^buffer64\(-?([\d]*)\)$/

export function bigIntReplacer(key: string, value: any) {
    void key
    if (typeof value === 'bigint') {
        return `bigint(${value.toString()})`
    }
    return value
}

export function bigIntAndDateAndBufferReviver(this: any, key: string, value: any) {
    if (typeof value === 'string') {
        const bigIntMatch = bigintRegex.exec(value)
        if (bigIntMatch) {
            return BigInt(bigIntMatch[1])
        }
        const bufferMatch = buffer64Regex.exec(value)
        if (bufferMatch) {
            return base64ToUint8Array(bufferMatch[1])
        }
        if (key.endsWith('At') || key.endsWith('Date')) {
            return new Date(value)
        }
    }
    return value
}

export function base64ToUint8Array(base64: string) {
    if (globalThis.Buffer) {
        return globalThis.Buffer.from(base64, 'base64')
    } else {
        return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0))
    }
}

export function jsonStringify(o: any, space?: string | number) {
    // TODO: Use fastSafeStringify instead?
    return JSON.stringify(o, bigIntReplacer, space)
}

export function jsonParse<T>(s: string) {
    return JSON.parse(s, bigIntAndDateAndBufferReviver) as T
}

export function tryStringify(o: any): string | undefined {
    try {
        return typeof o === 'string' ? o : fastSafeStringify(o)
    } catch {
        // E.g. due circular dependencies
        return undefined
    }
}

const isISOString = (s: string): boolean => {
    return typeof s === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?$/.test(s)
}

/**  Covert ISOString to Date recursively */
export function tryConvertDates(item: any): any {
    if (!item) return item

    if (Array.isArray(item)) {
        return item.map(tryConvertDates)
    } else if (typeof item === 'object') {
        for (const key of Object.keys(item)) {
            item[key] = tryConvertDates(item[key])
        }
        return item
    } else if (isISOString(item)) {
        return item.endsWith('Z') ? new Date(item) : new Date(item + '.000Z')
    } else {
        return item
    }
}
