import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'

import { ListResult, AuditLog } from 'common/api/v1/types'
import { withDefaultPagination } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'
import { enqueueErrorSnackbar } from './notificationActions'

export const getAudit = createAsyncThunk<ListResult<AuditLog>, Partial<PaginatedRequestParams>, ThunkApi>(
  'audit/getAudit',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.auditApi.getAudit(withDefaultPagination(params))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch audit log' }))
      throw err
    }
  },
)
