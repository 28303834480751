import { useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import Thumbnail from '../../../../common/Thumbnail'
import { styles as itemStyles } from './style'
import { setInputOfOutput } from '../../../../../redux/actions/outputsActions'
import { useConfirmationDialog } from '../../../../../utils'
import { inputOutputColor } from '../../../../../utils'
import { EnrichedOutput } from '../../../../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import { AppDispatch } from 'src/store'

const styles = {
  item: {
    margin: (theme: Theme) => theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '50px',
  },
  card: (theme: Theme) => ({
    display: 'flex',
    transition: 'transform .1s, opacity 0.2s',
    margin: theme.spacing(1, 0),
    opacity: 1,
    '&.hovered': {
      opacity: 0.4,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '&:hover .inputRemove': (theme: Theme) => ({
      transform: 'translateX(0)',
      [theme.breakpoints.up('sm')]: {
        boxShadow: `-15px 0px 22px 5px ${theme.palette.background.paper}`,
      },
    }),
  }),
  inputRemove: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '50px',
    height: '100%',
    transform: 'translateX(100%)',
    transition: 'all .2s',
    background: 'inherit',
    boxShadow: (theme: Theme) => `0 0 0 0 ${theme.palette.background.paper}`,
    '&.shown': {
      transform: 'translateX(0)',
    },
    display: 'flex',
    alignItems: 'center',
  },
  thumbnail: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: '90px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  }),
  content: {
    padding: (theme: Theme) => theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  empty: {
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface OutputProps {
  output: EnrichedOutput
  isHovered: boolean
}

const Item = ({ output, isHovered }: OutputProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const setConfirmation = useConfirmationDialog()
  const [showClear, setShowClear] = useState(false)
  const onClearInput = () => {
    setShowClear(true)
    setConfirmation(
      () => void dispatch(setInputOfOutput({ output, inputId: undefined })),
      'Do you want to clear the input of this output?',
      undefined,
      () => setShowClear(false),
    )
  }

  const input = output._input
  const format = getFormattedTransportStreamContent((input?.tsInfo || [])[0])
  return (
    <Box
      sx={{ ...styles.item, ...itemStyles.itemStyle, ...(isHovered ? itemStyles.itemHovered : {}) }}
      data-test-id="output"
    >
      <Typography
        variant="body1"
        color={isHovered ? 'primary' : output.adminStatus ? 'textPrimary' : 'textSecondary'}
        data-test-id="output-title"
      >
        {output.name}
      </Typography>
      <div>
        {input ? (
          <>
            <Box sx={styles.container}>
              <Card
                sx={styles.card}
                className={cn(isHovered && 'hovered')}
                data-test-id="input-streaming"
                style={{ position: 'relative', transform: isHovered ? 'translateX(50%)' : 'translateX(0)' }}
              >
                <Box sx={styles.thumbnail}>
                  <Thumbnail input={input} outputId={output.id} />
                </Box>
                <CardContent sx={styles.content}>
                  <Typography variant="body2" data-test-id="input-title" {...inputOutputColor(input)}>
                    {input.name}
                  </Typography>
                </CardContent>
                <Box sx={styles.inputRemove} className={cn('inputRemove', showClear && 'shown')} title="Clear">
                  <IconButton aria-label="clear" onClick={onClearInput} edge="end">
                    <Close />
                  </IconButton>
                </Box>
              </Card>
            </Box>
            <Typography variant="subtitle2" color="textSecondary">
              {format}
            </Typography>
          </>
        ) : (
          <Typography
            sx={{ ...styles.content, ...styles.empty }}
            color="textSecondary"
            variant="caption"
            component="div"
          >
            {isHovered ? 'Drop to switch' : 'Waiting for input'}
          </Typography>
        )}
      </div>
    </Box>
  )
}

export default Item
