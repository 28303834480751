import { FC, ReactNode } from 'react'
import Grid from '@mui/material/Grid'

import { Paper } from '../../common/Form'

export interface InfoSectionProps {
  children?: ReactNode
  // display title
  title?: string

  // selector for tests
  id?: string

  tooltip?: string
}

export const InfoSection: FC<InfoSectionProps> = ({ children, title, id, tooltip }) => {
  return (
    <Paper id={id} title={title || 'Information'} tooltip={tooltip}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Paper>
  )
}
