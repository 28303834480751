import IconButton from '@mui/material/IconButton'
import { HighlightOff } from '@mui/icons-material'
import { useState } from 'react'
import { LocalizationProvider, DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { FilterProps } from './utils'
import { usePageParams } from '../../../utils'

/**
 * Date filter, setting both date and time
 * @param paramName - parameter name
 * @param label - input label to show
 */
export const DateTimePicker = ({
  paramName,
  label,
  minDate = new Date('2020-01-01'),
  maxDate = new Date(),
}: FilterProps) => {
  const [params, setPageParams] = usePageParams()
  const value = params[paramName] ?? new Date().toISOString()
  const [open, setOpen] = useState(false)

  const handleOnClickClearIcon: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    setPageParams({ [paramName]: undefined })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} key={`filter-${paramName}`}>
      <MuiDateTimePicker<Date>
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={label}
        format="yyyy-MM-dd hh:mm a"
        value={new Date(value)}
        onChange={(value) => value && setPageParams({ [paramName]: value.toISOString(), pageNumber: '0' })}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture
        slotProps={{
          actionBar: {
            actions: ['cancel', 'accept'],
          },
          textField: {
            onClick: () => setOpen(true),
            InputProps: {
              endAdornment: (
                <IconButton aria-label="clear" onClick={handleOnClickClearIcon} edge="end">
                  <HighlightOff />
                </IconButton>
              ),
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}
