import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'

import { ListResult, AlarmLog } from 'common/api/v1/types'
import { withDefaultPagination } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'
import { enqueueErrorSnackbar } from './notificationActions'

export const getAlarmLogs = createAsyncThunk<ListResult<AlarmLog>, Partial<PaginatedRequestParams>, ThunkApi>(
  'audit/getAlarmLogs',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.alarmLogsApi.getAlarmLogs(withDefaultPagination(params))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch alarm log' }))
      throw err
    }
  },
)
