import { configureStore, isPlain } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'

import rootReducer from './redux/reducers'
import { Api as ProdApi, DevApi, IApi } from './api/Api'
import { loadState } from './localstorage'
import { BrowserEdgeClient } from './api/network'
import { makeRoutes, Routes } from './utils/routes'
import { createContext, useContext } from 'react'

// eslint-disable-next-line no-console
console.debug('API IN USE ', process.env.NODE_ENV !== 'production' ? 'DEV' : 'PROD')
const edgeClient = new BrowserEdgeClient({ baseUrl: '' })
let api: IApi = process.env.NODE_ENV !== 'production' ? new DevApi() : new ProdApi(edgeClient)
if (window.location.port === '3002') {
  // eslint-disable-next-line no-console
  console.debug(
    'Since port is 3002, use a non mocked api, served on the same host. (Usually nginx:ed in, see nginx.conf',
  )
  api = new ProdApi(edgeClient)
}
const persistedState = loadState()
/**
 * Container to have access to history from redux epics
 */
const historyContainer = {
  history: {} as RouteComponentProps['history'],
  setHistory(history: RouteComponentProps['history']) {
    Object.assign(this, { history })
  },
}

const getCachedUrlParamsFn = () => store.getState().urlParamReducer.cachedUrlParams
const routes = makeRoutes(getCachedUrlParamsFn)

const dependencies = {
  api,
  routes,
  setHistory(h: RouteComponentProps['history']) {
    historyContainer.setHistory(h)
  },
  history(): RouteComponentProps['history'] {
    return historyContainer.history
  },
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  devTools: true, // process.env.NODE_ENV !== 'production', // It's ok to always have it enabled
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { isSerializable: (value: unknown) => value instanceof Date || isPlain(value) }, // Allow Dates in the store since they are kind of serializable
      thunk: {
        extraArgument: dependencies,
      },
    }),
})

export const storeWithRoutes = {
  store,
  routes,
}

export const RouteContext = createContext<{
  routes: Routes
}>({ routes })

export const useRoutes = () => {
  const { routes } = useContext(RouteContext)
  return routes
}

export const Api: IApi = api

export type AppDispatch = typeof store.dispatch
export type GlobalState = ReturnType<typeof store.getState>
export type ThunkApi = { dispatch: AppDispatch; state: GlobalState; extra: typeof dependencies }

export default store
