import { GenericHttpError, HttpError, HttpStatusCode } from './http/httpError'

export enum ErrorCode {
    unknownError = 'unknownError',
    internalServerError = 'internalServerError',
    notImplementedError = 'notImplementedError',
    serviceUnavailableError = 'serviceUnavailable',
    originIsUnreachableError = 'originIsUnreachableError',
    portIsTaken = 'portIsTaken',
    notFound = 'notFound',
    timeout = 'timeout',
    conflict = 'conflict',
    badRequest = 'badRequest',
    unsupportedMediaType = 'unsupportedMediaType',
    unauthorized = 'unauthorized',
    mfaRequired = 'mfaRequired',
    forbidden = 'forbidden',
    payloadTooLarge = 'payloadTooLarge',
    gatewayTimeout = 'gatewayTimeout',

    noPortAccess = 'noPortAccess',
    outputDeniedNoServiceAccess = 'outputDeniedNoServiceAccess',
    outputInvalidNoSuchPort = 'outputInvalidNoSuchPort',

    serviceNotFound = 'serviceNotFound',
    invalidApplianceId = 'invalidApplianceId',

    userNotFound = 'userNotFound',

    missingParameter = 'missingParameter',
    usernameTaken = 'usernameTaken',
    nameTaken = 'nameTaken',

    invalidParameters = 'invalidParameters',
    tooManyLoginAttempts = 'tooManyLoginAttempts',
}

export function errorTitle(errorCode: ErrorCode) {
    return errorTitles[errorCode]
}

const errorTitles: { [key in ErrorCode]: string } = {
    [ErrorCode.portIsTaken]: 'The port is already taken',
    [ErrorCode.unknownError]: 'Unknown error',
    [ErrorCode.internalServerError]: 'Internal server error',
    [ErrorCode.notImplementedError]: 'Not Implemented',
    [ErrorCode.serviceUnavailableError]: 'Service is unavailable',
    [ErrorCode.originIsUnreachableError]: 'Origin is unreachable',
    [ErrorCode.notFound]: 'The resource could not be found',
    [ErrorCode.timeout]: 'The request timed out',
    [ErrorCode.conflict]: 'The request resulted in a conflict',
    [ErrorCode.badRequest]: 'The request was invalid',
    [ErrorCode.unsupportedMediaType]: 'The media type is not supported',
    [ErrorCode.unauthorized]: 'Authentication required',
    [ErrorCode.mfaRequired]: 'MFA required',
    [ErrorCode.forbidden]: 'Access denied',
    [ErrorCode.payloadTooLarge]: 'Payload too large',
    [ErrorCode.gatewayTimeout]: 'Gateway timeout',

    [ErrorCode.noPortAccess]: 'You are not the owner of this port',
    [ErrorCode.outputDeniedNoServiceAccess]:
        'The subscription was denied because the user group does not have access to the service',

    [ErrorCode.outputInvalidNoSuchPort]:
        'The subscription request was invalid because the specified port does not exist',
    [ErrorCode.serviceNotFound]: 'The service could not be found',
    [ErrorCode.invalidApplianceId]: 'The provided appliance id was invalid',

    [ErrorCode.userNotFound]: 'The user was not found',

    [ErrorCode.missingParameter]: 'One or more parameters are missing',
    [ErrorCode.usernameTaken]: 'The specified username was already taken',
    [ErrorCode.nameTaken]: 'The specified name was already taken',

    [ErrorCode.invalidParameters]: 'One or more parameters are invalid.',
    [ErrorCode.tooManyLoginAttempts]: 'Too many login attempts.',
}

function defaultTitle(type: ErrorCode) {
    return errorTitles[type]
}

export class EdgeApiHttpError extends HttpError {
    constructor(
        status: HttpStatusCode,
        public type: ErrorCode,
        title: string,
        detail: string | InvalidParameter[] = ''
    ) {
        super(status, type, title, detail)
    }
    toJSON() {
        const { status, title, detail, type } = this
        return { status, title: title || defaultTitle(type), detail, type }
    }

    toString() {
        return `Error: HTTP ${this.status} ${this.title}${
            this.detail ? ` (${typeof this.detail === 'object' ? JSON.stringify(this.detail) : this.detail})` : ''
        }`
    }
}

const errors: Map<HttpStatusCode, new (type?: ErrorCode, detail?: string, title?: string) => EdgeApiHttpError> =
    new Map()

export interface InvalidParameter {
    name: string
    reason: string
}

function createError(httpCode: HttpStatusCode, errorCode: ErrorCode) {
    const Cls = class extends EdgeApiHttpError {
        constructor(type: ErrorCode = errorCode, detail: string | InvalidParameter[] = '', title: string = '') {
            super(httpCode, type, title || defaultTitle(type), detail)
            Error.captureStackTrace(this, Cls)
        }
    }
    Object.defineProperty(Cls, 'name', { value: errorCode })
    errors.set(httpCode, Cls)
    return Cls
}

export function throwApiError(e: Error, apiError: EdgeApiHttpError) {
    if (e instanceof EdgeApiHttpError) {
        throw e
    } else {
        throw apiError
    }
}

export const NotFoundError = createError(HttpStatusCode.NotFound, ErrorCode.notFound)
export const TimeoutError = createError(HttpStatusCode.GatewayTimeout, ErrorCode.gatewayTimeout)
export const ConflictError = createError(HttpStatusCode.Conflict, ErrorCode.conflict)
export const ServerError = createError(HttpStatusCode.ServerError, ErrorCode.internalServerError)
export const NotImplementedError = createError(HttpStatusCode.NotImplemented, ErrorCode.notImplementedError)
export const BadRequestError = createError(HttpStatusCode.BadRequest, ErrorCode.badRequest)
export const UnsupportedMediaTypeError = createError(
    HttpStatusCode.UnsupportedMediaType,
    ErrorCode.unsupportedMediaType
)
export const UnauthorizedError = createError(HttpStatusCode.Unauthorized, ErrorCode.unauthorized)
export const ForbiddenError = createError(HttpStatusCode.Forbidden, ErrorCode.forbidden)
export const PayloadTooLargeError = createError(HttpStatusCode.PayloadTooLarge, ErrorCode.payloadTooLarge)
export const InvalidParametersError = createError(HttpStatusCode.BadRequest, ErrorCode.invalidParameters)
export const ServiceUnavailableError = createError(HttpStatusCode.ServiceUnavailable, ErrorCode.serviceUnavailableError)
export const OriginIsUnreachableError = createError(
    HttpStatusCode.OriginIsUnreachable,
    ErrorCode.originIsUnreachableError
)

export enum NetworkErrorCodes {
    ECONNRESET = 'ECONNRESET',
    ECONNREFUSED = 'ECONNREFUSED',
    ETIMEDOUT = 'ETIMEDOUT',
    ENOTFOUND = 'ENOTFOUND',
    EAI_AGAIN = 'EAI_AGAIN', // Will be returned on linux failing to resolve a .local address (while macos returns ENOTFOUND)
    EHOSTUNREACH = 'EHOSTUNREACH',
    ERR_INVALID_URL = 'ERR_INVALID_URL',
    DEPTH_ZERO_SELF_SIGNED_CERT = 'DEPTH_ZERO_SELF_SIGNED_CERT',
    OTHER = 'OTHER', // Since this list is not exhaustive, 'OTHER' will be returned in place of the actual error code for unmatched codes.
}

export class NetworkError extends Error {
    public code: string
    constructor(
        public message: string,
        protected originalErrorCode: NetworkErrorCodes,
        public cause?: any,
        constructorOpt?: any
    ) {
        super(message, { cause })
        if (!(originalErrorCode in NetworkErrorCodes)) {
            this.code = NetworkErrorCodes.OTHER
        } else {
            this.code = originalErrorCode
        }
        Error.captureStackTrace(this, constructorOpt || NetworkError)
    }
}

export class RequestTimeoutError extends Error {}

export const parseError = (
    error: NetworkError | GenericHttpError | RequestTimeoutError
): EdgeApiHttpError | NetworkError | RequestTimeoutError => {
    if (error instanceof NetworkError) {
        return error
    }
    if (error instanceof RequestTimeoutError) {
        return error
    }
    if (error instanceof GenericHttpError) {
        if (typeof error.body === 'object') {
            const httpStatus = 'status' in error.body ? error.body.status : error.status
            if (httpStatus) {
                const ErrorCls = errors.get(httpStatus)
                if (ErrorCls) {
                    return new ErrorCls(error.body.type, error.body.detail, error.body.title)
                } else {
                    return new EdgeApiHttpError(httpStatus, error.body.type, error.body.detail, error.body.title)
                }
            }
        } else {
            return new EdgeApiHttpError(
                (error && error.status) || 0,
                ErrorCode.unknownError,
                `HTTP ${error.status} - body parsing failed`
            )
        }
    }
    const otherError = error as any
    return new EdgeApiHttpError(otherError.status || 0, ErrorCode.unknownError, otherError.message || 'Unknown error')
}
