import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

const classes = {
  list: {
    width: '100%',
  },
  item: {
    padding: '0',
    alignItems: 'baseline',
  },
  title: {
    flexGrow: 1,
    fontSize: (theme: Theme) => `${theme.typography.h3.fontSize}`,
  },
  value: {
    fontSize: (theme: Theme) => `${theme.typography.h1.fontSize}`,
    fontWeight: 'bold',
    marginLeft: (theme: Theme) => theme.spacing(4),
    whiteSpace: 'nowrap',
  },
}

interface LineProps {
  title: React.ReactNode
  value: React.ReactNode
}
const Line = ({ title, value }: LineProps) => {
  return (
    <ListItem sx={classes.item}>
      <Typography component="div" sx={classes.title}>
        {title}
      </Typography>
      <Typography component="div" sx={classes.value}>
        {value}
      </Typography>
    </ListItem>
  )
}

interface DataSetProps {
  values: Array<{
    title: React.ReactNode
    value: React.ReactNode
  }>
}

/**
 * Simple dataset with short (mostly numbers) values
 * @param values - array of {title, value} objects
 */
const NumericalDataSet = ({ values }: DataSetProps) => {
  return (
    <List sx={classes.list}>
      {values.map(({ title, value }, ind) => (
        <Line key={ind} title={title} value={value} />
      ))}
    </List>
  )
}

export default NumericalDataSet
