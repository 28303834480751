import { Button, IconButton, Tooltip } from '@mui/material'
import { PIDRule, PIDRuleAction } from 'common/api/v1/types'
import { useFormikContext } from 'formik'
import { Select, TextInput } from '../../common/Form'
import Table, { TableConfig } from '../../common/Table'
import { InputFormProps } from './InputForm'
import DeleteIcon from '@mui/icons-material/Delete'
import { MissingContent } from '../../common/MissingContent'
import { RichOption } from 'src/components/common/Form/Select'

const options: RichOption[] = [
  {
    name: 'Map',
    value: PIDRuleAction.Map,
  },
  {
    name: 'Delete',
    value: PIDRuleAction.Delete,
  },
  {
    name: 'Set null',
    value: PIDRuleAction.SetNull,
  },
]

const pidMapRulesPrefix = 'deriveFrom.ingestTransform.pidMap.rules'
export const PidMapTable = () => {
  const { values, setFieldValue } = useFormikContext<InputFormProps>()

  // get a clone of rules from the formik state since we do not want to tamper with formik state directly
  const getPidMapRules = () => Array.from(values.deriveFrom?.ingestTransform?.pidMap?.rules ?? [])
  const rules = getPidMapRules()

  // TODO: better to use FieldArray and arrayHelpers from formik but not easy with the table structure we have
  const addRule = () => {
    const pidRules = [...rules, { action: 'map', pid: 0, destPid: 0 }]
    setFieldValue(pidMapRulesPrefix, pidRules)
  }

  const removeRule = (rule: PIDRule) => {
    const pidRules = rules.filter((r) => r !== rule)
    setFieldValue(pidMapRulesPrefix, pidRules)
  }

  const config: TableConfig<PIDRule> = [
    {
      title: 'action',
      getValue: (_rule, i) => {
        return (
          <Select
            sx={{ minWidth: '100px' }}
            name={`${pidMapRulesPrefix}.${i}.action`}
            options={options}
            variant="standard"
            hiddenLabel
            required
          />
        )
      },
    },
    {
      title: 'Source PID',
      getValue: (_rule, i) => (
        <TextInput
          type="number"
          name={`${pidMapRulesPrefix}.${i}.pid`}
          variant="standard"
          hiddenLabel
          required
          validators={{ pid: {} }}
        />
      ),
    },
    {
      title: 'Destination PID',
      getValue: (rule, i) => {
        if (rule.action !== 'map') {
          return 'N/A'
        }

        return (
          <TextInput
            type="number"
            name={`${pidMapRulesPrefix}.${i}.destPid`}
            hiddenLabel
            variant="standard"
            required
            validators={{ pid: {} }}
          />
        )
      },
    },
    {
      getValue: (rule) => {
        return (
          <Tooltip title={'Remove rule'} placement="top" style={{ marginRight: 16 }}>
            <IconButton edge="end" onClick={() => removeRule(rule)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      },
      props: { align: 'right' },
    },
  ]

  if (!values._derived) {
    return null
  }

  return (
    <Table
      config={config}
      disableToolbar={true}
      data={rules}
      emptyMessageComponent={<MissingContent message="No PID mapping" />}
      actions={[
        <Button key="add-pid-rule-button" variant="contained" color="primary" onClick={addRule}>
          Add Rule
        </Button>,
      ]}
    />
  )
}
