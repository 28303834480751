import { AuditLog, ListResult } from 'common/api/v1/types'
import { IAuditApi } from './api'
import { MOCK_AUDIT, MOCK_DELAY_MS } from '../MockData'
import { listResult } from '../../utils'

export class MockAuditApi implements IAuditApi {
  getAudit(): Promise<ListResult<AuditLog>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(listResult(MOCK_AUDIT))
      }, MOCK_DELAY_MS),
    )
  }
}
