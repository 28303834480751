function setPriorityBits(num: number, priority2bits: ChannelPriority) {
    // set bit 29 and 30 to encode the priority (we don't want to use bit 31 since it is the sign bit)
    return BigInt(num) | (BigInt(priority2bits) << 29n)
}

function getPriorityBits(num: number) {
    // get bit 29 and 30
    return (BigInt(num) >> 29n) & 3n
}

export function clear3HighOrderBitsOnInt32(num: number) {
    return Number(BigInt(num) & 0x1fffffffn)
}

export type ChannelPriority = 0 | 1 | 2 | 3

// Channel priority is encoded in the 2 high order bits of the channel
// in order to retain compatibility with the pre-existing channels
// which all have priority 0.
export function channelIdFromChannelGroupAndPriority(channelGroup: number, priority: ChannelPriority) {
    return Number(BigInt.asIntN(32, setPriorityBits(channelGroup, priority)))
}

export function priorityFromChannel(channelId: number) {
    return Number(getPriorityBits(channelId)) as ChannelPriority
}

export function channelGroupFromChannel(channelId: number) {
    return clear3HighOrderBitsOnInt32(channelId)
}
