import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { EnhancedInputConfig } from 'common/api/v1/internal'

export const readGraph = createAsyncThunk<EnhancedInputConfig, void, ThunkApi>(
  'graph/readGraph',
  async (_, { extra: { api } }) => {
    const response = await api.graphApi.readGraph()
    return response
  },
)
