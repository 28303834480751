import { createAsyncThunk } from '@reduxjs/toolkit'

import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'

export const ensureOperatorToken = createAsyncThunk<void, void, ThunkApi>(
  'apiTokens/ensureOperatorToken',
  async (_, { dispatch, extra: { api } }) => {
    try {
      await api.apiTokensApi.ensureOperatorToken()
      dispatch(
        enqueueSuccessSnackbar(
          'Successfully ensured the Edge Operator token. You can now proceed and install the Edge Operator.',
        ),
      )
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update tls settings' }))
    }
  },
)
