import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Paper } from './Form'

const styles = {
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  message: {
    textTransform: 'uppercase',
  },
  buttonWrapper: {
    margin: (theme: Theme) => theme.spacing(2),
  },
}

export interface FailedFetchingContentProps {
  message: string
  inline?: boolean
}

/**
 * Common component to show errors on empty list returned for entities list page
 * @param message - the message to show
 * @param buttonText - the text for the button that leads to create the entity instance
 * @param url - url for the entity creation form
 */
export const FailedFetchingContent = ({ message, inline }: FailedFetchingContentProps) => {
  const content = (
    <Box sx={styles.centered}>
      <Typography sx={styles.message} variant="h2">
        {message}
      </Typography>
      <Box sx={styles.buttonWrapper}>
        <Button variant="outlined" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </Box>
    </Box>
  )

  return inline ? content : <Paper>{content}</Paper>
}
