import { createSlice } from '@reduxjs/toolkit'

import {
  logoutUser,
  loginUser,
  setAuthorizationError,
  impersonateUser,
  stopImpersonation,
  fetchCurrentUser,
} from '../actions/userActions'
import { isOneOf } from '../actions'
import type { EnrichedUser } from '../../api/nm-types'
import { createLoadingReducer } from './shared'
import type { ErrorInfo } from '../../components/common/ApplicationException'

interface State {
  loading: boolean
  loginFail?: ErrorInfo
  user: EnrichedUser | unknown
  changingUser: boolean
}

const initialStateUser: State = { user: {}, loading: false, changingUser: false }

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(
  loginUser,
  logoutUser,
  impersonateUser,
  stopImpersonation,
  fetchCurrentUser,
)

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        setAuthorizationError,
        (state, { payload: loginFail }): State => ({ ...state, loginFail, changingUser: false }),
      )
      .addCase(loginUser.fulfilled, (state, { payload }): State => ({ ...state, user: payload, changingUser: false }))
      .addCase(logoutUser.fulfilled, (): State => initialStateUser)
      .addCase(
        impersonateUser.fulfilled,
        (state, { payload: user }): State => ({ ...state, user, changingUser: false }),
      )
      .addCase(
        stopImpersonation.fulfilled,
        (state, { payload: user }): State => ({ ...state, user, changingUser: false }),
      )
      .addCase(fetchCurrentUser.fulfilled, (state, { payload }): State => ({ ...state, user: payload }))
      .addMatcher(
        isOneOf([loginUser.pending, logoutUser.pending, impersonateUser.pending, stopImpersonation.pending]),
        (state): State => ({ ...state, changingUser: true }),
      )
      .addMatcher(
        isOneOf([loginUser.rejected, logoutUser.rejected, impersonateUser.rejected, stopImpersonation.rejected]),
        (state): State => ({ ...state, changingUser: false }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default userSlice.reducer
