import { ListResult, GroupRecipientList, Group } from 'common/api/v1/types'
import { IGroupListApi } from './api'
import { MOCK_DELAY_MS, MOCK_GROUP_LISTS, MOCK_GROUPS } from '../MockData'
import { listResult } from '../../utils'

export class MockGroupListApi implements IGroupListApi {
  createGroupList(): Promise<GroupRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_GROUP_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  updateGroupList(): Promise<GroupRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_GROUP_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  removeGroupList(): Promise<GroupRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_GROUP_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  getGroupList(): Promise<GroupRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_GROUP_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  getGroupLists(): Promise<ListResult<GroupRecipientList>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(listResult(MOCK_GROUP_LISTS))
      }, MOCK_DELAY_MS),
    )
  }

  getGroups(): Promise<ListResult<Group>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(listResult(MOCK_GROUPS))
      }, MOCK_DELAY_MS),
    )
  }
}
