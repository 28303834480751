import { Route } from 'react-router-dom'

import { Edit } from './Edit'
import { ListView as List } from './List'

import { useRoutes } from '../../store'

export const AddressMappings = () => {
  const routes = useRoutes()
  return (
    <>
      <Route path={routes.addressMappingsUpdate.route} component={Edit} />
      <Route path={routes.addressMappingsNew.route} component={Edit} />
      <Route exact path={routes.addressMappings.route} component={List} />
    </>
  )
}
