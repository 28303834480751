import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { Warning } from '@mui/icons-material'
import Stack from '@mui/material/Stack'

const styles = {
  title: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface ChangeSecondaryRegionDialogProps {
  applianceName: string
  newSecondaryRegionName: string
}

export const ChangeSecondaryRegionDialog = ({
  applianceName,
  newSecondaryRegionName,
}: ChangeSecondaryRegionDialogProps) => {
  return (
    <>
      <Typography variant="h3" color="textPrimary" sx={styles.title}>
        You are about to change secondary region for an appliance
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Warning />
        <Typography variant="h3">Warning!</Typography>
      </Stack>

      <Typography paragraph>
        Changing secondary region to &apos;{newSecondaryRegionName}&apos; for appliance &apos;{applianceName}&apos;
        cause temporary loss of redundancy for redundant inputs on the appliance.
      </Typography>

      <Typography paragraph>Are you sure?</Typography>
    </>
  )
}
