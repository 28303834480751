import type { AlarmFilter, AlarmUpdate, AlarmWithImpact, ListResult } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { singleSortQueryFromPaginatedRequestParams, type AlarmsRequestParams, type EnrichedAlarm } from '../nm-types'

export interface IAlarmApi {
  readAlarmsInBackground(): Promise<AlarmWithImpact[]>
  getAlarms(params: AlarmsRequestParams): Promise<ListResult<EnrichedAlarm>>
  updateAlarm(id: AlarmWithImpact['alarmId'], params: AlarmUpdate): ReturnType<EdgeClient['setAlarm']>
}

export class AlarmsApi implements IAlarmApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  /**
   * Gets alarms and populates each with affected entities
   */
  async readAlarmsInBackground(): Promise<AlarmWithImpact[]> {
    const alarms: Array<AlarmWithImpact> = (await this.edgeClient.listAlarms()).items

    return alarms
  }

  async getAlarms({
    filter: searchName,
    applianceId,
    ...params
  }: AlarmsRequestParams): Promise<ListResult<EnrichedAlarm>> {
    const filter: AlarmFilter = { searchName, applianceId }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })

    const { items: alarms, total } = await this.edgeClient.listAlarms(query)
    const { affectedInputs, affectedOutputs } = alarms.reduce<{
      affectedInputs: Set<string>
      affectedOutputs: Set<string>
    }>(
      (acc, alarm) => {
        if (alarm.affectedInput) {
          acc.affectedInputs.add(alarm.affectedInput)
        }
        if (alarm.affectedOutput) {
          acc.affectedOutputs.add(alarm.affectedOutput)
        }
        return acc
      },
      {
        affectedInputs: new Set(),
        affectedOutputs: new Set(),
      },
    )

    const inputs =
      affectedInputs.size > 0 ? (await this.edgeClient.listInputs({ filter: { ids: [...affectedInputs] } })).items : []
    const outputs =
      affectedOutputs.size > 0
        ? (await this.edgeClient.listOutputs({ filter: { ids: [...affectedOutputs] } })).items
        : []

    return {
      total,
      items: alarms.map((alarm) => {
        return {
          ...alarm,
          _input: alarm.affectedInput ? inputs.find(({ id }) => id === alarm.affectedInput) : undefined,
          _output: alarm.affectedOutput ? outputs.find(({ id }) => id === alarm.affectedOutput) : undefined,
        }
      }),
    }
  }

  async updateAlarm(
    alarmId: AlarmWithImpact['alarmId'],
    params: AlarmUpdate,
  ): ReturnType<typeof this.edgeClient.setAlarm> {
    const encodedAlarmId = encodeURIComponent(alarmId)
    return this.edgeClient.setAlarm(encodedAlarmId, params)
  }
}
