import { NetworksRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'
import { ListResult, Network, NetworkFilter } from 'common/api/v1/types'

export interface INetworksApi {
  createNetwork(network: Omit<Network, 'id'>): Promise<Network>
  getNetwork(id: Network['id']): Promise<Network>
  getNetworks(params: NetworksRequestParams): Promise<ListResult<Network>>
  removeNetwork(id: Network['id']): Promise<Network>
  updateNetwork(id: Network['id'], network: Omit<Network, 'id'>): Promise<Network>
}

export class NetworksApi implements INetworksApi {
  constructor(private readonly edgeClient: EdgeClient) {}
  createNetwork(network: Omit<Network, 'id'>) {
    return this.edgeClient.createNetwork(network)
  }
  getNetwork(id: Network['id']) {
    return this.edgeClient.getNetwork(id)
  }
  getNetworks({ filter, port, ids, ...params }: NetworksRequestParams) {
    const searchFilter: NetworkFilter = {}
    if (filter) searchFilter.searchName = filter
    if (port) searchFilter.port = port
    if (ids) searchFilter.ids = ids
    const query = singleSortQueryFromPaginatedRequestParams({
      filter: searchFilter,
      paginatedRequestParams: params,
    })
    return this.edgeClient.listNetworks(query)
  }
  removeNetwork(id: Network['id']) {
    return this.edgeClient.deleteNetwork(id)
  }
  updateNetwork(id: Network['id'], network: Omit<Network, 'id'>) {
    return this.edgeClient.updateNetwork(id, network)
  }
}
