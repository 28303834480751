import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import { VideoLibrary } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import { OutputRecipientList } from 'common/api/v1/types'
import { styles as itemStyles } from './style'

const styles = {
  listContainer: {
    position: 'relative',
    margin: (theme: Theme) => theme.spacing(1, 1, 3, 3),
  },
  listContent: {
    display: 'flex',
    padding: (theme: Theme) => theme.spacing(2),
    color: (theme: Theme) => theme.palette.text.secondary,
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: (theme: Theme) => theme.spacing(2),
    },
  },
  listItem: {
    position: 'relative',
  },
  listSign: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
}

interface OutputProps {
  output: OutputRecipientList
  isHovered: boolean
}
const OutputList = ({ output, isHovered }: OutputProps) => {
  return (
    <Box sx={styles.listContainer}>
      <Box sx={{ ...styles.listSign, ...itemStyles.itemStyle, top: '16px', left: '-16px' }} />
      <Box sx={{ ...styles.listSign, ...itemStyles.itemStyle, top: '8px', left: '-8px' }} />
      <Box
        sx={{ ...styles.listItem, ...itemStyles.itemStyle, ...(isHovered ? itemStyles.itemHovered : {}) }}
        data-test-id="output"
      >
        <Typography variant="body1" color={isHovered ? 'primary' : 'textPrimary'} data-test-id="output-title">
          {output.name}
        </Typography>
        <Box sx={styles.listContent}>
          <Icon>
            <VideoLibrary />
          </Icon>
          <Typography>{output.description}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default OutputList
