import {
    ApplianceFeatures,
    ApplianceType,
    ApplianceVersion,
    AudioCodec,
    AudioLayout,
    CoaxPortMode,
    ComprimatoPortMode,
    EdgeProduct,
    EncoderFeatures,
    FecLimits,
    IpPortMode,
    MatroxPortMode,
    Nimbra400VideoFlags,
    RawVideo,
    RistProfile,
    SrtMode,
    VideoCodec,
    VideonPortMode,
    VideonVideoFlags,
    ZixiMode,
} from './api/v1/types'
import { parseVaVersion, vaVersionIsGreaterOrEqual, VaVersions, vaVersionSupports12MTimeCode } from './vaVersion'
import { jsonParse, jsonStringify } from './serialization'
import { supportsFec } from './versions'

type ApplianceFeaturesFactory = (product: EdgeProduct, version?: ApplianceVersion) => ApplianceFeatures

export const standardFecSettings: FecLimits = {
    maxSize: 100,
    rowRange: {
        min: 4,
        max: 20,
    },
    colRange: {
        min: 1,
        max: 20,
    },
}

const nimbraVa220: ApplianceFeaturesFactory = (_product: EdgeProduct, version?: ApplianceVersion) => {
    const sdiInputCapabilities = {
        video: {
            codecs: [
                {
                    name: VideoCodec.h264,
                    bitrate: {
                        min: 1000000,
                        max: 30000000,
                    },
                },
                {
                    name: VideoCodec.h265,
                    bitrate: {
                        min: 1000000,
                        max: 30000000,
                    },
                },
            ],
            flags: vaVersionSupports12MTimeCode(version?.vaVersion)
                ? [
                      {
                          name: 'SMPTE 12M timecode',
                          value: Nimbra400VideoFlags.smpte12mTimecode,
                          disabledForCodecs: [VideoCodec.h265],
                      },
                  ]
                : [],
        },
        audio: {
            maxAudioStreams: 8,
            layout: [AudioLayout.mono, AudioLayout.stereo],
            codecs: [
                {
                    name: AudioCodec.aes3,
                    bitratesKbps: [1920],
                },
                {
                    name: AudioCodec.aacLc,
                    bitratesKbps: [96, 128, 192, 256, 384],
                },
                {
                    name: AudioCodec.mpeg2,
                    bitratesKbps: [96, 128, 192, 256, 384],
                },
                {
                    name: AudioCodec.heAac,
                    bitratesKbps: [96, 128],
                },
            ].concat(
                vaVersionIsGreaterOrEqual(parseVaVersion(version && version.vaVersion), VaVersions['VA-16.4.0.0'])
                    ? [
                          {
                              name: AudioCodec.ac3pt,
                              bitratesKbps: [192, 384, 448, 640],
                          },
                      ]
                    : []
            ),
        },
    }
    return {
        proxy: true,
        coaxPorts: 4,
        input: {
            modes: [
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.listener, SrtMode.rendezvous] },
                { mode: IpPortMode.udp },
                { mode: IpPortMode.zixi, subModes: [ZixiMode.pull, ZixiMode.push] },
                { mode: CoaxPortMode.sdi, encoder: sdiInputCapabilities },
                { mode: CoaxPortMode.asi },
            ],
        },
        output: {
            modes: [
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.listener, SrtMode.rendezvous] },
                { mode: IpPortMode.udp },
                { mode: IpPortMode.zixi, subModes: [ZixiMode.pull, ZixiMode.push] },
                { mode: CoaxPortMode.sdi },
                { mode: CoaxPortMode.asi },
            ],
        },
        fec: standardFecSettings,
    }
}

const nimbraVa225 = patchedCopy(nimbraVa220)
const nimbraVaDocker = patchedCopy(nimbraVa220, (v) => {
    v.coaxPorts = 0
    v.input = {
        ...v.input,
        modes: v.input?.modes.filter((m) => m.mode in IpPortMode) ?? [],
    }
    v.output = {
        ...v.output,
        modes: v.output?.modes.filter((m) => m.mode in IpPortMode) ?? [],
    }
})

const nimbra410 = patchedCopy(nimbraVaDocker)
const nimbra412 = patchedCopy(nimbraVa220, (v) => (v.coaxPorts = 2))
const nimbra414 = patchedCopy(nimbraVa220)

function patchedCopy<T>(
    factoryFn: (product: EdgeProduct, applianceVersion?: ApplianceVersion) => T,
    patch?: (v: T) => void
) {
    return (product: EdgeProduct, applianceVersion?: ApplianceVersion) => {
        const clone = jsonParse<T>(jsonStringify(factoryFn(product, applianceVersion)))
        patch?.(clone)
        return clone
    }
}

export const edgeConnect = (_product: EdgeProduct, version?: ApplianceVersion): ApplianceFeatures => {
    return {
        fec: version && supportsFec(ApplianceType.edgeConnect, version) ? standardFecSettings : undefined,
        input: {
            modes: [
                { mode: IpPortMode.generator },
                { mode: IpPortMode.rist, subModes: [RistProfile.simple] },
                { mode: IpPortMode.rtmp },
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
            ],
        },
        output: {
            modes: [
                { mode: IpPortMode.rist, subModes: [RistProfile.simple] },
                { mode: IpPortMode.rtmp },
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
            ],
        },
    }
}

export const core = (product: EdgeProduct, version?: ApplianceVersion): ApplianceFeatures => {
    return {
        fec: version && supportsFec(ApplianceType.core, version) ? standardFecSettings : undefined,
        input: {
            modes: [
                { mode: IpPortMode.generator },
                { mode: IpPortMode.rist, subModes: [RistProfile.simple] },
                { mode: IpPortMode.rtmp },
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
                ...(product === EdgeProduct.edge ? [{ mode: IpPortMode.zixi, subModes: [ZixiMode.pull] }] : []),
            ],
        },
        output: {
            modes: [
                { mode: IpPortMode.rist, subModes: [RistProfile.simple] },
                { mode: IpPortMode.rtmp },
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
                ...(product === EdgeProduct.edge ? [{ mode: IpPortMode.zixi, subModes: [ZixiMode.push] }] : []),
            ],
        },
    }
}

export const emptyFeatureSet = (_product: EdgeProduct, _?: ApplianceVersion) => ({})

const videon = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => {
    const inputCapabilities = {
        video: {
            latencyModes: [
                { name: 'High', value: 'HIGH' },
                { name: 'Normal', value: 'NORMAL' },
                { name: 'Low', value: 'LOW' },
                { name: 'Lowest', value: 'LOWEST' },
            ],
            scalingModes: [
                { name: 'Passthrough', value: 'RES_PASSTHROUGH' },
                { name: '320X180', value: 'RES_320X180' },
                { name: '480X270', value: 'RES_480X270' },
                { name: '640X360', value: 'RES_640X360' },
                { name: '720X576', value: 'RES_720X576' },
                { name: '960X540', value: 'RES_960X540' },
                { name: '1280X720', value: 'RES_1280X720' },
                { name: '1920X1080', value: 'RES_1920X1080' },
                { name: '3840X2160', value: 'RES_3840X2160' },
            ],
            codecs: [
                {
                    name: VideoCodec.h264,
                    profile: [
                        { name: 'Baseline Profile', value: 'PROFILE_BASELINE' },
                        { name: 'Main Profile', value: 'PROFILE_MAIN' },
                        { name: 'High Profile', value: 'PROFILE_HIGH' },
                    ],
                    bitrate: {
                        min: 1000000,
                        max: 30000000,
                    },
                },
                {
                    name: VideoCodec.h265,
                    bitrate: {
                        min: 1000000,
                        max: 30000000,
                    },
                    profile: [{ name: 'Main Profile', value: 'PROFILE_MAIN' }],
                },
            ],
            flags: [
                {
                    name: 'Limit to 30 FPS',
                    value: VideonVideoFlags.limitTo30Fps,
                    disabledForCodecs: [],
                },
                {
                    name: 'Enable KLV Timecode Insertion for Video Frames',
                    value: VideonVideoFlags.klvTimeCodeInsertion,
                    disabledForCodecs: [],
                },
            ],
        },
        audio: {
            maxAudioStreams: 1,
            layout: [AudioLayout.stereo],
            codecs: [
                {
                    name: AudioCodec.aacLc,
                    bitratesKbps: [64, 96, 128, 192, 256, 320, 384, 512],
                },
            ],
        },
    }
    return {
        proxy: false,
        coaxPorts: 1,
        input: {
            modes: [
                {
                    prettyName: 'sdi',
                    mode: VideonPortMode.videonSdi,
                    encoder: inputCapabilities,
                },
                {
                    prettyName: 'hdmi',
                    mode: VideonPortMode.videonHdmi,
                    encoder: inputCapabilities,
                },
                {
                    prettyName: 'auto',
                    mode: VideonPortMode.videonAuto,
                    encoder: inputCapabilities,
                },
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
            ],
        },
        output: {
            modes: [
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
            ],
        },
    }
}

const dummyCapabilities: EncoderFeatures = {
    video: { codecs: [] },
    audio: { maxAudioStreams: 1, layout: [], codecs: [] },
}
const matroxE4 = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => ({
    proxy: true,
    coaxPorts: 4,
    input: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
            { prettyName: 'sdi', mode: MatroxPortMode.matroxSdi, encoder: dummyCapabilities }, // Include 'encoder' to make NM render Matrox Encoder Settings component
        ],
    },
})

const matroxD4 = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => ({
    proxy: true,
    coaxPorts: 4,
    output: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
            { prettyName: 'sdi', mode: MatroxPortMode.matroxSdi, decoder: dummyCapabilities }, // Matrox decoder settings UI is embedded in the MatroxSdiPortForm component
        ],
    },
})

const matroxS1 = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => ({
    proxy: true,
    coaxPorts: 1,
    input: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
            { prettyName: 'sdi', mode: MatroxPortMode.matroxSdi, encoder: dummyCapabilities }, // Include 'encoder' to make NM render Matrox Encoder Settings component
        ],
    },
    output: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
            { prettyName: 'sdi', mode: MatroxPortMode.matroxSdi, decoder: dummyCapabilities }, // Matrox decoder settings UI is embedded in the MatroxSdiPortForm component
        ],
    },
})
const comprimato = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => {
    const inputCapabilities: EncoderFeatures = {
        video: {
            codecs: [
                {
                    name: VideoCodec.h264,
                    profile: [
                        { name: 'High', value: 'HIGH' },
                        { name: 'Main', value: 'MAIN' },
                        { name: 'Baseline (software encoder)', value: 'BASELINE' },
                    ],
                    colorSampling: [
                        { name: '4:2:0', value: '4:2:0' },
                        { name: '4:2:2', value: '4:2:2' },
                    ],
                    bitDepth: [8, 10],
                    resolution: [
                        { name: '720p', value: '720p' },
                        { name: '1080p', value: '1080p' },
                        { name: '480i', value: '480i' },
                        { name: '576i', value: '576i' },
                        { name: '1080i', value: '1080i' },
                    ],
                    scanRate: ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60'],
                    bitrate: {
                        min: 0,
                        max: 40000000,
                    },
                    restrictions: {
                        bitDepth: {
                            determinedBy: 'profile',
                            mapping: {
                                BASELINE: [8],
                                MAIN: [8],
                                HIGH: [8, 10],
                            },
                        },
                        colorSampling: {
                            determinedBy: 'profile',
                            mapping: {
                                BASELINE: ['4:2:0'],
                                MAIN: ['4:2:0'],
                                HIGH: ['4:2:0', '4:2:2'],
                            },
                        },
                    },
                },
                {
                    name: VideoCodec.h265,
                    profile: [
                        { name: 'Main', value: 'MAIN' },
                        { name: 'Main10', value: 'MAIN10' },
                    ],
                    colorSampling: [{ name: '4:2:0', value: '4:2:0' }],
                    bitDepth: [8, 10],
                    resolution: [
                        { name: '720p', value: '720p' },
                        { name: '1080p', value: '1080p' },
                    ],
                    scanRate: ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60'],
                    bitrate: {
                        min: 0,
                        max: 40000000,
                    },
                    restrictions: {
                        bitDepth: {
                            determinedBy: 'profile',
                            mapping: {
                                MAIN: [8],
                                MAIN10: [8, 10],
                            },
                        },
                        colorSampling: {
                            determinedBy: 'profile',
                            mapping: {
                                MAIN: ['4:2:0'],
                                MAIN10: ['4:2:0'],
                            },
                        },
                    },
                },
            ],
        },
        audio: {
            maxAudioStreams: 1,
            layout: [AudioLayout.stereo],
            codecs: [
                {
                    name: AudioCodec.aacAdts,
                    bitratesKbps: [64, 96, 128, 192, 256, 320, 384, 512],
                },
                {
                    name: AudioCodec.aacLatm,
                    bitratesKbps: [64, 96, 128, 192, 256, 320, 384, 512],
                },
                {
                    name: AudioCodec.mpeg2,
                    bitratesKbps: [64, 96, 128, 192, 256, 320, 384],
                },
                {
                    name: AudioCodec.aes3,
                    bitratesKbps: [],
                    bitDepth: [20, 24],
                },
                {
                    name: AudioCodec.ac3,
                    bitratesKbps: [192, 384, 448, 640],
                },
            ],
        },
    }

    return {
        proxy: true,
        input: {
            modes: [
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
                { prettyName: 'sdi', mode: ComprimatoPortMode.comprimatoSdi, encoder: inputCapabilities },
                { prettyName: 'ndi', mode: ComprimatoPortMode.comprimatoNdi, encoder: inputCapabilities },
            ],
        },
        output: {
            modes: [
                { mode: IpPortMode.rtp },
                { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
                { mode: IpPortMode.udp },
                {
                    prettyName: 'sdi',
                    mode: ComprimatoPortMode.comprimatoSdi,
                    decoder: {
                        audio: {
                            maxAudioStreams: 1,
                            layout: [AudioLayout.stereo],
                            codecs: [
                                {
                                    name: AudioCodec.aes3,
                                    bitratesKbps: [3000],
                                    bitDepth: [20, 24],
                                },
                                {
                                    name: AudioCodec.ac3,
                                    bitratesKbps: [192, 384, 448, 640],
                                },
                            ],
                        },
                        video: {
                            codecs: [
                                {
                                    name: RawVideo,
                                    colorSampling: [{ name: '4:2:2', value: '4:2:2' }],
                                    bitDepth: [10],
                                    bitrate: {
                                        min: 0,
                                        max: 40000000,
                                    },
                                    resolution: [
                                        { name: '720p', value: '720p' },
                                        { name: '1080p', value: '1080p' },
                                        { name: '480i', value: '480i' },
                                        { name: '576i', value: '576i' },
                                        { name: '1080i', value: '1080i' },
                                    ],
                                    scanRate: ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60'],
                                },
                            ],
                        },
                    },
                },
                {
                    prettyName: 'ndi',
                    mode: ComprimatoPortMode.comprimatoNdi,
                    decoder: {
                        audio: {
                            maxAudioStreams: 1,
                            layout: [AudioLayout.stereo],
                            codecs: [
                                {
                                    name: AudioCodec.raw,
                                    bitratesKbps: [],
                                },
                            ],
                        },
                        video: {
                            codecs: [
                                {
                                    name: RawVideo,
                                    colorSampling: [{ name: '4:2:2', value: '4:2:2' }],
                                    bitDepth: [10],
                                    bitrate: {
                                        min: 0,
                                        max: 40000000,
                                    },
                                    resolution: [
                                        { name: '720p', value: '720p' },
                                        { name: '1080p', value: '1080p' },
                                        { name: '480i', value: '480i' },
                                        { name: '576i', value: '576i' },
                                        { name: '1080i', value: '1080i' },
                                    ],
                                    scanRate: ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60'],
                                },
                            ],
                        },
                    },
                },
            ],
        },
    }
}

const mediakindRx1 = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => ({
    proxy: true,
    input: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
        ],
    },
    output: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
        ],
    },
})
const mediakindCe1 = (_product: EdgeProduct, _?: ApplianceVersion): ApplianceFeatures => ({
    proxy: true,
    input: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
        ],
    },
    output: {
        modes: [
            { mode: IpPortMode.rtp },
            { mode: IpPortMode.srt, subModes: [SrtMode.caller, SrtMode.rendezvous, SrtMode.listener] },
            { mode: IpPortMode.udp },
        ],
    },
})

export const applianceTypes: { [key in ApplianceType]: ApplianceFeaturesFactory } = {
    [ApplianceType.nimbraVA220]: nimbraVa220,
    [ApplianceType.nimbraVA225]: nimbraVa225,
    [ApplianceType.nimbraVAdocker]: nimbraVaDocker,
    [ApplianceType.nimbra410]: nimbra410,
    [ApplianceType.nimbra412]: nimbra412,
    [ApplianceType.nimbra414]: nimbra414,
    [ApplianceType.nimbra414b]: nimbra414,
    [ApplianceType.edgeConnect]: edgeConnect,
    [ApplianceType.core]: core,
    [ApplianceType.thumb]: emptyFeatureSet,
    [ApplianceType.videon]: videon,
    [ApplianceType.matroxMonarchEdgeE4_8Bit]: matroxE4,
    [ApplianceType.matroxMonarchEdgeE4_10Bit]: matroxE4,
    [ApplianceType.matroxMonarchEdgeD4]: matroxD4,
    [ApplianceType.matroxMonarchEdgeS1]: matroxS1,
    [ApplianceType.comprimato]: comprimato,
    [ApplianceType.mediakindRx1]: mediakindRx1,
    [ApplianceType.mediakindCe1]: mediakindCe1,
}
