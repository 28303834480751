import { PaginatedRequestParams } from '../../api/nm-types'
import { FilterBy } from '../common/Filters/FilterView/FilterTemplate'

enum ListAddressMappingFilterKey {
  search = 'search phrase',
}

export function mapAddressMappingFilterToUrlParam(filter: FilterBy): Partial<PaginatedRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListAddressMappingFilterKey.search:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToAddressMappingFilter(
  key: keyof PaginatedRequestParams,
  value: string,
): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListAddressMappingFilterKey.search,
        operator: 'matches',
        value,
      }
  }
  return undefined
}
