import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { initAlarms } from './alarmsActions'
import { initAppliance } from './applianceActions'
import { initBuildInfo } from './buildInfoActions'
import { RouteComponentProps } from 'react-router-dom'

export const init = createAsyncThunk<void, RouteComponentProps['history'], ThunkApi>(
  'ui/init',
  async (history, { dispatch, extra: { setHistory } }) => {
    setHistory(history)
    dispatch(initAppliance())
    dispatch(initAlarms())
    dispatch(initBuildInfo())
  },
)

export const toggleSidebarMenu = createAction('ui/toggleSidebarMenu')
export const setSearchVisibility = createAction<boolean>('ui/setSearchVisibility')
