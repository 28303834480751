import { Route } from 'react-router-dom'

import { StatusList } from './List'
import { useRoutes } from '../../store'

export const Status = () => {
  const routes = useRoutes()
  return (
    <>
      <Route path={routes.status.route} component={StatusList} />
    </>
  )
}
