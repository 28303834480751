import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const styles = {
  container: {
    width: '100%',
    position: 'relative',
  },
  visibilityIcon: {
    position: 'absolute',
    top: '30px',
    right: '10px',
    cursor: 'pointer',
  },
}

/** <TextField> with a visibility toggle icon for type="password" */
export const PasswordField = (props: TextFieldProps & { showPassword?: { show: boolean; id: string } }) => {
  const [showPassword, setShowPassword] = useState(false)
  const { showPassword: _, ...textProps } = props

  useEffect(() => {
    setShowPassword(Boolean(props.showPassword?.show))
  }, [props.showPassword?.id])

  return (
    <Box sx={styles.container}>
      <TextField {...textProps} type={showPassword ? 'text' : 'password'} />
      <Box onClick={() => setShowPassword(!showPassword)} sx={styles.visibilityIcon}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </Box>
    </Box>
  )
}
