import { useRef, useState } from 'react'
import ReactMapboxGl, { Popup, ViewState, NavigationControl } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import Box from '@mui/material/Box'
import { styled, Theme } from '@mui/material/styles'

import Appliances from './Appliances/Appliances'
import type { PopupProps } from './utils'

const styles = {
  container: {
    width: '100%',
    height: '50vh',
    maxHeight: '700px',
    '& a': {
      color: (theme: Theme) => theme.palette.background.default,
      '&:hover': { color: (theme: Theme) => theme.palette.background.paper },
    },
  },
}

const StyledPopup = styled(Popup)(({ theme }) => ({
  '& .mapboxgl-popup-content': {
    background: theme.palette.grey[800],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    maxHeight: '40vh',
    maxWidth: '50vh',
    overflow: 'auto',
    padding: (theme: Theme) => theme.spacing(2),
  },
  '& .mapboxgl-popup-tip': {
    borderTopColor: theme.palette.grey[800],
  },
}))

export enum MapDataType {
  services = 'services',
  appliances = 'appliances',
}

interface MapProps {
  dataType?: MapDataType
}

const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN

/**
 * Mapbox container
 * @param sideMargin - needed to avoid having padding (left, right & bottom) on parent Paper
 * @param dataType - do we show appliances only or the whole services
 */
const Map = ({ dataType = MapDataType.services }: MapProps) => {
  const featureEvents = useRef({ appliances: new EventTarget() })
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([])
  const [popup, setPopup] = useState<undefined | PopupProps>(undefined)
  const [currentHoverFeature, setCurrentHoverFeature] = useState<number | undefined>(undefined)
  const initialViewState: Partial<ViewState> = {
    latitude: 59.359010042385975,
    longitude: 17.980828484893433,
    zoom: 2,
  }
  const [cursor, setCursor] = useState<'grab' | 'pointer'>('grab')

  return (
    <Box sx={styles.container}>
      <ReactMapboxGl
        mapboxAccessToken={
          MAPBOX_TOKEN // nimbra-edge-dev token from .env unless MAPBOX_TOKEN is set (which in the pipeline is set to nimbra-edge-production
        }
        initialViewState={initialViewState}
        cursor={cursor}
        minZoom={0.5}
        maxZoom={10}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/netinsight/ck9sgm2mi0ryl1ipff3wfxmhl"
        interactiveLayerIds={interactiveLayerIds}
        onLoad={() => setInteractiveLayerIds(['appliances'])}
        onClick={(e) => {
          const feature = e?.features?.[0]
          if (feature?.properties) {
            featureEvents.current.appliances.dispatchEvent(
              new CustomEvent('onClick', { detail: feature.properties.id }),
            )
          } else {
            setPopup(undefined)
          }
        }}
        onMouseMove={(e) => {
          const feature = e?.features?.[0]
          if (feature?.properties) {
            featureEvents.current.appliances.dispatchEvent(
              new CustomEvent('onMouseEnter', { detail: feature.properties.id }),
            )
            setCurrentHoverFeature(feature.properties.id)
            setCursor('pointer')
          } else {
            if (currentHoverFeature !== undefined) {
              featureEvents.current.appliances.dispatchEvent(
                new CustomEvent('onMouseLeave', { detail: currentHoverFeature }),
              )
              setCurrentHoverFeature(undefined)
              setCursor('grab')
            }
          }
        }}
      >
        <>
          <NavigationControl position="top-left" showCompass={false} />
          {dataType === MapDataType.appliances && (
            <Appliances layerId="appliances" setPopup={setPopup} featureEvents={featureEvents.current} />
          )}
          {!!popup && (
            <StyledPopup
              onClose={() => setPopup(undefined)}
              latitude={popup.coordinates[0]}
              longitude={popup.coordinates[1]}
              anchor="bottom"
            >
              {popup.content}
            </StyledPopup>
          )}
        </>
      </ReactMapboxGl>
    </Box>
  )
}

export default Map
