import {
  AllocateApplianceRequest,
  Appliance,
  CoreVideoApplianceAllocation,
  Group,
  ListResult,
  PhysicalPort,
  PortFilter,
  SharedPort,
} from 'common/api/v1/types'
import type { RequestOptions } from 'common/network/request-options'
import { getApplianceOwnerId } from '../../utils'
import {
  EnrichedApplianceWithOwner,
  EnrichedPhysicalPort,
  PortsRequestParams,
  singleSortQueryFromPaginatedRequestParams,
} from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'
import { arrayToChunks } from '../../utils/array'

export interface IPortsApi {
  getPort(id: PhysicalPort['id']): Promise<EnrichedPhysicalPort>
  getPorts(params: PortsRequestParams): Promise<ListResult<EnrichedPhysicalPort>>
  getBarePorts(params: PortsRequestParams): Promise<ListResult<PhysicalPort>>
  updatePort(id: Appliance['id'], sharedPorts: Array<SharedPort>): Promise<Array<SharedPort>>
  allocateCoreVideoAppliance(
    params: AllocateApplianceRequest,
    options?: Pick<RequestOptions, 'signal'>,
  ): Promise<CoreVideoApplianceAllocation>
}

export class PortsApi implements IPortsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async updatePort(id: Appliance['id'], sharedPorts: Array<SharedPort>): Promise<Array<SharedPort>> {
    return await this.edgeClient.setAppliancePorts(id, sharedPorts)
  }

  /**
   * Returns interface with populated appliance and owner
   * @param portId
   */
  async getPort(portId: string): Promise<EnrichedPhysicalPort> {
    const port = await this.edgeClient.getPort(portId)
    const barePortAppliance = await this.edgeClient.getAppliance(port.appliance.id)

    const groupIds = [port.owner, barePortAppliance.owner].filter((id) => !!id) as string[]
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items

    const portOwner = groups.find((group) => group.id == port.owner) as Group

    const portApplianceOwner = groups.find((group) => group.id == barePortAppliance.owner) as Group
    const enrichedPortAppliance: EnrichedApplianceWithOwner = {
      ...barePortAppliance,
      _owner: portApplianceOwner,
    }
    return {
      ...port,
      _owner: portOwner,
      _appliance: enrichedPortAppliance,
    }
  }

  /**
   * Returns ListResult of interfaces with appliance, owner, inputs and output using it populated
   */
  async getPorts(params: PortsRequestParams): Promise<ListResult<EnrichedPhysicalPort>> {
    const ports = await this.getBarePorts(params)
    const { applianceIds, groupIds } = ports.items.reduce<{
      groupIds: Set<string>
      applianceIds: Set<string>
    }>(
      (acc, port) => {
        acc.groupIds.add(port.owner)
        acc.applianceIds.add(port.appliance.id)
        return acc
      },
      {
        groupIds: new Set<string>(),
        applianceIds: new Set<string>(),
      },
    )

    const appliances = await fetchInBatches(applianceIds, (ids) => this.edgeClient.listAppliances({ filter: { ids } }))
    const groupsToFetch = new Set([...groupIds, ...appliances.map((a) => getApplianceOwnerId(a)).filter((id) => !!id)])
    const groups = await fetchInBatches(groupsToFetch, (ids) => this.edgeClient.listGroups({ filter: { ids } }))

    return {
      ...ports,
      items: ports.items.map((port) => {
        const portAppliance = appliances.find(({ id }) => id === port.appliance.id)
        const portApplianceOwner = groups.find((g) => g.id == portAppliance?.owner)
        const enrichedAppliance: EnrichedApplianceWithOwner | undefined = portAppliance &&
          portApplianceOwner && {
            ...portAppliance,
            _owner: portApplianceOwner,
          }
        const enrichedPort: EnrichedPhysicalPort = {
          ...port,
          _appliance: enrichedAppliance,
          _owner: groups.find(({ id }) => id === port.owner) as Group,
        }
        return enrichedPort
      }),
    }
  }

  /**
   * Returns ListResult of interfaces
   * @param owner - to show only those belonging to this group
   * @param portType - to show only those of this PortType
   * @param applianceType - to show only those of this ApplianceType
   * @param appliance - to show only those of this appliance
   * @param searchName - term to search for
   * @param params - pagination parameters
   */
  getBarePorts({
    owner,
    portType,
    applianceType,
    appliance,
    filter: searchName,
    ...params
  }: PortsRequestParams): Promise<ListResult<PhysicalPort>> {
    const filter: PortFilter = {
      owner,
      appliance,
      applianceType,
      portType,
      searchName,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listPorts(query)
  }

  allocateCoreVideoAppliance(
    params: AllocateApplianceRequest,
    options?: Pick<RequestOptions, 'signal'>,
  ): Promise<CoreVideoApplianceAllocation> {
    return this.edgeClient.createApplianceAllocation(params, options)
  }
}

// Batch requests to prevent error '414 Request-URI Too Large'.
// e.g. ~188 ids seems to be the highest possible value when listing inputs
async function fetchInBatches<T>(
  ids: Set<string>,
  fetchFn: (ids: string[]) => Promise<ListResult<T>>,
  maxBatchSize = 150,
): Promise<T[]> {
  const chunks = arrayToChunks(Array.from(ids), maxBatchSize)
  const allItems = []
  for (const ids of chunks) {
    const listResult = await fetchFn(ids)
    allItems.push(...listResult.items)
  }
  return allItems
}
