import { BuildInfo } from 'common/api/v1/types'
import { getBuildInfo } from '../actions/buildInfoActions'
import { createSlice } from '@reduxjs/toolkit'
import { createLoadingReducer } from './shared'

interface State {
  buildInfo?: BuildInfo
  loading: boolean
}

const initialState: State = {
  buildInfo: undefined,
  loading: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getBuildInfo)

const buildInfoSlice = createSlice({
  name: 'buildInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuildInfo.fulfilled, (state, { payload: buildInfo }): State => ({ ...state, buildInfo }))
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default buildInfoSlice.reducer
