import { createSlice } from '@reduxjs/toolkit'
import { AlarmLog } from 'common/api/v1/types'
import { getAlarmLogs } from '../actions/alarmLogsActions'
import { logoutUser } from '../actions/userActions'
import { createLoadingReducer } from './shared'

interface State {
  alarmLogs: Array<AlarmLog>
  total: number
  loading: boolean
}
export const initialStateAlarmLogs: State = {
  alarmLogs: [],
  total: 0,
  loading: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getAlarmLogs)

const alarmLogsSlice = createSlice({
  name: 'alarmLogs',
  initialState: initialStateAlarmLogs,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAlarmLogs.fulfilled,
        (state, { payload: { items: alarmLogs, total } }): State => ({
          ...state,
          alarmLogs,
          total,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateAlarmLogs)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default alarmLogsSlice.reducer
