import { createSlice } from '@reduxjs/toolkit'

import { IpMappingRead } from 'common/api/v1/types'
import {
  clearEditMapping,
  getIpMappings,
  removeIpMapping,
  createIpMapping,
  getIpMapping,
  updateIpMapping,
} from '../actions/addressMappingActions'

import { logoutUser } from '../actions/userActions'
import { createLoadingReducer } from './shared'

interface State {
  loading: boolean
  ipMapping?: IpMappingRead
  ipMappings: Array<IpMappingRead>
}
export const initialStateAddressMapping: State = {
  loading: false,
  ipMappings: [],
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(
  createIpMapping,
  updateIpMapping,
  removeIpMapping,
  getIpMappings,
  getIpMapping,
)

const addressMappingSlice = createSlice({
  name: 'addressMappings',
  initialState: initialStateAddressMapping,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.fulfilled, (): State => initialStateAddressMapping)
      .addCase(clearEditMapping, (state): State => ({ ...state, ipMapping: undefined }))
      .addCase(
        getIpMapping.fulfilled,
        (state, { payload: mapping }): State => ({
          ...state,
          ipMapping: mapping,
        }),
      )
      .addCase(
        getIpMappings.fulfilled,
        (state, { payload: ipMappings }): State => ({
          ...state,
          ipMappings,
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default addressMappingSlice.reducer
