import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'

import { BillingReport } from 'common/api/v1/types'
import { enqueueErrorSnackbar } from './notificationActions'

export interface BillingPayload {
  startDate: Date
  endDate: Date
}

export const readBilling = createAsyncThunk<BillingReport, BillingPayload, ThunkApi>(
  'billing/readBilling',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.billingApi.readBilling(params)
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch billing' }))
      throw err
    }
  },
)
