import { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useAsync } from 'react-async-hook'
import Box from '@mui/material/Box'

import { getTotalOutputCount } from '../../../redux/actions/outputsActions'
import Pendable from '../../common/Pendable'
import { Link } from '../../common/Link'
import NumericalDataSet from '../../common/NumericalDataSet'
import { useApplianceSelector, useInputsSelector } from '../../../utils'
import { DEFAULT_PAGINATION } from '../../../utils'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { getUsers } from '../../../redux/actions/userActions'
import { getConnectitTokens } from '../../../redux/actions/tokenActions'

import { useRoutes } from '../../../store'
import { Role } from 'common/api/v1/types'
import { IS_CONNECT_IT } from '../../../env'
import { EnrichedUser } from '../../../api/nm-types'

const { userApi, tokenApi } = Api

const Availability = () => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(getTotalOutputCount())
  }, [dispatch])
  const { total: outputsTotal, loading: outputsLoading } = useSelector(
    ({ outputsReducer }: GlobalState) => outputsReducer,
    shallowEqual,
  )
  const { loading: inputsLoading, total: inputsTotal } = useInputsSelector(DEFAULT_PAGINATION)
  const { loading: appliancesLoading, total: appliancesTotal, totalRegions } = useApplianceSelector(DEFAULT_PAGINATION)
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user as EnrichedUser, shallowEqual)
  const showTokens = IS_CONNECT_IT && user.role === Role.super
  const users = useAsync(() => getUsers(DEFAULT_PAGINATION, { dispatch, userApi }), [])
  const connectitTokens = useAsync(
    () => (showTokens ? getConnectitTokens({}, { dispatch, tokenApi }) : Promise.resolve(null)),
    [showTokens],
  )
  const routes = useRoutes()
  const isPending = connectitTokens?.loading || users.loading || appliancesLoading || inputsLoading || outputsLoading
  return (
    <Pendable pending={isPending}>
      <Box sx={{ width: '100%' }}>
        <NumericalDataSet
          values={[
            {
              title: (
                <Link to={routes.inputs()} underline="hover">
                  Inputs available
                </Link>
              ),
              value: inputsTotal,
            },
            {
              title: (
                <Link to={routes.outputs()} underline="hover">
                  Outputs available
                </Link>
              ),
              value: outputsTotal,
            },
            {
              title: (
                <Link to={routes.users()} underline="hover">
                  User accounts
                </Link>
              ),
              value: users.result?.total,
            },
            {
              title: (
                <Link to={routes.appliances()} underline="hover">
                  Appliances
                </Link>
              ),
              value: appliancesTotal,
            },
            {
              title: (
                <Link to={routes.regions()} underline="hover">
                  Regions
                </Link>
              ),
              value: totalRegions,
            },
            ...(showTokens && connectitTokens
              ? [
                  {
                    title: 'Token usage',
                    value: connectitTokens.result
                      ? `${connectitTokens.result.used} / ${
                          connectitTokens.result.used + connectitTokens.result.available
                        }`
                      : 'N/A',
                  },
                ]
              : []),
          ]}
        />
      </Box>
    </Pendable>
  )
}

export default Availability
