import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { Network, ListResult } from 'common/api/v1/types'

import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import { NetworksRequestParams } from '../../api/nm-types'

export const createNetwork = createAsyncThunk<void, Omit<Network, 'id'>, ThunkApi>(
  'networks/createNetwork',
  async (network, { dispatch, extra: { routes, api, history } }) => {
    try {
      await api.networksApi.createNetwork(network)
      history().push(routes.networks())
      dispatch(enqueueSuccessSnackbar(`Created Network ${network.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'create network' }))
      throw err
    }
  },
)

export const removeNetwork = createAsyncThunk<{ id: Network['id']; name: Network['name'] }, Network['id'], ThunkApi>(
  'networks/removeNetwork',
  async (id, { dispatch, extra: { routes, api, history } }) => {
    try {
      const res = await api.networksApi.removeNetwork(id)
      history().push(routes.networks())
      dispatch(enqueueSuccessSnackbar(`Removed Network: ${res.name}`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete network' }))
      throw err
    }
  },
)

export const updateNetwork = createAsyncThunk<void, Network, ThunkApi>(
  'networks/updateNetwork',
  async (network, { dispatch, extra: { routes, api, history } }) => {
    try {
      await api.networksApi.updateNetwork(network.id, {
        name: network.name,
        enabled: network.enabled,
      })
      history().push(routes.networks())
      dispatch(enqueueSuccessSnackbar(`Updated Network: ${network.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update network' }))
      throw err
    }
  },
)

export const getNetworks = createAsyncThunk<ListResult<Network>, Partial<NetworksRequestParams>, ThunkApi>(
  'networks/getNetworks',
  async (params, { extra: { api } }) => {
    return await api.networksApi.getNetworks(withDefaultPagination(params))
  },
)

export const getNetwork = createAsyncThunk<Network, Network['id'], ThunkApi>(
  'networks/getNetwork',
  async (id, { extra: { api } }) => {
    return await api.networksApi.getNetwork(id)
  },
)

export const clearNetwork = createAction('networks/clearNetwork')
export const clearNetworks = createAction('networks/clearNetworks')
