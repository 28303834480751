import { useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { Grid } from '@mui/material'
import { merge } from 'lodash'
import { Form, Formik, FormikProps } from 'formik'

import { clearKubernetesNode, getKubernetesNode, updateKubernetesNode } from '../../../redux/actions/k8sActions'
import { AppDispatch, GlobalState } from '../../../store'
import { ButtonsPane, Checkbox, Paper, SafeRouting } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Pendable from '../../common/Pendable'
import { formTransform, useUser } from '../../../utils'
import { KubernetesNode, Role, ExternalRegionMode } from 'common/api/v1/types'
import { KubernetesRoles } from 'common/k8s/labels'
import Meta from './meta'

import { useRoutes } from '../../../store'

interface RoleTypes {
  roleCore: boolean
  roleVideo: boolean
  roleVideoStandby: boolean
  roleThumb: boolean
  roleEdgeConnect: boolean
  roleLoadSimulator: boolean
}

const getInitialState = (n?: KubernetesNode): KubernetesNode & RoleTypes =>
  merge(
    {
      name: '',
      status: '',
      externalIP: '',
      internalIP: '',
      hostname: '',
      roles: [],
      roleCore: n?.roles.includes(KubernetesRoles.core) || false,
      roleVideo: n?.roles.includes(KubernetesRoles.video) || false,
      roleVideoStandby: n?.roles.includes(KubernetesRoles.video_standby) || false,
      roleThumb: n?.roles.includes(KubernetesRoles.thumb) || false,
      roleEdgeConnect: n?.roles.includes(KubernetesRoles.edge_connect) || false,
      roleLoadSimulator: n?.roles.includes(KubernetesRoles.load_simulator) || false,
      kubeletVersion: '',
      region: {
        id: '',
        name: '',
        external: ExternalRegionMode.externalK8s,
      },
    },
    n,
  )
export const Edit = ({ history, match }: RouteComponentProps<{ regionId: string; name: KubernetesNode['name'] }>) => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getKubernetesNode({ regionId: match.params.regionId, name: match.params.name }))
    return () => {
      dispatch(clearKubernetesNode())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  const { node, loading } = useSelector(
    ({ k8sReducer }: GlobalState) => ({
      node: k8sReducer.kubernetesNode,
      loading: k8sReducer.loading,
    }),
    shallowEqual,
  )

  const user = useUser()
  if (user.role !== Role.super) {
    history.goBack()
    return null
  }

  const onSubmit = (kubernetesNode: KubernetesNode) => {
    dispatch(
      updateKubernetesNode({
        regionId: kubernetesNode.region.id,
        name: kubernetesNode.name,
        roles: kubernetesNode.roles,
      }),
    )
  }

  return (
    <Wrapper name="Kubernetes node" entityName={node?.name}>
      <Grid container spacing={0}>
        <Pendable pending={loading}>
          <Formik
            onSubmit={(values) => {
              onSubmit(formTransform(values))
            }}
            initialValues={getInitialState(node)}
          >
            {(props) => (
              <KubernetesNodeForm {...props} isExternalNode={node?.region.external !== ExternalRegionMode.core} />
            )}
          </Formik>
        </Pendable>
      </Grid>
    </Wrapper>
  )
}

const KubernetesNodeForm = ({
  values,
  setStatus,
  dirty,
  isSubmitting,
  setSubmitting,
  setFieldValue,
  isExternalNode,
}: FormikProps<KubernetesNode & RoleTypes> & { isExternalNode: boolean }) => {
  const history = useHistory()
  const routes = useRoutes()

  const { saving, formErrors, devMode } = useSelector(
    ({ k8sReducer, settingsReducer }: GlobalState) => ({
      saving: k8sReducer.saving,
      formErrors: k8sReducer.formErrors,
      devMode: settingsReducer.devMode,
    }),
    shallowEqual,
  )

  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])

  useEffect(() => {
    if (saving === false) setSubmitting(false)
  }, [saving])

  const prevValues = useRef<Partial<typeof values>>({})

  useEffect(() => {
    const prev = prevValues.current

    // TODO: is there a better way to map an array to our list of checkboxs?
    const rolesSet: Set<KubernetesRoles> = new Set()
    const { roleCore, roleVideo, roleVideoStandby, roleThumb, roleEdgeConnect, roleLoadSimulator } = values
    if (roleCore) rolesSet.add(KubernetesRoles.core)
    if (roleVideo) rolesSet.add(KubernetesRoles.video)
    if (roleVideoStandby) rolesSet.add(KubernetesRoles.video_standby)
    if (roleThumb) rolesSet.add(KubernetesRoles.thumb)
    if (roleEdgeConnect) rolesSet.add(KubernetesRoles.edge_connect)
    if (roleLoadSimulator) rolesSet.add(KubernetesRoles.load_simulator)

    // Activated standby, but not video
    if (!values.roleVideo && values.roleVideoStandby && !prev.roleVideoStandby) {
      rolesSet.add(KubernetesRoles.video)
    }

    // Deactivated video, but not standby
    if (!values.roleVideo && values.roleVideoStandby && prev.roleVideoStandby) {
      rolesSet.delete(KubernetesRoles.video_standby)
    }

    values.roles = Array.from(rolesSet)
    setFieldValue('roleCore', rolesSet.has(KubernetesRoles.core))
    setFieldValue('roleVideo', rolesSet.has(KubernetesRoles.video))
    setFieldValue('roleVideoStandby', rolesSet.has(KubernetesRoles.video_standby))
    setFieldValue('roleThumb', rolesSet.has(KubernetesRoles.thumb))
    setFieldValue('roleEdgeConnect', rolesSet.has(KubernetesRoles.edge_connect))
    setFieldValue('roleLoadSimulator', rolesSet.has(KubernetesRoles.load_simulator))

    prevValues.current = values
  }, [values, setFieldValue])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="node-form" translate="no" noValidate>
          <Meta node={values} />
          <Paper title="Node roles">
            <Checkbox
              name="roleCore"
              label="Core"
              tooltip="The core role determines which worker nodes run the API and all the supporting central services and databases. This is the most memory-intensive role and requires a minimum of 16GB for production deployments. It is also the most storage-intensive role both in volume and IOPS."
              disabled={isExternalNode}
            />
            <Checkbox
              name="roleVideo"
              label="Video"
              tooltip="The video role is given to the worker nodes that should distribute video. This is the most bandwidth-intensive role and it requires a public ip (public as in reachable from outside the cluster) available. For each worker node with the video node there should be a public ip mapping that specifies how external appliances can reach the video node."
            />
            <Checkbox
              name="roleVideoStandby"
              label="Video Standby"
              tooltip="The video-standby role is a specialization of the video role where no workload is scheduled unless some video worker node is unavailable. This can be used to ensure auto-recovery in case a video worker node becomes unavailable for any reason."
            />
            <Checkbox
              name="roleThumb"
              label="Thumb"
              tooltip="The thumb role determines where the thumbnail service should run. This is the most CPU-intensive role and for that reason, it is generally recommended to not colocate this role with the video role on the same worker."
            />

            {devMode && (
              <>
                <Checkbox
                  name="roleEdgeConnect"
                  label="EdgeConnect"
                  tooltip="The edgeConnect role is used to deploy edge-connect instances within the Kubernetes cluster."
                  disabled={isExternalNode}
                />
                <Checkbox
                  name="roleLoadSimulator"
                  label="Load simulator"
                  tooltip="The load simulator role is used to run appliance emulators that generate control plane load."
                  disabled={isExternalNode}
                />
              </>
            )}
          </Paper>
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.kubernetesNodes())
                },
              },
              Save: {
                id: 'button-save',
                savingState: !!saving,
                primary: true,
                type: 'submit',
              },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}
