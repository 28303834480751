import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Input, Output, OutputRedundancyMode } from 'common/api/v1/types'
import { Api } from '../../store'
import { AutoUpdatingInputHealthIndicator } from '../common/Indicator'
import Title from '../common/Dialog/Title'
import { Table, TableConfig } from '../common/Table'
import PaginatedList from '../common/SelfStatePaginatedList'
import Thumbnail from '../common/Thumbnail'
import { inputOutputColor, useConfirmationDialog } from '../../utils'
import { EnrichedInput, EnrichedOutput, InputsRequestParams } from '../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import DialogActions from '@mui/material/DialogActions'
import { useEffect, useState } from 'react'
import Divider from '@mui/material/Divider'
import { inputRedundancy } from 'common/utils'

const { inputApi } = Api

const styles = {
  thumbnail: {
    padding: (theme: Theme) => theme.spacing(1),
    minWidth: '80px',
    maxWidth: '120px',
    minHeight: '50px',
  },
  name: {
    fontWeight: 'bold',
    fontSize: (theme: Theme) => theme.typography.h4.fontSize,
  },
  row: {
    cursor: 'pointer',
  },
}

interface SelectInputDialogProps {
  show: boolean
  output: EnrichedOutput
  closeDialog: () => void
  onInputSelect: (output: Output, input: Input) => void
  onClearInput: (output: EnrichedOutput) => void
}

export const SelectInputDialog = ({
  show,
  output,
  closeDialog,
  onInputSelect,
  onClearInput,
}: SelectInputDialogProps) => {
  const onRowClicked = (selectedInput: EnrichedInput | undefined) => {
    closeDialog()
    if (selectedInput) {
      onInputSelect(output, { ...selectedInput })
    }
  }
  const onClearInputClicked = () => {
    closeDialog()
    onClearInput(output)
  }

  return (
    <Dialog open={Boolean(show && output)} onClose={closeDialog} maxWidth="md" fullWidth id="switch-input-dialog">
      <Title title={`Switch input of ${output.name}`} onClose={closeDialog} />
      <DialogContent sx={{ paddingBottom: 0 /* Remove bot padding for sticky pagination to look good */ }}>
        <DialogContentText component="div">
          {show && <PaginatedInputList output={output} onRowClicked={onRowClicked} />}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={closeDialog} data-test-id="cancel-btn">
          Cancel
        </Button>
        <Button
          disabled={!output.input}
          variant="outlined"
          onClick={onClearInputClicked}
          data-test-id="clear-input-btn"
        >
          Clear
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const SelectInputWithConfirmationDialog = ({
  show,
  output,
  closeDialog,
  onInputSelect,
  onClearInput,
}: SelectInputDialogProps) => {
  const showConfirmationDialog = useConfirmationDialog()
  const [selectedInput, setSelectedInput] = useState<Input | undefined>(output._input)

  useEffect(() => {
    if (!show) {
      setSelectedInput(undefined)
    } else {
      setSelectedInput(output._input)
    }
  }, [show, output])

  const resetStateAndClose = () => {
    // The same dialog component is re-used between presentations,
    // i.e. dialog state is persisted...
    setSelectedInput(undefined)
    closeDialog()
  }

  const onSaveButtonClicked = (selectedInput: Input | undefined) => {
    const isRedundantOutput = output.redundancyMode != OutputRedundancyMode.none
    const isRedundantInput = !!selectedInput && inputRedundancy(selectedInput)
    const outputRedundancyWillBeRemoved = isRedundantOutput && !isRedundantInput

    const title = selectedInput
      ? outputRedundancyWillBeRemoved
        ? `Assigning non-redundant input "${selectedInput.name}" to output "${output.name}" will disable output redundancy. Proceed?`
        : `Assign input "${selectedInput.name}" to output "${output.name}"?`
      : `Clear the input of output "${output.name}"?`

    const action = selectedInput
      ? () => {
          onInputSelect(output, { ...selectedInput })
          resetStateAndClose()
        }
      : () => {
          onClearInput(output)
          resetStateAndClose()
        }

    showConfirmationDialog(action, title)
  }

  const hasSelectedSameInput = selectedInput?.id === output.input
  return (
    <Dialog
      open={Boolean(show && output)}
      onClose={resetStateAndClose}
      maxWidth="md"
      fullWidth
      id="switch-input-dialog"
    >
      <Title title={`Switch input of ${output.name}`} onClose={resetStateAndClose} />
      <DialogContent sx={{ paddingBottom: 0 /* Remove bot padding for sticky pagination to look good */ }}>
        <DialogContentText component="div">
          {show && (
            <PaginatedInputList
              output={output}
              onRowClicked={(input) => {
                const isAlreadySelected = input?.id === selectedInput?.id
                setSelectedInput(isAlreadySelected ? undefined : input)
              }}
              selectedInputColumn={{ selectedInput }}
            />
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={resetStateAndClose} data-test-id="cancel-btn">
          Cancel
        </Button>
        <Button
          disabled={!output.input}
          variant="outlined"
          onClick={() => onSaveButtonClicked(undefined)}
          data-test-id="clear-input-btn"
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={hasSelectedSameInput}
          onClick={() => onSaveButtonClicked(selectedInput)}
          data-test-id="save-btn"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface PaginatedInputListProps {
  output: EnrichedOutput
  onRowClicked: (input: EnrichedInput | undefined) => void
  selectedInputColumn?: {
    selectedInput: EnrichedInput | undefined
  }
}
const PaginatedInputList = ({ output, onRowClicked, selectedInputColumn }: PaginatedInputListProps) => {
  const config: TableConfig<EnrichedInput, string> = [
    {
      getValue: (input: EnrichedInput) => (
        <Box sx={styles.thumbnail}>
          <Thumbnail input={input} linkToPlaylist={false} />
        </Box>
      ),
    },
    {
      getValue: ({ name, _owner, adminStatus }: EnrichedInput) => (
        <>
          <Typography data-test-id="input-name" sx={styles.name} {...inputOutputColor({ adminStatus })}>
            {name}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {_owner ? _owner.name : ''}
          </Typography>
        </>
      ),
    },
    {
      getValue: ({ tsInfo }: EnrichedInput) => (
        <Typography color="textSecondary" variant="body2">
          {getFormattedTransportStreamContent((tsInfo || [])[0])}
        </Typography>
      ),
    },
    {
      getValue: (input: EnrichedInput) => <AutoUpdatingInputHealthIndicator initialInput={input} />,
    },
  ]

  const showSelectionColumn = !!selectedInputColumn
  if (showSelectionColumn) {
    config.push({
      getValue: (input: EnrichedInput) => {
        const isSelected = selectedInputColumn?.selectedInput?.id === input.id
        return (
          <Button sx={{ visibility: isSelected ? 'visible' : 'hidden' }} variant="outlined" size="small">
            Selected
          </Button>
        )
      },
      props: {
        align: 'center',
        noTypography: true,
      },
    })
  }

  return (
    <PaginatedList<InputsRequestParams, EnrichedInput>
      api={inputApi.getInputs.bind(inputApi)}
      otherParams={{ canSubscribe: true }}
      searchPlaceholder="Find input…"
      emptyMessage="no inputs are available"
      notFoundMessage="no matching inputs"
      Header={
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color="textSecondary" variant="body1">
              Current input: {output._input ? output._input.name : 'none'}
            </Typography>
            {showSelectionColumn && (
              <Typography color="textSecondary" variant="body1">
                New input: {selectedInputColumn?.selectedInput?.name ?? 'none'}
              </Typography>
            )}
          </Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        </>
      }
      List={({ list }) => (
        <Table
          id="input-switch"
          data={list}
          props={{ size: 'small' }}
          noHeader
          rowProps={(input) => ({
            onClick: () => onRowClicked(input),
            selected: output.input === input.id,
            sx: styles.row,
          })}
          config={config}
        />
      )}
    />
  )
}
