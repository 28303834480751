import { createSlice } from '@reduxjs/toolkit'

import { UsageReport } from 'common/api/v1/types'
import { readUsage } from '../actions/usageActions'
import { createLoadingReducer } from './shared'

interface State {
  usage?: UsageReport
  loading: boolean
  error: boolean
}
export const initialStateUsage: State = {
  usage: undefined,
  loading: false,
  error: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(readUsage)

export const usageSlice = createSlice({
  name: 'usage',
  initialState: initialStateUsage,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(readUsage.pending, (state): State => ({ ...state, error: false, usage: undefined }))
      .addCase(readUsage.fulfilled, (state, { payload: usage }): State => ({ ...state, error: false, usage }))
      .addCase(readUsage.rejected, (state): State => ({ ...state, error: true, usage: undefined }))
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default usageSlice.reducer
