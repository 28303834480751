import { AppliancesRequestParams } from '../../api/nm-types'
import { IRegionApi } from '../../api/region/api'
import { IGroupsApi } from '../../api/groups/api'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'
import { IApplianceApi } from '../../api/appliances/api'
import { getProductName } from 'common/api/v1/helpers'
import { ApplianceType } from 'common/api/v1/types'

enum ListApplianceFilterKey {
  name = 'name',
  type = 'type',
  region = 'region',
  groupName = 'owner',
}

export function makeListApplianceFilter({
  applianceApi,
  groupApi,
  regionApi,
}: {
  applianceApi: IApplianceApi
  groupApi: IGroupsApi
  regionApi: IRegionApi
}): FilterTemplate[] {
  const requestPaging = { pageNumber: '0', rowsPerPage: '20' }
  const filterTemplates: FilterTemplate[] = [
    { key: ListApplianceFilterKey.name, operator: 'matches' },
    {
      key: ListApplianceFilterKey.type,
      operator: 'isOneOf',
      possibleValues: async (searchText) => {
        const { items: applianceTypes } = await applianceApi.listRegisteredApplianceTypes()
        return applianceTypes
          .map((type) => ({ value: type, displayName: getProductName(type) }))
          .filter(({ displayName }) => displayName.toLowerCase().includes((searchText ?? '').toLowerCase()))
      },
    },
    {
      key: ListApplianceFilterKey.groupName,
      operator: 'is',
      possibleValues: async (searchText) => {
        const result = await groupApi.getPureGroups({
          ...requestPaging,
          filter: searchText,
        })
        return result.items.map((a) => ({ value: a.name, displayName: a.name }))
      },
    },

    {
      key: ListApplianceFilterKey.region,
      operator: 'isOneOf',
      possibleValues: async (searchText) => {
        const result = await regionApi.getRegions({
          ...requestPaging,
          filter: searchText,
        })
        return result.items.map((r) => ({ value: r.name, displayName: r.name }))
      },
    },
  ]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}

export function mapApplianceFilterToUrlParam(filter: FilterBy): Partial<AppliancesRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListApplianceFilterKey.name:
        return { filter: filter.value }
      case ListApplianceFilterKey.groupName:
        return { groupName: filter.value }
    }
  } else if ('values' in filter) {
    switch (filter.key) {
      case ListApplianceFilterKey.type:
        return { types: filter.values.map(({ value }) => value).join(',') }
      case ListApplianceFilterKey.region:
        return { regions: filter.values.map(({ value }) => value).join(',') }
    }
  }
  return {}
}

export function mapUrlParamToApplianceFilter(key: keyof AppliancesRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListApplianceFilterKey.name,
        operator: 'matches',
        value,
      }
    case 'groupName':
      return {
        key: ListApplianceFilterKey.groupName,
        operator: 'is',
        value,
      }
    case 'types': {
      return {
        key: ListApplianceFilterKey.type,
        operator: 'isOneOf',
        values: value.split(',').map((type) => ({ value: type, displayName: getProductName(type as ApplianceType) })),
      }
    }
    case 'regions':
      return {
        key: ListApplianceFilterKey.region,
        operator: 'isOneOf',
        values: value.split(',').map((regionName) => ({ value: regionName, displayName: regionName })),
      }
  }
  return undefined
}
