import { KubernetesNodesRequestParams } from '../../../api/nm-types'
import { FilterBy } from '../../common/Filters/FilterView/FilterTemplate'

enum ListK8sNodesFilterKey {
  name = 'name',
}

export function mapK8sNodeFilterToUrlParam(filter: FilterBy): Partial<KubernetesNodesRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListK8sNodesFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToK8sNodeFilter(
  key: keyof KubernetesNodesRequestParams,
  value: string,
): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListK8sNodesFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}
