import MuiToolTip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'

export interface Props extends MuiTooltipProps {}

const Tooltip = ({ children, ...props }: Props) => {
  return (
    <MuiToolTip
      // disablePortal=true to avoid a second flickering scrollbar that appears if scrolling when tooltip is visible
      PopperProps={{ disablePortal: true }}
      slotProps={{ tooltip: { sx: { textTransform: 'none' } } }}
      {...props}
    >
      {children}
    </MuiToolTip>
  )
}

export default Tooltip
