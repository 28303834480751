import Hls from 'hls.js'
import { getItem } from './localstorage'
import { getLogger } from './logger'
const log = getLogger('hlsplayer')

const debugVal = getItem('hls_player_debug')
const debug = !!(debugVal && debugVal !== '0')

export default class HlsPlayer {
  hls: Hls | null = null
  isNative: boolean
  constructor(private videoEl: HTMLVideoElement) {
    this.isNative = HlsPlayer.nativeHls()

    if (this.isNative) return
    this.hls = new Hls({
      // No need to show duration for live content in native controls
      liveDurationInfinity: true,
      debug,
    })

    this.hls.on(Hls.Events.ERROR, (event, data) => {
      log(event, data)
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            this.hls?.startLoad()
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            this.hls?.recoverMediaError()
            break
          default:
            break
        }
      }
    })

    this.hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
      log(event, data)
    })

    this.hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
      log(event, data)
    })
    this.hls.on(Hls.Events.MEDIA_ATTACHED, function (event, data) {
      log(event, data)
    })
    this.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      log(event, data)
    })

    this.hls.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, function (event, data) {
      log(event, data)
    })
    this.hls.on(Hls.Events.BUFFER_CODECS, function (event, data) {
      log(event, data)
    })
  }

  static nativeHls(): boolean {
    const video = document.createElement('video')
    return video.canPlayType('application/vnd.apple.mpegURL') !== ''
  }

  static isSupported(): boolean {
    return HlsPlayer.nativeHls() || Hls.isSupported()
  }

  async load(src: string): Promise<void> {
    const hls = this.hls
    if (!hls) {
      this.videoEl.setAttribute('src', src)
      return
    }

    return new Promise((resolve) => {
      hls.loadSource(src)
      hls.attachMedia(this.videoEl)
      hls.on(Hls.Events.MANIFEST_PARSED, function parseListener() {
        hls.off(Hls.Events.MANIFEST_PARSED, parseListener)
        resolve(undefined)
      })
    })
  }

  play(): Promise<void> {
    return this.videoEl.play()
  }

  destroy(): void {
    this.hls?.destroy()
  }
}
