export const isPasswordSecure = (password: string) => {
  if (!/\d/.test(password)) return false
  if (!/[A-Z]/.test(password)) return false
  if (!/[!@#$+\-*&_]/.test(password)) return false
  return true
}

const CHARS = '0123456789!@#$+-*&_abcdefghijknopqrstuvwxyzACDEFGHJKLMNPQRSTUVWXYZ'
export const generatePassword = (minLength = 8, maxLength = 16): string => {
  let password = ''
  while (password.length < minLength || !isPasswordSecure(password)) {
    password += CHARS[Math.floor(Math.random() * (CHARS.length - 1))]
    if (password.length >= maxLength) break
  }
  return password
}
