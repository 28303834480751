import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { Appliance, GeoCoreNode, Role, User } from 'common/api/v1/types'
import { isCoreNode } from '../../../utils'
import { Link } from '../../common/Link'

import { useRoutes } from '../../../store'

/**
 * Showing single appliance popup
 * @param name - appliance name
 * @param id - appliance id for the link
 * @constructor
 */
export const AppliancePopup = ({ name, id, linkAvailable }: { name: string; id: string; linkAvailable: boolean }) => {
  const routes = useRoutes()
  return (
    <Link to={routes.appliancesUpdate({ id })} underline="hover" available={linkAvailable}>
      <Typography component="div" variant="body2">
        {name}
      </Typography>
    </Link>
  )
}

const isGeoCoreNode = (appliance: Appliance | GeoCoreNode): appliance is GeoCoreNode => isCoreNode(appliance.type)

/**
 * List of appliances popup
 * @param appliances
 * @constructor
 */
export const AppliancesPopup = ({ appliances, user }: { appliances: Array<Appliance | GeoCoreNode>; user: User }) => (
  <List dense disablePadding>
    {appliances.map((appliance) => {
      const { name, id } = appliance
      let hasAccessToNode = false

      if (isGeoCoreNode(appliance)) {
        hasAccessToNode = user.role === Role.super
      } else {
        const applianceOwner = typeof appliance.owner === 'string' ? appliance.owner : appliance.owner.id
        hasAccessToNode = user.role === Role.super || user.group === applianceOwner
      }

      return (
        <ListItem disableGutters key={`appliance-popup-${id}`}>
          <ListItemText>
            <AppliancePopup name={name} id={id} linkAvailable={hasAccessToNode} />
          </ListItemText>
        </ListItem>
      )
    })}
  </List>
)
