import { useEffect, useMemo } from 'react'
import { FormikProps } from 'formik'

import {
  ApplianceFeatures,
  ApplianceSettings,
  CoaxOutputPort,
  CoaxPortMode,
  isMatroxApplianceSettings,
  MatroxDecoderApplianceSettings,
  MatroxOutputPort,
  MatroxPortMode,
  Output,
} from 'common/api/v1/types'

import { Select } from '../../../../common/Form'
import { CommonFields } from '../IpPortForm'
import { get } from 'lodash'
import MatroxSdiPortForm, { getMatroxSdiPortFormFields } from './MatroxSdiPortForm'
import VaSdiPortForm, { getVaSdiPortFormFields } from './VaSdiPortForm'
import { RichOption } from '../../../../common/Form/Select'
import { COAX_MODES } from '../../../../../utils'

interface CoaxPortFormProps {
  namePrefix: string
  physicalPortIndex: string
  form: FormikProps<Output>
  settings: ApplianceSettings | undefined
  applianceFeatures: ApplianceFeatures
}

export const getCoaxPortFormFields = (port: CoaxOutputPort | MatroxOutputPort) => {
  const fields: string[] = [
    CommonFields.id,
    CommonFields.physicalPort,
    CommonFields.copies,
    CommonFields.mode,
    'encoderSettings',
  ]
  if (port.mode === CoaxPortMode.sdi) {
    fields.push(...getVaSdiPortFormFields())
  } else if (port.mode === MatroxPortMode.matroxSdi) {
    fields.push(...getMatroxSdiPortFormFields())
  }
  return fields
}

const CoaxPortForm = ({ namePrefix, physicalPortIndex, settings, form, applianceFeatures }: CoaxPortFormProps) => {
  const { setFieldValue } = form
  const logicalPort: CoaxOutputPort | MatroxOutputPort = get(form.values, namePrefix)

  const modeKey = `${namePrefix}.mode`
  const selectedMode = logicalPort.mode
  const modeOptions: RichOption[] = useMemo(() => {
    const coaxModes = applianceFeatures.output?.modes.filter((m) => COAX_MODES.includes(m.mode)) ?? []
    return coaxModes.map((m) => ({ name: m.prettyName ?? m.mode, value: m.mode }))
  }, [JSON.stringify(applianceFeatures.output?.modes)])

  useEffect(() => {
    const defaultMode = modeOptions.length === 1 ? modeOptions[0].value : ''
    const isValidMode = modeOptions.some((m) => m.value === selectedMode)
    if (!isValidMode && selectedMode !== defaultMode) {
      setFieldValue(modeKey, defaultMode, false)
    }
  }, [modeKey, setFieldValue, modeOptions, selectedMode])

  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        required
        newLine
        options={modeOptions}
        validators={{
          oneOf: { validValues: new Set(modeOptions.filter((o) => !o.disabled).map(({ value }) => value)) },
        }}
      />

      {selectedMode === CoaxPortMode.sdi && <VaSdiPortForm namePrefix={namePrefix} />}
      {selectedMode === MatroxPortMode.matroxSdi && settings && isMatroxApplianceSettings(settings) && (
        <MatroxSdiPortForm
          namePrefix={namePrefix}
          physicalPortIndex={physicalPortIndex}
          port={logicalPort}
          settings={settings as MatroxDecoderApplianceSettings}
        />
      )}
    </>
  )
}

export default CoaxPortForm
