import { createAsyncThunk } from '@reduxjs/toolkit'

import { UsageReport } from 'common/api/v1/types'
import { UsageRequestParams } from 'src/api/nm-types'
import { withDefaultPagination } from '../../utils'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar } from './notificationActions'

export const readUsage = createAsyncThunk<UsageReport, UsageRequestParams, ThunkApi>(
  'usage/readUsage',
  async ({ startDate, endDate, ...params }, { dispatch, extra: { api } }) => {
    try {
      return await api.usageApi.readUsage({ startDate, endDate, ...withDefaultPagination(params) })
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch usage' }))
      throw err
    }
  },
)
