import { createSlice } from '@reduxjs/toolkit'

import { AuditLog } from 'common/api/v1/types'
import { getAudit } from '../actions/auditActions'
import { logoutUser } from '../actions/userActions'
import { createLoadingReducer } from './shared'

interface State {
  audit: Array<AuditLog>
  total: number
  loading: boolean
}
export const initialStateAudit: State = {
  audit: [],
  total: 0,
  loading: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getAudit)

const auditSlice = createSlice({
  name: 'audit',
  initialState: initialStateAudit,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAudit.fulfilled,
        (state, { payload: { items: audit, total } }): State => ({
          ...state,
          audit,
          total,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateAudit)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default auditSlice.reducer
