import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'

import { enqueueAlarmSnackbar, enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { getCurrentPage, getIsStreamManager, sleep, withDefaultPagination } from '../../utils'
import { AlarmsRequestParams } from '../../api/nm-types'
import { AlarmSeverityLevels, AlarmWithImpact, ListResult, User, VaAlarmOwnSeverityLevels } from 'common/api/v1/types'

const getIsCritical = (alarms: AlarmWithImpact[]) => alarms.length > 200
let isInitial = true

export const readAlarmsInBackground = createAsyncThunk<AlarmWithImpact[], { silent?: boolean }, ThunkApi>(
  'alarms/readAlarms',
  async ({ silent }, { dispatch, getState, extra: { api } }) => {
    try {
      const activeAlarms = await api.alarmsApi.readAlarmsInBackground()
      {
        const actions: any[] = []
        const isCritical = getIsCritical(activeAlarms)
        if (isCritical) {
          actions.push(setCriticalAlarmsAmount(true))
        } else {
          if (!isInitial) {
            const previousAlarms = getState().alarmsReducer.alarmsForNotifications
            let alarm: {
              message: string
              severity?: AlarmSeverityLevels
            } | null = null
            let amount = 0
            activeAlarms.some((item) => {
              if (!previousAlarms.find(({ alarmId }) => alarmId === item.alarmId)) {
                const severity = item.alarmSeverity
                if (severity !== VaAlarmOwnSeverityLevels.cleared) {
                  if (amount) {
                    alarm = {
                      message: 'You have new active alarms',
                    }
                    return true
                  } else {
                    amount++
                    alarm = {
                      message: `ALARM: ${item.alarmCause}`,
                      severity,
                    }
                  }
                }
              }
            })
            if (alarm) actions.push(enqueueAlarmSnackbar(alarm))
            if (getState().alarmsReducer.isCriticalAmount) actions.push(setCriticalAlarmsAmount(false))
          }
        }
        if (isInitial) isInitial = false
        actions.forEach((action) => dispatch(action))
        return activeAlarms
      }
    } catch (err) {
      if (!silent) {
        dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch alarms' }))
      }
      throw err
    }
  },
)

export const getAlarms = createAsyncThunk<
  ListResult<AlarmWithImpact>,
  Partial<AlarmsRequestParams> & { silent?: boolean },
  ThunkApi
>('alarms/getAlarms', async (params, { dispatch, extra: { api } }) => {
  try {
    return await api.alarmsApi.getAlarms(withDefaultPagination({ ...params, rowsPerPage: '200' }))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch alarms' }))
    throw err
  }
})

export const clearAlarm = createAsyncThunk<void, Pick<AlarmWithImpact, 'alarmId'>, ThunkApi>(
  'alarms/clearAlarm',
  async (params, { dispatch, extra: { api } }) => {
    try {
      await api.alarmsApi.updateAlarm(params.alarmId, { status: 'CLEAR' })
      dispatch(enqueueSuccessSnackbar('1 alarm cleared'))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'clear alarm' }))
      throw err
    }
  },
)

export const clearAlarms = createAction('alarms/clearAlarms')

let isPollingForAlarms = false
export const initAlarms = createAsyncThunk<void, void, ThunkApi>(
  'alarms/initAlarms',
  async (_, { dispatch, getState, extra: { routes } }) => {
    if (isPollingForAlarms) {
      return
    }
    isPollingForAlarms = true
    // eslint-disable-next-line no-constant-condition
    while (true) {
      await sleep(10000)
      if (
        !getIsStreamManager(getCurrentPage(), routes) &&
        !!(getState().userReducer.user as User).id &&
        !getState().userReducer.changingUser
      ) {
        await dispatch(readAlarmsInBackground({ silent: true }))
      }
    }
  },
)

export const setCriticalAlarmsAmount = createAsyncThunk<void, boolean, ThunkApi>(
  'alarms/setCriticalAlarmsAmount',
  async (isCritical, { dispatch }) => {
    if (isCritical) {
      dispatch(enqueueAlarmSnackbar({ message: 'Too many alarms present!' }))
    }
  },
)
