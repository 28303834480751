import {
    MatroxDecoderFramerateFamily,
    MatroxDecoderOutputResolution,
    MatroxDecoderOutputResolutionFamilyOne,
    MatroxDecoderOutputResolutionFamilyThree,
    MatroxDecoderOutputResolutionFamilyTwo,
    MatroxOutputPort,
    MatroxPortMode,
    OutputPort,
    PhysicalPort,
} from './api/v1/types'

// A virtual interface is used to represent a grid layout matrox E4 input (receiving from multiple SDI interfaces at once.)
export function isVirtualAggregatedPort(port: Pick<PhysicalPort, 'index'>): boolean {
    return port.index === '0,1,2,3'
}

export const RESOLUTION_4K = 2160
export function is4kResolution(resolution: MatroxDecoderOutputResolution | string) {
    return resolution.includes(RESOLUTION_4K.toString())
}

export function isMatroxSdi12gPort(portIndex: number): boolean {
    // Matrox port index is 0-based. The first 3 SDI ports are 3G and the fourth is 12G (4k capabilities).
    return portIndex === 3
}

export function isMatroxOutputPort(outputPort: OutputPort): outputPort is MatroxOutputPort {
    return outputPort.mode === MatroxPortMode.matroxSdi
}

export function isResolutionInFramerateFamily(
    resolution: MatroxDecoderOutputResolution,
    framerateFamily: MatroxDecoderFramerateFamily
) {
    const familyMembers = getResolutionsInFramerateFamily(framerateFamily)
    return familyMembers.includes(resolution)
}

export function getResolutionsInFramerateFamily(framerateFamily: MatroxDecoderFramerateFamily): string[] {
    switch (framerateFamily) {
        case MatroxDecoderFramerateFamily.familyOne:
            return Object.values(MatroxDecoderOutputResolutionFamilyOne)
        case MatroxDecoderFramerateFamily.familyTwo:
            return Object.values(MatroxDecoderOutputResolutionFamilyTwo)
        case MatroxDecoderFramerateFamily.familyThree:
            return Object.values(MatroxDecoderOutputResolutionFamilyThree)
    }
    throw new Error(`Unknown framerate family: ${framerateFamily}`)
}
