import { Grid } from '@mui/material'

import { UsageDetail, UsageReport } from 'common/api/v1/types'
import { Table } from '../../common/Table'
import { getTransferredString } from 'common/api/v1/helpers'

interface DetailsProps {
  usage: UsageReport | undefined
  loading: boolean
}

const formatDateTime = (d: Date): string => {
  return d.toISOString().substring(0, 19).replace('T', ' ')
}

const tableConfig = [
  {
    title: 'Start date (UTC)',
    getValue: ({ startDate }: UsageDetail) => formatDateTime(startDate),
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'End date (UTC)',
    getValue: ({ endDate }: UsageDetail) => (endDate && formatDateTime(endDate)) || '',
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Type',
    getValue: ({ type }: UsageDetail) => type,
  },
  {
    title: 'Input',
    getValue: ({ inputName }: UsageDetail) => inputName,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Input ID',
    getValue: ({ inputId }: UsageDetail) => inputId,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Input group',
    getValue: ({ inputGroup }: UsageDetail) => inputGroup,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Input appliance',
    getValue: ({ inputApplianceName }: UsageDetail) => inputApplianceName,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Input secondary appliance',
    getValue: ({ inputSecondaryApplianceName }: UsageDetail) => inputSecondaryApplianceName || '',
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Input secondary appliance region',
    getValue: ({ inputSecondaryApplianceRegion }: UsageDetail) => inputSecondaryApplianceRegion || '',
  },
  {
    title: 'Input region',
    getValue: ({ inputRegion }: UsageDetail) => inputRegion,
  },
  {
    title: 'Input SMPTE 2022-7 enabled',
    getValue: ({ inputIsSmpte_2022_7_Enabled }: UsageDetail) => inputIsSmpte_2022_7_Enabled?.toString(),
  },
  {
    title: 'Input Redundancy',
    getValue: ({ inputRedundancyEnabled }: UsageDetail) => inputRedundancyEnabled?.toString(),
  },
  {
    title: 'Input protocol',
    getValue: ({ inputProtocol }: UsageDetail) => inputProtocol,
  },
  {
    title: 'Programs',
    getValue: ({ inputServiceCount }: UsageDetail) => inputServiceCount?.toString(),
  },
  {
    title: 'Output',
    getValue: ({ outputName }: UsageDetail) => outputName,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Output ID',
    getValue: ({ outputId }: UsageDetail) => outputId,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Output group',
    getValue: ({ outputGroup }: UsageDetail) => outputGroup,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Output appliance',
    getValue: ({ outputApplianceName }: UsageDetail) => outputApplianceName,
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Output region',
    getValue: ({ outputRegion }: UsageDetail) => outputRegion,
  },
  {
    title: 'Output SMPTE 2022-7 enabled',
    getValue: ({ outputIsSmpte_2022_7_Enabled }: UsageDetail) => outputIsSmpte_2022_7_Enabled?.toString(),
  },
  {
    title: 'Output Redundancy',
    getValue: ({ outputRedundancyEnabled }: UsageDetail) => outputRedundancyEnabled?.toString(),
  },
  {
    title: 'Output protocol',
    getValue: ({ outputProtocol }: UsageDetail) => outputProtocol,
  },
  {
    title: 'Ingress Data Transfer',
    getValue: ({ ingressTrafficGB }: UsageDetail) => getTransferredString(ingressTrafficGB),
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
  {
    title: 'Egress Data Transfer',
    getValue: ({ egressTrafficGB }: UsageDetail) => getTransferredString(egressTrafficGB),
    props: { style: { whiteSpace: 'nowrap' as const } },
  },
]
const Details = ({ usage, loading }: DetailsProps) => (
  <Grid item style={{ maxWidth: '100%' }}>
    <Table<UsageDetail>
      emptyMessageComponent={<div>No usage details</div>}
      props={{ size: 'small' }}
      config={tableConfig}
      data={usage?.details || []}
      pending={loading}
      pagination={{ total: usage?.total || 0, useUrlSearchParams: true }}
      isSmall
    />
  </Grid>
)

export default Details
