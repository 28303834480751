export const VaVersions = {
    'VA-15.1.0.2': parseVaVersion('VA-15.1.0.2')!,
    'VA-16.4.0.0': parseVaVersion('VA-16.4.0.0')!,
} as const

export function vaVersionIsGreaterOrEqual(vaVersion: ParsedVaVersion | undefined, referenceVersion: ParsedVaVersion) {
    if (!vaVersion) {
        return false
    }

    try {
        const comp = compareVaVersions(vaVersion, referenceVersion)
        return comp >= 0
    } catch {
        return false
    }
}

function compareVaVersions(v1: ParsedVaVersion, v2: ParsedVaVersion) {
    if (v1.series !== v2.series) {
        throw new Error(`Cannot compare va version ${JSON.stringify(v1)} to ${JSON.stringify(v2)}`)
    }
    const diff = v1.number - v2.number
    return Math.min(Math.max(diff, -1), 1)
}

interface ParsedVaVersion {
    series: string
    number: number
}

export function parseVaVersion(vaVersionString?: string): ParsedVaVersion | undefined {
    if (!vaVersionString || typeof vaVersionString !== 'string') {
        return undefined
    }

    const splitResult = vaVersionString.replace(/-SE$/, '').split('-')
    if (splitResult.length !== 2) {
        return undefined
    }
    const [series, versionNumberString] = splitResult
    const numbers = versionNumberString.split('.').map((n) => parseInt(n))
    if (numbers.length !== 4 || numbers.some((n) => n !== n)) {
        return undefined
    }
    const versionNumber = numbers.reverse().reduce((ack, cur, i) => {
        return ack + Math.pow(10, 3 * i) * cur
    })
    return { series, number: versionNumber }
}

export function vaVersionSupportsAdaptiveFec(vaVersionString?: string) {
    return vaVersionString && vaVersionIsGreaterOrEqual(parseVaVersion(vaVersionString), VaVersions['VA-15.1.0.2'])
}
export function vaVersionSupports12MTimeCode(vaVersionString?: string) {
    return vaVersionString && vaVersionIsGreaterOrEqual(parseVaVersion(vaVersionString), VaVersions['VA-16.4.0.0'])
}
