import { useState } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

interface Props {
  initialColor: Rgb
  label: string
  onColorSelected: (rgb: Rgb) => void
  required?: boolean
  disabled?: boolean
}

interface Rgb {
  red: number
  green: number
  blue: number
}

export const ColorPicker = ({ label, initialColor, onColorSelected, required, disabled }: Props) => {
  const [hex, setHex] = useState(rgbToHex(initialColor))
  const isValidColor = isValidHexColor(hex)
  const onDone = () => {
    let color = hex
    if (!isValidColor) {
      color = '#000000'
      setHex(color)
    }
    onColorSelected(hexToRgb(color))
  }
  return (
    <TextField
      label={label}
      margin={'normal'}
      style={{ width: '100%' }}
      required={required ?? false}
      disabled={disabled ?? false}
      value={hex}
      error={!isValidColor}
      onChange={(event) => setHex(event.target.value)}
      onBlur={onDone}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <input
              type={'color'}
              disabled={disabled ?? false}
              value={hex}
              onChange={(event) => setHex(event.target.value)}
              onBlur={onDone}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

function componentToHex(c: number) {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

function rgbToHex({ red, green, blue }: Rgb) {
  return '#' + componentToHex(red) + componentToHex(green) + componentToHex(blue)
}

function hexToRgb(hex: string): Rgb {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : {
        red: 0,
        green: 0,
        blue: 0,
      }
}

function isValidHexColor(maybeHex: string): boolean {
  return /#[a-f0-9]{6}$/.test(maybeHex.toLowerCase())
}
