import { GroupWithPermission, IGroupsApi } from './api'
import { MOCK_DELAY_MS, MOCK_GROUPS } from '../MockData'
import {
  InputAccessType,
  ListResult,
  Group,
  GroupInit,
  GroupOrGroupRecipientList,
  GroupUpdate,
} from 'common/api/v1/types'
import * as uuid from 'uuid'
import { listResult } from '../../utils'

export class MockGroupsApi implements IGroupsApi {
  createGroup(group: GroupInit): Promise<Group> {
    return new Promise((resolve) =>
      setTimeout(() => {
        return resolve(
          Object.assign([], {
            name: group.name,
            applianceSecret: group.applianceSecret,
            id: uuid.v4(),
          }),
        )
      }, MOCK_DELAY_MS),
    )
  }

  updateGroup(_id: string, group: GroupUpdate): Promise<Group> {
    return new Promise((resolve) =>
      setTimeout(() => {
        return resolve({
          ...group,
          id: (group as Group).id || uuid.v4(),
        })
      }, MOCK_DELAY_MS),
    )
  }

  removeGroup(id: string): Promise<Group> {
    return new Promise((resolve) =>
      setTimeout(() => {
        return resolve({ id, name: 'dummy' })
      }, MOCK_DELAY_MS),
    )
  }

  getGroup(): Promise<Group> {
    return new Promise<Group>((resolve) => setTimeout(() => resolve(MOCK_GROUPS[0]), MOCK_DELAY_MS))
  }

  getGroupsWithLists(): Promise<ListResult<GroupOrGroupRecipientList>> {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            items: MOCK_GROUPS as Array<GroupOrGroupRecipientList>,
            total: MOCK_GROUPS.length,
          }),
        MOCK_DELAY_MS,
      ),
    )
  }

  getPureGroups(): Promise<ListResult<Group>> {
    return new Promise((resolve) => setTimeout(() => resolve(listResult(MOCK_GROUPS)), MOCK_DELAY_MS))
  }

  getGroupPermissions(): Promise<ListResult<GroupWithPermission>> {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            items: [
              {
                ...MOCK_GROUPS[0],
                _permission: { accessType: InputAccessType.pull, groupId: MOCK_GROUPS[0].id },
              },
            ],
            total: MOCK_GROUPS.length,
          }),
        MOCK_DELAY_MS,
      ),
    )
  }
}
