import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import validatejs from 'validate.js'

import {
  Address,
  ApplianceType,
  ApplianceVersion,
  FecLimits,
  Output,
  OutputPortFec,
  RtpOutputPort,
} from 'common/api/v1/types'

import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues as createDefaultFieldValues, makeAddressOptions } from '../../../../../utils'
import { supportsFec } from 'common/versions'
import { standardFecSettings } from 'common/applianceTypes' // TODO: Remove usage of static/compiled applianceTypes

const addFecValidator = (namePrefix: string, port: RtpOutputPort, fecMaxSize: number) => {
  validatejs.validators[`fec-${namePrefix}`] = (value: number | '', { min, max }: { min: number; max: number }) => {
    if (value === '') return
    if (value < min || value > max) {
      return `Must be ${min} - ${max}`
    }
    if (port && port.fecRows && port.fecCols) {
      const val = port.fecRows * port.fecCols
      if (val > fecMaxSize) {
        return `FecRows multiplied by FecCols equals ${val}, which exceeds maximum value of ${fecMaxSize}`
      }
    }
    return undefined
  }
}

export enum RtpFields {
  sourceAddress = 'sourceAddress',
  address = 'address',
  port = 'port',
  fec = 'fec',
  fecRows = 'fecRows',
  fecCols = 'fecCols',
  ttl = 'ttl',
}
export const rtpDefaults = () => createDefaultFieldValues(Object.keys(RtpFields), [], { ttl: 64 })
export const getRtpFieldsToSave = () => [
  RtpFields.sourceAddress,
  RtpFields.address,
  RtpFields.port,
  RtpFields.fec,
  RtpFields.fecRows,
  RtpFields.fecCols,
  RtpFields.ttl,
]

interface RtpFormProps {
  form: FormikProps<Output>
  namePrefix: string
  addresses: Array<Address>
  applianceType: ApplianceType
  applianceVersion: ApplianceVersion
  applianceFecLimits?: FecLimits
}
const RtpForm = ({
  form: { values, setFieldValue },
  namePrefix,
  addresses,
  applianceType,
  applianceVersion,
  applianceFecLimits,
}: RtpFormProps) => {
  const port: RtpOutputPort = get(values, namePrefix)
  useEffect(() => {
    if (!port.fec) {
      if (port.fecCols) {
        setFieldValue(`${namePrefix}.fecCols`, '', false)
      }
      if (port.fecRows) {
        setFieldValue(`${namePrefix}.fecRows`, '', false)
      }
    }
  }, [port, namePrefix, setFieldValue])

  const isFecSupported = supportsFec(applianceType, applianceVersion)
  const isFecEnabled = !!port.fec
  const fecRowMin = applianceFecLimits?.rowRange.min ?? standardFecSettings.rowRange.min
  const fecRowMax = applianceFecLimits?.rowRange.max ?? standardFecSettings.rowRange.max
  const fecColMin = applianceFecLimits?.colRange.min ?? standardFecSettings.colRange.min
  const fecColMax = applianceFecLimits?.colRange.max ?? standardFecSettings.colRange.max
  const fecMaxSize = applianceFecLimits?.maxSize ?? standardFecSettings.maxSize

  addFecValidator(namePrefix, port, fecMaxSize)

  const sourceAddressSelector = `${namePrefix}.${RtpFields.sourceAddress}`
  const selectedSourceAddress = get(values, sourceAddressSelector)
  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [{ name: 'any', value: '' }, ...makeAddressOptions(selectedSourceAddress, addresses)]

  return (
    <>
      <Select
        name={sourceAddressSelector}
        label="Source Address"
        options={addressOptions}
        newLine
        validators={{
          addressIn: { addresses: addressesAndEmptyAddress },
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.address}`}
        label="Destination address"
        required
        type="text"
        newLine
        validators={{
          ip: {},
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.port}`}
        label="Destination Port"
        required
        type="number"
        noNegative
        validators={{
          port: { isUdp: true },
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.ttl}`}
        label="TTL"
        required
        type="number"
        validators={{ number: { greaterThanOrEqualTo: 1, lessThanOrEqualTo: 255 } }}
      />
      {isFecSupported && (
        <>
          <Select
            newLine
            label="Fec"
            name={`${namePrefix}.${RtpFields.fec}`}
            options={[{ name: 'none', value: '' }, ...Object.values(OutputPortFec)]}
          />
          <TextInput
            newLine
            disabled={!isFecEnabled}
            required={isFecEnabled}
            name={`${namePrefix}.${RtpFields.fecRows}`}
            label="Fec rows"
            type="number"
            validators={{
              [`fec-${namePrefix}`]: {
                min: fecRowMin,
                max: fecRowMax,
              },
            }}
          />
          <TextInput
            disabled={!isFecEnabled}
            required={isFecEnabled}
            name={`${namePrefix}.${RtpFields.fecCols}`}
            label="Fec cols"
            type="number"
            validators={{
              [`fec-${namePrefix}`]: {
                min: fecColMin,
                max: fecColMax,
              },
            }}
          />
        </>
      )}
    </>
  )
}

export default RtpForm
