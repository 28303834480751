import { BuildInfo } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IBuildInfoApi {
  readBuildInfo(): Promise<BuildInfo>
}

export class BuildInfoApi implements IBuildInfoApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  readBuildInfo(): Promise<BuildInfo> {
    return this.edgeClient.getBuildInfo()
  }
}
