export interface SrtStatsWindow {
    flow: number
    congestion: number
    flight: number
}

export interface SrtLink {
    rtt: number
    bandwidth: number
    maxBandwidth: number
}

export interface SrtSendStats {
    packets: number
    packetsLost: number
    packetsDropped: number
    packetsRetransmitted: number
    packetsFilterExtra: number
    bytes: number
    bytesDropped: number
    mbitRate: number

    // Custom srt stat property, not available in srt-live-transmit by default
    // Update: Now, the msSendBuffer stat is available in upstream srt-live-transmit under the name msBuf instead.
    // We remap msBuf -> msSendBuffer before forwarding the stats to Edge in order to be compatible with the old metrics.
    msSendBuffer: number
}

export interface SrtRecvStats {
    packets: number
    packetsLost: number
    packetsDropped: number
    packetsRetransmitted: number
    packetsBelated: number
    packetsFilterExtra: number
    packetsFilterSupply: number
    packetsFilterLoss: number
    bytes: number
    bytesLost: number
    bytesDropped: number
    mbitRate: number

    // Custom srt stat property, not available in srt-live-transmit by default
    msRecvBuffer: number
}

export interface SrtStats {
    remoteAddress?: string
    sid: number
    time: number
    window: SrtStatsWindow
    link: SrtLink
    send: SrtSendStats
    recv: SrtRecvStats

    // Custom srt stat property, not available in srt-live-transmit by default
    connectionStatus: SrtConnectionStatus
    // edge stream id used for correlation purposes, not to be confused with srt stream id
    edgeStreamid?: number
}

export enum SrtConnectionStatus {
    connected = 'connected',
    disconnected = 'disconnected',
}

export interface SrtStreamTags {
    channelId: number
    streamId: number
    outputId?: string
    type: string
}
