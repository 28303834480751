import { Route } from 'react-router-dom'

import AlarmsTable from './Table'

import { useRoutes } from '../../store'

export const Alarms = () => {
  const routes = useRoutes()
  return <Route exact path={routes.alarms.route} component={AlarmsTable} />
}
