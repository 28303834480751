import { createSlice } from '@reduxjs/toolkit'

import { GlobalSettings, ImageName, TlsCertRead } from 'common/api/v1/types'
import {
  getSettings,
  saveSettings,
  FileInfo,
  setImage,
  setImageToDelete,
  saveImages,
  saveTls,
  setDevMode,
} from '../actions/settingsActions'

import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import { createLoadingReducer } from './shared'

interface State {
  loading: boolean
  saving: boolean
  settings?: GlobalSettings
  savingImages: boolean
  [ImageName.product]?: FileInfo
  [ImageName.serviceProvider]?: FileInfo
  [ImageName.favicon]?: FileInfo
  toDelete: {
    [ImageName.product]: boolean
    [ImageName.serviceProvider]: boolean
    [ImageName.favicon]: boolean
  }
  savingTls: boolean
  tls?: TlsCertRead
  devMode: boolean
}
export const initialStateBilling: State = {
  loading: false,
  saving: false,
  savingImages: false,
  savingTls: false,
  toDelete: {
    [ImageName.product]: false,
    [ImageName.serviceProvider]: false,
    [ImageName.favicon]: false,
  },
  devMode: localStorage.getItem('dev-mode') === 'enabled',
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getSettings)

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialStateBilling,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveSettings.pending, (state): State => ({ ...state, saving: true }))
      .addCase(getSettings.fulfilled, (state, { payload: { settings, tls } }): State => ({ ...state, settings, tls }))
      .addCase(saveSettings.fulfilled, (state, { payload: settings }): State => ({ ...state, saving: false, settings }))
      .addCase(saveSettings.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(
        setImage,
        (state, { payload: { name, file } }): State => ({
          ...state,
          [name]: { ...file, file: file?.file?.slice() },
          toDelete: { ...state.toDelete, [name]: false },
        }),
      )
      .addCase(
        setImageToDelete,
        (state, { payload: name }): State => ({
          ...state,
          toDelete: { ...state.toDelete, [name]: true },
          [name]: undefined,
        }),
      )
      .addCase(saveImages.pending, (state): State => ({ ...state, savingImages: true }))
      .addCase(saveTls.pending, (state): State => ({ ...state, savingTls: true }))
      .addCase(saveTls.rejected, (state): State => ({ ...state, savingTls: false }))
      .addCase(saveTls.fulfilled, (state, { payload: tls }): State => ({ ...state, savingTls: false, tls }))
      .addCase(logoutUser.fulfilled, (): State => initialStateBilling)
      .addCase(setDevMode, (state, { payload }): State => ({ ...state, devMode: payload }))
      .addMatcher(
        isOneOf([saveImages.fulfilled, saveImages.rejected]),
        (state): State => ({ ...state, savingImages: false }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default settingsSlice.reducer
