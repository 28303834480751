import { GlobalSettings } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface ISettingsApi {
  getSettings(): Promise<GlobalSettings>
  saveSettings(params: GlobalSettings): Promise<GlobalSettings>
}

export class SettingsApi implements ISettingsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getSettings(): Promise<GlobalSettings> {
    return this.edgeClient.getGlobalsettings()
  }
  saveSettings(params: GlobalSettings): Promise<GlobalSettings> {
    return this.edgeClient.setGlobalsettings(params)
  }
}
