import { Route } from 'react-router-dom'
import { NetworksList } from './NetworksList'
import EditNetwork from './NetworkForm'

import { useRoutes } from '../../store'

export const Networks = () => {
  const routes = useRoutes()
  return (
    <>
      <Route path={routes.networkUpdate.route} component={EditNetwork} />
      <Route exact path={routes.networks.route} component={NetworksList} />
      <Route path={routes.networkNew.route} component={EditNetwork} />
    </>
  )
}
