import { IImageApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import { ImageDetails, ImageName } from 'common/api/v1/types'

export class MockImageApi implements IImageApi {
  getImageDetails(): Promise<ImageDetails> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          name: ImageName.favicon,
          id: '123',
          type: 'png',
        })
      }, MOCK_DELAY_MS),
    )
  }
  setImage(): Promise<string> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve('!!!!')
      }, MOCK_DELAY_MS),
    )
  }
  deleteImage(): Promise<string> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve('!!!!')
      }, MOCK_DELAY_MS),
    )
  }
}
