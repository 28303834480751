import { Route, Switch } from 'react-router-dom'

import { StreamManagerContainer } from './StreamManagerContainer'
import { NetworkManagerContainer } from './NetworkManagerContainer'
import { useUser } from '../../utils'
import { Role } from 'common/api/v1/types'
import { ConfirmDialogProvider } from '../common/ConfirmDialog'
import { ContentDialogProvider } from '../common/ContentDialog'

import { useRoutes } from '../../store'

const LoggedIn = () => {
  const user = useUser()
  const routes = useRoutes()
  return (
    <ConfirmDialogProvider>
      <ContentDialogProvider>
        <>
          <Switch>
            <Route
              path={user.role !== Role.basic ? routes.stream.route : undefined}
              component={StreamManagerContainer}
            />
            {user.role !== Role.basic && <Route component={NetworkManagerContainer} />}
          </Switch>
        </>
      </ContentDialogProvider>
    </ConfirmDialogProvider>
  )
}

export default LoggedIn
