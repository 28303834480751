import type { IRegionApi, RegionDetails } from './api'
import { MOCK_DELAY_MS, MOCK_REGIONS } from '../MockData'
import { ListResult, Region, ScaleOperation } from 'common/api/v1/types'

import { listResult } from '../../utils'

export class MockRegionApi implements IRegionApi {
  getRegions(): Promise<ListResult<Region>> {
    return new Promise((resolve) => setTimeout(() => resolve(listResult(MOCK_REGIONS)), MOCK_DELAY_MS))
  }
  getRegionDetailsById(): Promise<RegionDetails> {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            region: MOCK_REGIONS[0],
            videoScale: { replicas: 1, currentReplicas: 1 },
            thumbScale: { replicas: 1, currentReplicas: 1 },
          }),
        MOCK_DELAY_MS,
      ),
    )
  }
  setVideoScale(): Promise<ScaleOperation> {
    return new Promise((resolve) => setTimeout(() => resolve({ count: 1 }), MOCK_DELAY_MS))
  }
  setThumbScale(): Promise<ScaleOperation> {
    return new Promise((resolve) => setTimeout(() => resolve({ count: 1 }), MOCK_DELAY_MS))
  }
}
