import { EdgeClient } from 'common/generated/edgeClient'
import { EnhancedInputConfig } from 'common/api/v1/internal'

export interface IGraphApi {
  readGraph(): Promise<EnhancedInputConfig>
}

export class GraphApi implements IGraphApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  readGraph(): Promise<EnhancedInputConfig> {
    return this.edgeClient.getServiceGraphConfig()
  }
}
