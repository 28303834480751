import { AddFilterView, AddFilterViewProps } from './AddFilter'
import { Popover, SxProps } from '@mui/material'
import { useRef, useState } from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { FilterBy } from './FilterTemplate'
import { areObjectsEqual } from 'common/util'

interface FilterPopupProps {
  popupAnchor?: HTMLElement
  paperSx?: SxProps
  onCancelClicked: () => void
  onApplyClicked: (savedFilters: FilterBy[]) => void
}
type Props = FilterPopupProps & Omit<AddFilterViewProps, 'onFiltersUpdated'>
export const FilterPopup = (props: Props) => {
  const { popupAnchor, onCancelClicked, onApplyClicked, paperSx, ...filtersProps } = props

  const savedFiltersRef = useRef<FilterBy[]>([])
  const [hasMadeChanges, setHasMadeChanges] = useState(false)

  const resetState = (callback: () => void) => {
    // Popup component persists between presentations, i.e. we need to manually clear our state
    // (but call the callback first)
    callback()

    setHasMadeChanges(false)
    savedFiltersRef.current = []
  }

  return (
    <Popover
      PaperProps={{ sx: paperSx ?? { width: '50%' } }}
      open={!!popupAnchor}
      onClose={(_e, reason) => {
        // Don't close on backdropClick since it is too easy to do unintentionally
        if (reason === 'escapeKeyDown') {
          resetState(onCancelClicked)
        }
      }}
      anchorEl={popupAnchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <AddFilterView
        initialFilters={filtersProps.initialFilters}
        availableFilters={filtersProps.availableFilters}
        onFiltersUpdated={(filters) => {
          savedFiltersRef.current = filters
          setHasMadeChanges(!areFilterListsEqual(props.initialFilters, filters))
        }}
      />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => resetState(onCancelClicked)}
          data-test-id="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => resetState(() => onApplyClicked([...savedFiltersRef.current]))}
          data-test-id="apply-filter-btn"
          disabled={!hasMadeChanges}
        >
          Apply
        </Button>
      </DialogActions>
    </Popover>
  )
}

function areFilterListsEqual(a: FilterBy[], b: FilterBy[]) {
  if (a.length === b.length) {
    const sortedA = [...a].sort(compareFn)
    const sortedB = [...b].sort(compareFn)
    return sortedA.every((filter, index) => areObjectsEqual(filter, sortedB[index]))
  }
  return false
}

function compareFn(f1: FilterBy, f2: FilterBy) {
  return f1.key.localeCompare(f2.key)
}
