import { EdgeClient } from 'common/generated/edgeClient'

export interface IApiTokenApi {
  ensureOperatorToken(): Promise<void>
}

export class ApiTokenApi implements IApiTokenApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  /**
   * Creates and ensures a super user API token to be used by the Edge Operator. This needs to be done before installing the Operator in an existing cluster.
   */
  ensureOperatorToken(): Promise<void> {
    return this.edgeClient.setApiTokenEnsureOperatorToken()
  }
}
