import { useState, FormEvent } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import FormHelperText from '@mui/material/FormHelperText'
import { styled, Theme } from '@mui/material/styles'

import { errorTitle, ErrorCode } from 'common/errors'
import { GlobalState, AppDispatch } from '../../store'
import { Loading } from '../common/Loading'
import { loginUser } from '../../redux/actions/userActions'
import { LoginLogo } from '../common/LogoFetcher'

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}))

const styles = {
  paper: {
    marginTop: (theme: Theme) => theme.spacing(8),
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  submit: {
    margin: (theme: Theme) => theme.spacing(3, 0, 2),
  },
}

const commonInputProps = {
  variant: 'outlined' as const,
  margin: 'normal' as const,
  required: true,
  fullWidth: true,
  InputLabelProps: { shrink: true },
}

const ErrorHelper = ({ shown }: { shown?: boolean }) =>
  shown ? (
    <FormHelperText error variant="filled">
      Your authentication information is incorrect.
    </FormHelperText>
  ) : null

type LoginState = { username: string; password: string; otp?: string }

export const Login = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, loginFail } = useSelector((state: GlobalState) => state.userReducer, shallowEqual)
  const isMFARequired = loginFail?.details === errorTitle(ErrorCode.mfaRequired)
  const [user, setUser] = useState<LoginState>({ username: '', password: '' })

  const handleLogin = (e: FormEvent) => {
    e.preventDefault()
    //TODO VALIDATE
    dispatch(loginUser(user))
    setUser({ ...user, otp: undefined })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Loading loading={loading} />
      <Box sx={styles.paper}>
        <LoginLogo />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form noValidate onSubmit={handleLogin}>
          {!isMFARequired ? (
            <>
              <TextField
                {...commonInputProps}
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value })}
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
              />
              <TextField
                {...commonInputProps}
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </>
          ) : (
            <TextField
              {...commonInputProps}
              onChange={(e) => setUser({ ...user, otp: e.target.value })}
              id="mfacode"
              label="MFA code"
              autoComplete="one-time-code"
              autoFocus
            />
          )}
          <ErrorHelper shown={loginFail !== undefined && !isMFARequired} />
          <Button
            id="login-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={styles.submit}
            disabled={!user.username || !user.password || (isMFARequired && !user.otp)}
          >
            Sign In
          </Button>
        </Form>
      </Box>
    </Container>
  )
}
