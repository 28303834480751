import { ListResult, Tunnel, TunnelOutputInfo } from 'common/api/v1/types'
import { ITunnelApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import { PaginatedRequestParams } from '../nm-types'

export class MockTunnelApi implements ITunnelApi {
  listTunnels(): Promise<ListResult<Tunnel>> {
    return new Promise<ListResult<Tunnel>>((resolve) =>
      setTimeout(() => resolve({ total: 0, items: [] }), MOCK_DELAY_MS),
    )
  }

  listTunnelOutputs(id: number, paginatedRequestParams: PaginatedRequestParams) {
    void id
    void paginatedRequestParams
    return new Promise<ListResult<TunnelOutputInfo>>((resolve) =>
      setTimeout(() => resolve({ total: 0, items: [] }), MOCK_DELAY_MS),
    )
  }
}
