import { TlsCertRead, TlsCertWrite } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface ITlsApi {
  getTls(): Promise<TlsCertRead>
  saveTls(params: TlsCertWrite): Promise<TlsCertRead>
}

export class TlsApi implements ITlsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getTls(): Promise<TlsCertRead> {
    return this.edgeClient.getTls()
  }
  saveTls(params: TlsCertWrite): Promise<TlsCertRead> {
    return this.edgeClient.setTls(params)
  }
}
