import { PaginatedRequestParams } from '../../../../api/nm-types'

export interface FilterTemplate {
  key: string
  operator: FilterBy['operator']
  possibleValues?: (searchText: string) => Promise<{ value: string; displayName: string }[]>
  possibleValuesSync?: (searchText?: string) => { value: string; displayName: string }[]
}

export type FilterBy = IsFilter | IsOneOfFilter | MatchesFilter

export interface IsOneOfFilter {
  key: string // e.g. appliance
  operator: 'isOneOf'
  values: { value: string; displayName: string }[]
}

export interface IsFilter {
  key: string // e.g. tag
  operator: 'is'
  value: string
  displayName?: string
}

export interface MatchesFilter {
  key: string // e.g. name
  operator: 'matches'
  value: string
  displayName?: string
}

export function removeFilterByKeyAndValue(keyToDelete: string, valueToDelete: string, filters: FilterBy[]): FilterBy[] {
  return filters.reduce((acc, filter) => {
    const copy = { ...filter }
    let addFilter = true
    if (copy.key === keyToDelete) {
      if ('values' in copy) {
        // Keep filter but remove value from filter.values
        copy.values = copy.values.filter((v) => v.value !== valueToDelete)
        addFilter = copy.values.length > 0
      } else {
        addFilter = false
      }
    }
    if (addFilter) {
      acc.push(copy)
    }
    return acc
  }, [] as FilterBy[])
}

export function urlParamsToFilters<T extends PaginatedRequestParams>(
  urlParams: T,
  mapUrlParamToFilterFn: (key: keyof T, value: string) => FilterBy | undefined,
): FilterBy[] {
  const filters: FilterBy[] = []
  for (const key of Object.keys(urlParams) as Array<keyof typeof urlParams>) {
    if (!urlParams[key]) {
      continue
    }
    const filter = mapUrlParamToFilterFn(key, urlParams[key] as string)
    if (filter) {
      filters.push(filter)
    }
  }
  return filters
}

export function filtersToUrlParams<T extends PaginatedRequestParams>(
  filters: FilterBy[],
  prevFilter: FilterBy[],
  mapFilterToUrlParamFn: (filter: FilterBy) => Partial<T>,
): Omit<T, 'pageNumber' | 'rowsPerPage' | 'asc' | 'desc'> {
  function toUrlParams(filters: FilterBy[]) {
    return filters.reduce((urlParams, filter) => {
      return {
        ...urlParams,
        ...mapFilterToUrlParamFn(filter),
      }
    }, {} as T)
  }

  const removedFilters = prevFilter.filter((prev) => !filters.some((current) => current.key === prev.key))
  const urlParams = toUrlParams(filters)
  const urlParamsToRemove = toUrlParams(removedFilters)
  for (const keyToRemove of Object.keys(urlParamsToRemove)) {
    if (!urlParams[keyToRemove as keyof typeof urlParams]) {
      ;(urlParams as any)[keyToRemove] = ''
    }
  }
  return urlParams
}
