import { ConnectitTokens } from 'common/api/v1/types'
import { ITokenApi } from './api'
export class MockTokenApi implements ITokenApi {
  async getConnectitTokens(): Promise<ConnectitTokens | null> {
    return {
      available: 10,
      used: 90,
    }
  }
}
