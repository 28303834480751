import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../common/Tooltip'

import { Appliance, Role } from 'common/api/v1/types'
import { pluralize, useConfirmationDialog, usePageParams } from '../../utils'
import { restartAppliances } from '../../redux/actions/applianceActions'
import { AppliancesRequestParams, EnrichedApplianceWithOwner, EnrichedUser } from '../../api/nm-types'
import { Api, AppDispatch, GlobalState } from '../../store'
import { ReduxSelectedFilters } from '../common/Filters/FilterView/SelectedFilters'
import {
  makeListApplianceFilter,
  mapApplianceFilterToUrlParam,
  mapUrlParamToApplianceFilter,
} from './listApplianceFilter'
import { FilterBy, filtersToUrlParams, urlParamsToFilters } from '../common/Filters/FilterView/FilterTemplate'
import { FilterPopup } from '../common/Filters/FilterView/AddFilterPopup'

const styles = {
  toolbarText: {
    flexGrow: 1,
  },
}

interface CommonActionsProps {
  appliances: EnrichedApplianceWithOwner[]
  selected: Array<Appliance['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  user: EnrichedUser
}

const BatchRestartButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()

  const onClick = () => {
    showConfirmation(() => {
      dispatch(restartAppliances({ ids: selected }))
      setSelected([])
    }, 'Restarting will affect all video streams on the appliances. After restart the appliances will run the latest software version. Do you want to continue?')
  }

  return (
    <Tooltip title={'Restart selected appliances'}>
      <span>
        <Button variant="contained" color="primary" onClick={onClick}>
          Restart
        </Button>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ selected, setSelected, appliances, user }: CommonActionsProps) => {
  const showButtons = selected.length > 0
  const selectedAppliances = appliances.filter(({ id }) => selected.includes(id))
  const isOwnerOfAllSelected = selectedAppliances.every(({ _owner }) => _owner.id === user.group)

  const [filterPopupAnchor, setFilterPopupAnchor] = React.useState<HTMLElement | undefined>(undefined)
  const closePopup = () => {
    setFilterPopupAnchor(undefined)
  }
  const closePopupAndSaveFilters = (savedFilters: FilterBy[]) => {
    closePopup()
    populateUrlWithSelectedFilters(savedFilters)
  }

  const [_, setUrlParams] = usePageParams<Omit<AppliancesRequestParams, 'rowsPerPage' | 'asc' | 'desc'>>()
  const { cachedUrlParams } = useSelector(({ urlParamReducer }: GlobalState) => urlParamReducer, shallowEqual)
  const cachedRequestParams = cachedUrlParams['appliances']
  const appliedFilters = cachedRequestParams
    ? urlParamsToFilters(cachedRequestParams, mapUrlParamToApplianceFilter)
    : []
  const populateUrlWithSelectedFilters = (filters: FilterBy[]) => {
    const urlParams = filtersToUrlParams(filters, appliedFilters, mapApplianceFilterToUrlParam)
    setUrlParams({ ...urlParams, pageNumber: '0' }) // Reset page to 0 when applying filters
  }

  return (
    <Toolbar disableGutters>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button variant="contained" onClick={(e) => setFilterPopupAnchor(e.currentTarget)}>
          Add filter
        </Button>
        <FilterPopup
          popupAnchor={filterPopupAnchor}
          onApplyClicked={closePopupAndSaveFilters}
          onCancelClicked={closePopup}
          initialFilters={appliedFilters}
          availableFilters={makeListApplianceFilter({
            applianceApi: Api.appliancesApi,
            groupApi: Api.groupsApi,
            regionApi: Api.regionApi,
          })}
        />
        <ReduxSelectedFilters
          urlParamCacheKey={'appliances'}
          mapUrlParamToFilterFn={mapUrlParamToApplianceFilter}
          mapFilterToUrlParamFn={mapApplianceFilterToUrlParam}
        />
      </div>

      <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {!!selected.length && (
          <Typography sx={styles.toolbarText} variant="subtitle1">
            {pluralize(selected.length, 'appliance')} selected
          </Typography>
        )}
        {!!selected.length && showButtons && (user.role === Role.super || isOwnerOfAllSelected) && (
          <div style={{ marginLeft: 8 }}>
            <BatchRestartButton selected={selected} setSelected={setSelected} />
          </div>
        )}
      </div>
    </Toolbar>
  )
}

export default CommonActions
