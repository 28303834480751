import Badge, { BadgeProps } from '@mui/material/Badge'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { AlarmSeverityLevels, AlarmWithImpact } from 'common/api/v1/types'

type IProps = {
  alarms: AlarmWithImpact[]
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    transform: 'unset',
    color: theme.palette.grey[900],
  },
  '& .MuiBadge-colorPrimary': {
    background: theme.palette.warning.main,
  },
  '& .MuiBadge-colorError': {
    background: theme.palette.error.main,
  },
}))

const AlarmItemNameWithActiveAlarms = ({ alarms }: IProps) => {
  const hasCritical = alarms.some((alarm) => alarm.alarmSeverity === AlarmSeverityLevels.critical)
  const badgeColor = hasCritical ? 'error' : 'primary'

  return (
    <Box sx={styles.container}>
      Alarms
      {alarms.length > 0 && (
        <StyledBadge overlap="rectangular" color={badgeColor} badgeContent={alarms.length} max={9} />
      )}
    </Box>
  )
}

export default AlarmItemNameWithActiveAlarms
