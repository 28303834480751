import { IpMappingRead, IpMappingType, IpMappingWrite } from 'common/api/v1/types'
import { IAddressMappingApi, MappingKey } from './api'

export class MockAddressMappingApi implements IAddressMappingApi {
  async updateIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead> {
    await this.setIpMapping(ipMapping)
    return this._ipMappings.find(
      (m) => m.privateIp === ipMapping.privateIp && m.region.id === ipMapping.region,
    ) as IpMappingRead
  }
  async getIpMapping({ privateIp, region }: MappingKey): Promise<IpMappingRead> {
    const mapping = this._ipMappings.find((m) => m.privateIp === privateIp && m.region.id === region)
    if (!mapping) {
      throw new Error()
    }
    return mapping
  }
  _ipMappings: IpMappingRead[] = []
  async setIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead[]> {
    const existing = this._ipMappings.find((ip) => ip.privateIp === ipMapping.privateIp)
    if (existing) {
      existing.publicIp = ipMapping.publicIp
      existing.internalIp = ipMapping.internalIp
      existing.interRegionIp = ipMapping.interRegionIp
    } else {
      this._ipMappings.push({
        ...ipMapping,
        nics: [],
        region: { id: ipMapping.region, name: ipMapping.region },
        type: IpMappingType.mapped,
      })
    }
    return this._ipMappings.slice()
  }
  async getIpMappings(): Promise<IpMappingRead[]> {
    return this._ipMappings.slice()
  }
  async removeIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead[]> {
    const { privateIp, region } = ipMapping
    const existingIndex = this._ipMappings.findIndex((ip) => ip.privateIp === privateIp && ip.region.id === region)
    if (existingIndex !== -1) {
      this._ipMappings.splice(existingIndex, 1)
    }
    return this._ipMappings.slice()
  }
}
