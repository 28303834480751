import { SerializedError } from '@reduxjs/toolkit'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { styles, Paper } from './Form'

const ApplicationError = ({ error, onOKButtonClicked }: { error: SerializedError; onOKButtonClicked: () => void }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper>
        <Typography component="h2" variant="h2">
          Something went wrong
        </Typography>
        <br />
        <Typography component="h2" variant="body2">
          {error?.message}
        </Typography>
        <br />
        <Button onClick={onOKButtonClicked} variant="contained" color="primary" sx={styles.button}>
          Go back
        </Button>
      </Paper>
    </Container>
  )
}

export default ApplicationError
