import { Checkbox, GridItem, Paper, Select, TextInput } from '../../../common/Form'
import Typography from '@mui/material/Typography'
import fullscreen from '../../../../img/1FullScreen.png'
import grid from '../../../../img/4Grid.png'
import { FormikProps } from 'formik'
import {
  MatroxAudioChannelPair,
  MatroxEncoderAACEncoder,
  MatroxEncoderAACFormat,
  MatroxEncoderAACQualityLevel,
  MatroxEncoderBitrateControlMode,
  MatroxEncoderConfig,
  MatroxEncoderEncodingMode,
  MatroxEncoderFlipOption,
  MatroxEncoderIncludeOption,
  MatroxEncoderPivotOption,
  MatroxEncoderPixelFormat,
  MatroxEncoderProcessorFrameRate,
  MatroxEncoderScalingOption,
  MatroxEncoderVideoProfile,
  PhysicalPort,
  PortType,
} from 'common/api/v1/types'
import Grid from '@mui/material/Grid'
import { RichOption } from '../../../common/Form/Select'
import {
  getAllowedBitratesForMatroxAACEncoder,
  getCompatibleMatroxVideoProfilesForPixelFormat,
  makeMatroxEncoderProcessorAudioSourceSettings,
  makeMatroxEncoderProcessorVideoSourceSettings,
  MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER,
  MATROX_ENCODER_FRAMESIZE_MAX,
  MATROX_ENCODER_FRAMESIZE_MIN,
  MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER,
  MATROX_ENCODER_IFRAME_INTERVAL_MAX,
  MATROX_ENCODER_IFRAME_INTERVAL_MIN,
  MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER,
  MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS,
  MATROX_ENCODER_MAXBITRATE_MAX,
  MATROX_ENCODER_MAXBITRATE_MIN,
  MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS,
  MATROX_ENCODER_PFRAME_INTERVAL_MAX,
  MATROX_ENCODER_PFRAME_INTERVAL_MIN,
  MATROX_ENCODER_QP_MAX,
  MATROX_ENCODER_QP_MIN,
  MATROX_ENCODER_TARGET_BITRATE_MIN,
  MATROX_ENCODER_VIDEO_BRIGHTNESS_MAX,
  MATROX_ENCODER_VIDEO_BRIGHTNESS_MIN,
  MATROX_ENCODER_VIDEO_CAPTURE_RATE_MAX,
  MATROX_ENCODER_VIDEO_CAPTURE_RATE_MIN,
  MATROX_ENCODER_VIDEO_CONTRAST_MAX,
  MATROX_ENCODER_VIDEO_CONTRAST_MIN,
  MATROX_ENCODER_VIDEO_HUE_MAX,
  MATROX_ENCODER_VIDEO_HUE_MIN,
  MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MAX,
  MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MIN,
  MATROX_ENCODER_VIDEO_SATURATION_MAX,
  MATROX_ENCODER_VIDEO_SATURATION_MIN,
} from 'common/encoderSettingsUtil'
import MuiCheckbox from '@mui/material/Checkbox'
import { useEffect } from 'react'
import { isVirtualAggregatedPort } from 'common/matrox'
import { ColorPicker } from '../../../common/ColorPicker'

interface Props {
  namePrefix: string
  physicalPort: PhysicalPort
  matroxSettings: MatroxEncoderConfig
  setFieldValue: FormikProps<any>['setFieldValue']
}

const inputSourceOptions: RichOption[] = [0, 1, 2, 3].map((i) => ({ name: `SDI ${i + 1}`, value: i }))

const frameRateOptions = Object.keys(MatroxEncoderProcessorFrameRate)
  .map((fr) => Number(fr))
  .filter((num) => !isNaN(num))
  .sort()

const encoderIncludeOptions: RichOption[] = Object.values(MatroxEncoderIncludeOption)
  .map((option: MatroxEncoderIncludeOption) => {
    switch (option) {
      case MatroxEncoderIncludeOption.none:
        return undefined
      case MatroxEncoderIncludeOption.audioOnly:
        return { name: 'Audio only', value: option }
      case MatroxEncoderIncludeOption.videoOnly:
        return { name: 'Video only', value: option }
      case MatroxEncoderIncludeOption.audioAndVideo:
        return { name: 'Audio and video', value: option }
    }
  })
  .filter(Boolean) as RichOption[]

const scalingOptions: RichOption[] = Object.values(MatroxEncoderScalingOption).map(
  (option: MatroxEncoderScalingOption) => {
    switch (option) {
      case MatroxEncoderScalingOption.noScalingAtTopLeft:
        return { name: 'Unscaled from top left', value: option }
      case MatroxEncoderScalingOption.noScalingAtCenter:
        return { name: 'Unscaled centered', value: option }
      case MatroxEncoderScalingOption.scaleToFitDestination:
        return { name: 'Stretched to all edges', value: option }
      case MatroxEncoderScalingOption.scaleToFillButKeepContentAspectRatio:
        return { name: 'Scaled to all edges', value: option }
      case MatroxEncoderScalingOption.scaleToFitAllContent:
        return { name: 'Scaled to nearest edge', value: option }
    }
  },
)

const pivotOptions: RichOption[] = Object.values(MatroxEncoderPivotOption).map((option: MatroxEncoderPivotOption) => {
  switch (option) {
    case MatroxEncoderPivotOption.none:
      return { name: 'None', value: option }
    case MatroxEncoderPivotOption.by90Degrees:
      return { name: 'By 90 degrees', value: option }
    case MatroxEncoderPivotOption.by180Degrees:
      return { name: 'By 180 degrees', value: option }
    case MatroxEncoderPivotOption.by270Degrees:
      return { name: 'By 270 degrees', value: option }
  }
})

const flipOptions: RichOption[] = Object.values(MatroxEncoderFlipOption).map((option: MatroxEncoderFlipOption) => {
  switch (option) {
    case MatroxEncoderFlipOption.none:
      return { name: 'None', value: option }
    case MatroxEncoderFlipOption.horizontally:
      return { name: 'Horizontally', value: option }
    case MatroxEncoderFlipOption.vertically:
      return { name: 'Vertically', value: option }
    case MatroxEncoderFlipOption.bothAxis:
      return { name: 'Both axis', value: option }
  }
})

const encodingProfileOptions: RichOption[] = Object.values(MatroxEncoderVideoProfile).map(
  (option: MatroxEncoderVideoProfile) => {
    switch (option) {
      case MatroxEncoderVideoProfile.Baseline:
        return { name: 'Baseline', value: option }
      case MatroxEncoderVideoProfile.Main:
        return { name: 'Main', value: option }
      case MatroxEncoderVideoProfile.High:
        return { name: 'High', value: option }
      case MatroxEncoderVideoProfile.High10Bit:
        return { name: 'High 10-bit', value: option }
      case MatroxEncoderVideoProfile.High422:
        return { name: 'High YUV 4:2:2', value: option }
    }
  },
)

const encodingModeOptions: RichOption[] = Object.values(MatroxEncoderEncodingMode).map(
  (option: MatroxEncoderEncodingMode) => {
    switch (option) {
      case MatroxEncoderEncodingMode.lowLatency:
        return { name: 'Optimised for low latency', value: option }
      case MatroxEncoderEncodingMode.highQuality:
        return { name: 'Favour image quality', value: option }
    }
  },
)

const bitrateControlOptions: RichOption[] = Object.values(MatroxEncoderBitrateControlMode).map(
  (option: MatroxEncoderBitrateControlMode) => {
    switch (option) {
      case MatroxEncoderBitrateControlMode.constant:
        return { name: 'Constant', value: option }
      case MatroxEncoderBitrateControlMode.variable:
        return { name: 'Variable', value: option }
    }
  },
)

const aacEncoderOptions: RichOption[] = Object.values(MatroxEncoderAACEncoder).map(
  (option: MatroxEncoderAACEncoder) => {
    switch (option) {
      case MatroxEncoderAACEncoder.lc:
        return { name: 'LC', value: option }
      case MatroxEncoderAACEncoder.hev1:
        return { name: 'HEV1', value: option }
      case MatroxEncoderAACEncoder.hev2:
        return { name: 'HEV2', value: option }
    }
  },
)

const aacQualityLevelOptions: RichOption[] = Object.values(MatroxEncoderAACQualityLevel)
  .map((option: MatroxEncoderAACQualityLevel) => {
    switch (option) {
      case MatroxEncoderAACQualityLevel.low:
        return { name: 'Low', value: option }
      case MatroxEncoderAACQualityLevel.medium:
        return { name: 'Medium', value: option }
      case MatroxEncoderAACQualityLevel.high:
        return { name: 'High', value: option }
      case MatroxEncoderAACQualityLevel.unknown:
        return undefined
    }
  })
  .filter(Boolean) as RichOption[]

const aacFormatOptions: RichOption[] = Object.values(MatroxEncoderAACFormat)
  .map((option: MatroxEncoderAACFormat) => {
    switch (option) {
      case MatroxEncoderAACFormat.noContainer:
        return { name: 'No container/Raw', value: option }
      case MatroxEncoderAACFormat.adts:
        return { name: 'ADTS', value: option }
      case MatroxEncoderAACFormat.adif:
        return undefined
      case MatroxEncoderAACFormat.unknown:
        return undefined
    }
  })
  .filter(Boolean) as RichOption[]

function formatAudioPair(audioPair: MatroxAudioChannelPair) {
  switch (audioPair) {
    case MatroxAudioChannelPair.channel1And2:
      return '1-2'
    case MatroxAudioChannelPair.channel3And4:
      return '3-4'
    case MatroxAudioChannelPair.channel5And6:
      return '5-6'
    case MatroxAudioChannelPair.channel7And8:
      return '7-8'
    case MatroxAudioChannelPair.channel9And10:
      return '9-10'
    case MatroxAudioChannelPair.channel11And12:
      return '11-12'
    case MatroxAudioChannelPair.channel13And14:
      return '13-14'
    case MatroxAudioChannelPair.channel15And16:
      return '15-16'
  }
}
const MatroxEncoderSettingsComp = ({
  namePrefix,
  setFieldValue,
  physicalPort,
  matroxSettings: {
    processorSettings,
    encoderSettings: { include, audioSettings, videoSettings },
    tsOutputSettings,
  },
}: Props) => {
  useEffect(() => {
    if (!processorSettings.followVideoSourceInput && tsOutputSettings.isAncEnabled) {
      setFieldValue(`${namePrefix}.tsOutputSettings.isAncEnabled`, false, false)
    }
  }, [processorSettings.followVideoSourceInput])

  useEffect(() => {
    if (physicalPort.portType !== PortType.coax) {
      return
    }

    const isVirtualQuadPortSelected = isVirtualAggregatedPort(physicalPort)
    if (isVirtualQuadPortSelected) {
      if (processorSettings.videoLayout !== 'quad') {
        // The virtual aggregated port only supports quad mode
        setFieldValue(`${namePrefix}.processorSettings.videoLayout`, 'quad', false)
      }
      if (processorSettings.videoSourceSettings.length !== 4) {
        setFieldValue(
          `${namePrefix}.processorSettings.videoSourceSettings`,
          [
            makeMatroxEncoderProcessorVideoSourceSettings(0),
            makeMatroxEncoderProcessorVideoSourceSettings(1),
            makeMatroxEncoderProcessorVideoSourceSettings(2),
            makeMatroxEncoderProcessorVideoSourceSettings(3),
          ],
          false,
        )
      }
      if (processorSettings.followVideoSourceInput) {
        // Cannot use video input settings when there are multiple video sources
        setFieldValue(`${namePrefix}.processorSettings.followVideoSourceInput`, false, false)
      }
    } else {
      // Port indexes of the currently selected interfaces
      const availableInputSources: Set<number> = [physicalPort].reduce((set, port) => {
        const indexes = (port.index ?? '')
          .split(',')
          .map((str) => Number(str))
          .filter((i) => !isNaN(i))
        for (const i of indexes) {
          set.add(i)
        }
        return set
      }, new Set<number>())

      // Regular (non virtual-aggregated) interface:
      const physicalPortIndex = availableInputSources.values().next().value ?? 0
      if (processorSettings.videoLayout !== 'single') {
        // Going from 'quad' --> 'single'
        setFieldValue(`${namePrefix}.processorSettings.videoLayout`, 'single', false)
        setFieldValue(`${namePrefix}.processorSettings.followVideoSourceInput`, true, false)
        setFieldValue(
          `${namePrefix}.processorSettings.videoSourceSettings`,
          [makeMatroxEncoderProcessorVideoSourceSettings(physicalPortIndex)],
          false,
        )
      } else {
        // Going from 'single' --> 'single', e.g. SDI 1 --> SDI 2
        if (processorSettings.videoSourceSettings[0].portIndex !== physicalPortIndex) {
          setFieldValue(
            `${namePrefix}.processorSettings.videoSourceSettings`,
            [{ ...processorSettings.videoSourceSettings[0], portIndex: physicalPortIndex }],
            false,
          )
        }
      }

      if (processorSettings.audioSourceSettings.portIndex !== physicalPortIndex) {
        setFieldValue(
          `${namePrefix}.processorSettings.audioSourceSettings`,
          makeMatroxEncoderProcessorAudioSourceSettings(physicalPortIndex),
          false,
        )
      }
    }
  }, [physicalPort, processorSettings, setFieldValue, namePrefix])

  const audioBitrateConstraints = getAllowedBitratesForMatroxAACEncoder(audioSettings.aacEncoder)

  const audioCheckboxes = Object.values(MatroxAudioChannelPair)
    .filter((pair) => !isNaN(Number(pair)))
    .map((pair) => {
      const currentValue = tsOutputSettings.selectedAudioPairs
      const handleClick = () => {
        const newValue = currentValue.includes(pair as any)
          ? currentValue.filter((p) => p !== pair)
          : [...currentValue, pair]
        setFieldValue(`${namePrefix}.tsOutputSettings.selectedAudioPairs`, newValue, false)
      }
      const isSelected = tsOutputSettings.selectedAudioPairs.includes(pair as MatroxAudioChannelPair)
      const canDeselectAdditional = tsOutputSettings.selectedAudioPairs.length > MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS // Min 1
      const canSelectAdditional = tsOutputSettings.selectedAudioPairs.length < MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS // Max 4
      return (
        <Checkbox
          key={pair}
          label={formatAudioPair(pair as any)}
          control={
            <MuiCheckbox
              checked={isSelected}
              disabled={(isSelected && !canDeselectAdditional) || (!isSelected && !canSelectAdditional)}
              onChange={handleClick}
            />
          }
        />
      )
    })

  const isVideoFormDisabled = include === MatroxEncoderIncludeOption.audioOnly
  const isAudioFormDisabled = include === MatroxEncoderIncludeOption.videoOnly

  const hasMultipleVideoSources = processorSettings.videoLayout !== 'single'

  const selectedPixelFormat = videoSettings.forcePixelFormat
    ? videoSettings.forcedPixelFormat
    : processorSettings.followVideoSourceInput
    ? undefined
    : processorSettings.overriddenCanvasSettings.pixelFormat

  const isCBR = videoSettings.rateControl.bitrateControl === MatroxEncoderBitrateControlMode.constant

  return (
    <Paper className="outlined" title="Matrox Settings" collapsible>
      <Grid item xs={12}>
        <Paper title="Processor" collapsible>
          <Paper title="Canvas">
            <Checkbox
              name={`${namePrefix}.processorSettings.inputFailSafe`}
              label={'Input fail safe'}
              tooltip={`Whether or not to continue streaming on signal loss`}
            />

            <Checkbox
              name={`${namePrefix}.processorSettings.followVideoSourceInput`}
              disabled={hasMultipleVideoSources}
              label={'Use video input source settings'}
              tooltip={`Use the same settings as the video input source. Not applicable if there are multiple video input sources. Note: setting it to 'false' will apply progressive encoding.`}
            />

            <TextInput
              label="Frame width"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.frameSize.width`}
              disabled={processorSettings.followVideoSourceInput}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MAX,
                  divisibleBy: MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER} between ${MATROX_ENCODER_FRAMESIZE_MIN}-${MATROX_ENCODER_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              label="Frame height"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.frameSize.height`}
              disabled={processorSettings.followVideoSourceInput}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MAX,
                  divisibleBy: MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER} between ${MATROX_ENCODER_FRAMESIZE_MIN}-${MATROX_ENCODER_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <Select
              label="Frame rate"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.frameRate`}
              disabled={processorSettings.followVideoSourceInput}
              required
              options={frameRateOptions}
            />

            <Select
              label="Pixel format"
              name={`${namePrefix}.processorSettings.overriddenCanvasSettings.pixelFormat`}
              disabled={processorSettings.followVideoSourceInput}
              required
              options={Object.values(MatroxEncoderPixelFormat)}
            />

            <GridItem>
              <ColorPicker
                label={'Background color'}
                initialColor={processorSettings.overriddenCanvasSettings.backgroundColor}
                required
                disabled={processorSettings.followVideoSourceInput}
                onColorSelected={(rgb) => {
                  setFieldValue(`${namePrefix}.processorSettings.overriddenCanvasSettings.backgroundColor`, {
                    ...rgb,
                    alpha: 255,
                  })
                }}
              />
            </GridItem>
          </Paper>

          <Paper title="Input Sources">
            <Paper title="Audio">
              <Select
                label="Input source"
                name={`${namePrefix}.processorSettings.audioSourceSettings.portIndex`}
                disabled={!hasMultipleVideoSources}
                required
                options={inputSourceOptions}
              />
            </Paper>

            <Paper title="Video">
              <Paper title="Layout">
                <GridItem lg={12} xl={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" marginRight={4}>
                      {processorSettings.videoLayout}
                    </Typography>
                    <img
                      src={hasMultipleVideoSources ? grid : fullscreen}
                      alt={hasMultipleVideoSources ? 'Grid' : 'Fullscreen'}
                    />
                  </div>
                </GridItem>
              </Paper>

              {processorSettings.videoSourceSettings.map((_, index) => {
                const name = `${namePrefix}.processorSettings.videoSourceSettings.${index}`
                const source = ['A', 'B', 'C', 'D'][index]
                return (
                  <Paper key={name} title={`Source ${source}`}>
                    <Select
                      label="Input source"
                      name={`${name}.portIndex`}
                      disabled={!hasMultipleVideoSources}
                      required
                      options={inputSourceOptions}
                    />
                    <TextInput
                      label="Capture rate (frames)"
                      name={`${name}.frameCaptureRate`}
                      disabled={processorSettings.followVideoSourceInput}
                      tooltip={
                        'Defines if and how frames are skipped. A value of 1 indicates all frames are processed while a value of 3 indicates 1 out of every 3 frames are processed and 2 frames are skipped.'
                      }
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROX_ENCODER_VIDEO_CAPTURE_RATE_MIN,
                          lessThanOrEqualTo: MATROX_ENCODER_VIDEO_CAPTURE_RATE_MAX,
                          message: `Must be between ${MATROX_ENCODER_VIDEO_CAPTURE_RATE_MIN}-${MATROX_ENCODER_VIDEO_CAPTURE_RATE_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <Select
                      label="Scaling"
                      name={`${name}.scaling`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      options={scalingOptions}
                    />
                    <Select
                      label="Pivot"
                      name={`${name}.pivot`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      options={pivotOptions}
                    />
                    <Select
                      label="Flip"
                      name={`${name}.flip`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      options={flipOptions}
                    />
                    <TextInput
                      label="Opacity (percentage)"
                      name={`${name}.opacityPercentage`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MIN,
                          lessThanOrEqualTo: MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MAX,
                          message: `Must be between ${MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MIN}-${MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Brightness"
                      name={`${name}.brightness`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROX_ENCODER_VIDEO_BRIGHTNESS_MIN,
                          lessThanOrEqualTo: MATROX_ENCODER_VIDEO_BRIGHTNESS_MAX,
                          message: `Must be between ${MATROX_ENCODER_VIDEO_BRIGHTNESS_MIN}-${MATROX_ENCODER_VIDEO_BRIGHTNESS_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Contrast"
                      name={`${name}.contrast`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROX_ENCODER_VIDEO_CONTRAST_MIN,
                          lessThanOrEqualTo: MATROX_ENCODER_VIDEO_CONTRAST_MAX,
                          message: `Must be between ${MATROX_ENCODER_VIDEO_CONTRAST_MIN}-${MATROX_ENCODER_VIDEO_CONTRAST_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Hue"
                      name={`${name}.hue`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROX_ENCODER_VIDEO_HUE_MIN,
                          lessThanOrEqualTo: MATROX_ENCODER_VIDEO_HUE_MAX,
                          message: `Must be between ${MATROX_ENCODER_VIDEO_HUE_MIN}-${MATROX_ENCODER_VIDEO_HUE_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                    <TextInput
                      label="Saturation"
                      name={`${name}.saturation`}
                      disabled={processorSettings.followVideoSourceInput}
                      required
                      type="number"
                      noNegative
                      validators={{
                        numericality: {
                          greaterThanOrEqualTo: MATROX_ENCODER_VIDEO_SATURATION_MIN,
                          lessThanOrEqualTo: MATROX_ENCODER_VIDEO_SATURATION_MAX,
                          message: `Must be between ${MATROX_ENCODER_VIDEO_SATURATION_MIN}-${MATROX_ENCODER_VIDEO_SATURATION_MAX}`,
                          noStrings: false,
                        },
                      }}
                    />
                  </Paper>
                )
              })}
            </Paper>
          </Paper>
        </Paper>

        <Paper title="Encoder" collapsible>
          <Select
            label="Include"
            name={`${namePrefix}.encoderSettings.include`}
            required
            options={encoderIncludeOptions}
          />

          <Paper title="Audio">
            <Select
              label="AAC encoder"
              name={`${namePrefix}.encoderSettings.audioSettings.aacEncoder`}
              disabled={isAudioFormDisabled}
              required
              options={aacEncoderOptions}
            />

            <TextInput
              label="Bit rate (kb/s)"
              name={`${namePrefix}.encoderSettings.audioSettings.bitrateKbps`}
              disabled={isAudioFormDisabled}
              required
              type="number"
              noNegative
              validators={
                audioBitrateConstraints && {
                  numericality: {
                    greaterThanOrEqualTo: audioBitrateConstraints.minAllowedBitrateKbps,
                    lessThanOrEqualTo: audioBitrateConstraints.maxAllowedBitrateKbps,
                    message: `Must be between ${audioBitrateConstraints.minAllowedBitrateKbps}-${audioBitrateConstraints.maxAllowedBitrateKbps} for the selected AAC encoder (${audioSettings.aacEncoder})`,
                    noStrings: false,
                  },
                }
              }
            />

            <Select
              label="AAC quality"
              name={`${namePrefix}.encoderSettings.audioSettings.aacQuality`}
              disabled={isAudioFormDisabled}
              required
              options={aacQualityLevelOptions}
            />

            {/*  Currently only allow format ADTS due to Matrox API-errors when using e.g. Raw format */}
            <Select
              label="AAC format"
              name={`${namePrefix}.encoderSettings.audioSettings.aacFormat`}
              disabled={true}
              required
              options={aacFormatOptions}
            />

            <Checkbox
              label={'Use temporal noise shaping'}
              name={`${namePrefix}.encoderSettings.audioSettings.useTemporalNoiseShaping`}
              disabled={isAudioFormDisabled}
            />
          </Paper>

          <Paper title="Video">
            <Checkbox
              name={`${namePrefix}.encoderSettings.videoSettings.forceEncodingSize`}
              label={'Override video settings'}
              disabled={isVideoFormDisabled}
            />

            <TextInput
              label="Frame width"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.frameSize.width`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MAX,
                  divisibleBy: MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER} between ${MATROX_ENCODER_FRAMESIZE_MIN}-${MATROX_ENCODER_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              label="Frame height"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.frameSize.height`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MIN,
                  lessThanOrEqualTo: MATROX_ENCODER_FRAMESIZE_MAX,
                  divisibleBy: MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER,
                  message: `Must be multiplier of ${MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER} between ${MATROX_ENCODER_FRAMESIZE_MIN}-${MATROX_ENCODER_FRAMESIZE_MAX}`,
                  noStrings: false,
                },
              }}
            />
            <Select
              label="Scaling"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.scaling`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              options={scalingOptions}
            />
            <Select
              label="Pivot"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.pivot`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              options={pivotOptions}
            />
            <Select
              label="Flip"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedEncodingSettings.flip`}
              disabled={isVideoFormDisabled || !videoSettings.forceEncodingSize}
              required
              options={flipOptions}
            />

            <Checkbox
              name={`${namePrefix}.encoderSettings.videoSettings.forcePixelFormat`}
              label={'Enforce a pixel format'}
              disabled={isVideoFormDisabled}
            />

            <Select
              label="Forced pixel format"
              name={`${namePrefix}.encoderSettings.videoSettings.forcedPixelFormat`}
              disabled={isVideoFormDisabled || !videoSettings.forcePixelFormat}
              required
              options={Object.values(MatroxEncoderPixelFormat)}
            />

            <Select
              label="Encoding profile"
              name={`${namePrefix}.encoderSettings.videoSettings.encodingProfile`}
              disabled={isVideoFormDisabled}
              required
              tooltip={`Must be compatible with the selected pixel format`}
              options={encodingProfileOptions}
              validators={{
                oneOf: {
                  validValues: new Set(
                    selectedPixelFormat
                      ? getCompatibleMatroxVideoProfilesForPixelFormat(selectedPixelFormat)
                      : [MatroxEncoderVideoProfile.High422],
                  ),
                },
              }}
            />

            <Paper title="Group Of Pictures (GOP)">
              <TextInput
                label="GOP length"
                name={`${namePrefix}.encoderSettings.videoSettings.gop.iFrameInterval`}
                disabled={isVideoFormDisabled}
                required
                tooltip={`Insert a key frame every "${videoSettings.gop.iFrameInterval}" pictures`}
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: videoSettings.gop.pFrameInterval,
                    lessThanOrEqualTo: MATROX_ENCODER_IFRAME_INTERVAL_MAX,
                    divisibleBy: MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER,
                    message: `Must be a multiplier of ${MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER} between ${MATROX_ENCODER_IFRAME_INTERVAL_MIN}-${MATROX_ENCODER_IFRAME_INTERVAL_MAX} and greater than or equal to the selected P-frame interval (${videoSettings.gop.pFrameInterval})`,
                    noStrings: false,
                  },
                }}
              />
              <TextInput
                label="P-frame interval"
                name={`${namePrefix}.encoderSettings.videoSettings.gop.pFrameInterval`}
                disabled={isVideoFormDisabled}
                required
                tooltip={`Insert a P-frame every "${videoSettings.gop.pFrameInterval}":th frame`}
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: MATROX_ENCODER_PFRAME_INTERVAL_MIN,
                    lessThanOrEqualTo: MATROX_ENCODER_PFRAME_INTERVAL_MAX,
                    message: `Must be between ${MATROX_ENCODER_PFRAME_INTERVAL_MIN}-${MATROX_ENCODER_PFRAME_INTERVAL_MAX} and less than or equal to the selected GOP length (${videoSettings.gop.iFrameInterval})`,
                    noStrings: false,
                  },
                }}
              />
            </Paper>
            <Paper title="Rate Control">
              <Select
                label="Encoding Mode"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.mode`}
                disabled={isVideoFormDisabled}
                required
                options={encodingModeOptions}
              />

              <TextInput
                label="Quantization min"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.quantizationMin`}
                disabled={isVideoFormDisabled}
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: MATROX_ENCODER_QP_MIN,
                    lessThanOrEqualTo: videoSettings.rateControl.quantizationMax,
                    message: `Must be between ${MATROX_ENCODER_QP_MIN}-${MATROX_ENCODER_QP_MAX} and less than or equal to the selected quantization max (${videoSettings.rateControl.quantizationMax})`,
                    noStrings: false,
                  },
                }}
              />
              <TextInput
                label="Quantization max"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.quantizationMax`}
                disabled={isVideoFormDisabled}
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: videoSettings.rateControl.quantizationMin,
                    lessThanOrEqualTo: MATROX_ENCODER_QP_MAX,
                    message: `Must be between ${MATROX_ENCODER_QP_MIN}-${MATROX_ENCODER_QP_MAX} and greater than or equal to the selected quantization min (${videoSettings.rateControl.quantizationMin})`,
                    noStrings: false,
                  },
                }}
              />
              <Select
                label="Bit rate control"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.bitrateControl`}
                disabled={isVideoFormDisabled}
                required
                options={bitrateControlOptions}
              />

              <TextInput
                label="Target bitrate (Mbps)"
                name={`${namePrefix}.encoderSettings.videoSettings.targetBitrateMbps`}
                disabled={isVideoFormDisabled}
                required
                type="number"
                noNegative
                validators={{
                  numericality: {
                    greaterThanOrEqualTo: MATROX_ENCODER_TARGET_BITRATE_MIN,
                    lessThanOrEqualTo: isCBR ? MATROX_ENCODER_MAXBITRATE_MAX : videoSettings.rateControl.maxBitrateMbps,
                    message: `Must be between ${MATROX_ENCODER_TARGET_BITRATE_MIN}-${MATROX_ENCODER_MAXBITRATE_MAX}${
                      isCBR
                        ? ''
                        : ` and less than or equal to the selected max bitrate (${videoSettings.rateControl.maxBitrateMbps})`
                    }`,
                    noStrings: false,
                  },
                }}
              />

              <TextInput
                label="Max bit rate (Mbps)"
                name={`${namePrefix}.encoderSettings.videoSettings.rateControl.maxBitrateMbps`}
                disabled={isVideoFormDisabled || isCBR}
                required
                type="number"
                noNegative
                validators={
                  isVideoFormDisabled || isCBR
                    ? undefined
                    : {
                        numericality: {
                          greaterThanOrEqualTo: Math.max(
                            MATROX_ENCODER_MAXBITRATE_MIN,
                            videoSettings.targetBitrateMbps,
                          ),
                          lessThanOrEqualTo: MATROX_ENCODER_MAXBITRATE_MAX,
                          message: `Must be between ${MATROX_ENCODER_MAXBITRATE_MIN}-${MATROX_ENCODER_MAXBITRATE_MAX} and greater than or equal to the selected target bitrate (${videoSettings.targetBitrateMbps})`,
                          noStrings: false,
                        },
                      }
                }
              />
            </Paper>

            <Checkbox
              label={'Force CAVLC entropy encoding'}
              name={`${namePrefix}.encoderSettings.videoSettings.forceCAVLCEntropyEncoding`}
              disabled={isVideoFormDisabled}
            />
          </Paper>
        </Paper>
        <Paper title="MPEG2TS Output" collapsible>
          <Paper title="Audio Pair Selection">{audioCheckboxes}</Paper>
          <Checkbox
            label={'Enable ancillary data streaming'}
            name={`${namePrefix}.tsOutputSettings.isAncEnabled`}
            disabled={!processorSettings.followVideoSourceInput}
            tooltip={`Only applicable if "use video input source settings" is enabled.`}
          />
        </Paper>
      </Grid>
    </Paper>
  )
}

export default MatroxEncoderSettingsComp
