import { ConnectitTokens } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface ITokenApi {
  getConnectitTokens(params: object): Promise<ConnectitTokens | null>
}

export class TokenApi implements ITokenApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getConnectitTokens(): Promise<ConnectitTokens | null> {
    return this.edgeClient.getConnectitTokens()
  }
}
