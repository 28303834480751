import { createSlice } from '@reduxjs/toolkit'

import { clearPort, clearPorts, getPort, getPorts, updatePort } from '../actions/portActions'
import { logoutUser } from '../actions/userActions'
import { EnrichedPhysicalPort } from '../../api/nm-types'
import { createLoadingReducer } from './shared'

interface State {
  loading: boolean
  port?: EnrichedPhysicalPort
  ports: Array<EnrichedPhysicalPort>
  saving?: boolean
  total: number
}
export const initialStatePorts: State = { ports: [], loading: false, total: 0 }

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getPorts)

const portsSlice = createSlice({
  name: 'ports',
  initialState: initialStatePorts,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPort.fulfilled, (state, { payload: port }): State => ({ ...state, port }))
      .addCase(getPorts.fulfilled, (state, { payload: { items: ports, total } }): State => ({ ...state, ports, total }))
      .addCase(clearPort, (state): State => ({ ...state, port: undefined }))
      .addCase(clearPorts, (state): State => ({ ...state, ports: [] }))
      .addCase(updatePort.pending, (state): State => ({ ...state, saving: true }))
      .addCase(updatePort.fulfilled, (state): State => ({ ...state, saving: undefined }))
      .addCase(updatePort.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(logoutUser.fulfilled, (): State => initialStatePorts)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default portsSlice.reducer
