import type { UrlWithParsedQuery } from 'node:url'

import { SortOrder } from './api/v1/types'
import { jsonParse } from './serialization'

export interface Query<TFilter, TSortOrder = SortOrder> {
    filter?: TFilter
    skip?: number
    limit?: number
    order?: TSortOrder
}

export function toQueryParams(q: Query<any, any>): { q: string } {
    return { q: JSON.stringify(q) }
}

export function fromQueryParams<TFilterType, TSortOrderType = SortOrder>(q: UrlWithParsedQuery['query']) {
    if (!q.q) {
        return {}
    }
    let obj
    try {
        obj = jsonParse(q.q as string)
    } catch (e) {
        // Try to decodeURIComponent first to support older API clients
        obj = jsonParse(decodeURIComponent(q.q as string))
    }
    return obj as Query<TFilterType, TSortOrderType>
}
