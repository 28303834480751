import { Route } from 'react-router-dom'

import { ListView as OutputLists } from '../outputLists/List'
import { Edit as OutputListsEdit } from '../outputLists/Edit'
import { List } from './List'
import { Edit } from './Edit'

import { useRoutes } from '../../store'

export const Outputs = () => {
  const routes = useRoutes()
  return (
    <>
      <Route path={routes.outputsUpdate.route} component={Edit} />
      <Route path={routes.outputsNew.route} component={Edit} />
      <Route exact path={routes.outputs.route} component={List} />
      <Route path={routes.outputListsUpdate.route} component={OutputListsEdit} />
      <Route path={routes.outputListsNew.route} component={OutputListsEdit} />
      <Route exact path={routes.outputLists.route} component={OutputLists} />
    </>
  )
}
