import Chip from '@mui/material/Chip'

import { FilterBy, filtersToUrlParams, removeFilterByKeyAndValue, urlParamsToFilters } from './FilterTemplate'
import { usePageParams } from '../../../../utils'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { GlobalState } from '../../../../store'
import { UrlParamCache } from '../../../../redux/reducers/urlParamReducer'
import { useEffect } from 'react'
import { saveUrlParams } from '../../../../redux/actions/urlParamActions'
import { areObjectsEqual } from 'common/util'

interface Props {
  selectedFilters: FilterBy[]
  onFiltersUpdated: (filters: FilterBy[]) => void
}

export const ReduxSelectedFilters = <T extends PaginatedRequestParams>({
  urlParamCacheKey,
  mapUrlParamToFilterFn,
  mapFilterToUrlParamFn,
}: {
  urlParamCacheKey: keyof UrlParamCache
  mapUrlParamToFilterFn: (key: keyof T, value: string) => FilterBy | undefined
  mapFilterToUrlParamFn: (filter: FilterBy) => Partial<T>
}) => {
  const dispatch = useDispatch()
  const [urlParams, setUrlParams] = usePageParams<Omit<T, 'rowsPerPage' | 'asc' | 'desc'>>()
  const { cachedUrlParams: urlParamCache } = useSelector(
    ({ urlParamReducer }: GlobalState) => urlParamReducer,
    shallowEqual,
  )
  const cachedUrlParams = urlParamCache[urlParamCacheKey]
  const appliedFilters = cachedUrlParams ? urlParamsToFilters(cachedUrlParams, mapUrlParamToFilterFn) : []
  const populateUrlWithSelectedFilters = (filters: FilterBy[]) => {
    const urlParams = filtersToUrlParams(filters, appliedFilters, mapFilterToUrlParamFn)
    setUrlParams({ ...urlParams, pageNumber: '0' } as T) // Reset page to 0 when applying filters
  }

  useEffect(() => {
    // Observe url params and cache them in redux on change
    if (!areObjectsEqual(urlParams, cachedUrlParams)) {
      const action = saveUrlParams(urlParamCacheKey, urlParams as T)
      dispatch(action)
    }
  }, [urlParams, cachedUrlParams, urlParamCacheKey, dispatch])

  return <SelectedFilters selectedFilters={appliedFilters} onFiltersUpdated={populateUrlWithSelectedFilters} />
}

export const SelectedFilters = ({ selectedFilters, onFiltersUpdated }: Props) => {
  const onDelete = (keyToDelete: string, valueToDelete: string) => {
    const updatedFilters = removeFilterByKeyAndValue(keyToDelete, valueToDelete, selectedFilters)
    onFiltersUpdated(updatedFilters)
  }
  const filterViews: JSX.Element[] = selectedFilters
    .map((filter): JSX.Element[] => {
      const items: { key: string; operator: FilterBy['operator']; value: string; displayName: string }[] = []
      if ('value' in filter) {
        items.push({ ...filter, displayName: filter.displayName ?? filter.value })
      } else {
        items.push(
          ...filter.values.map(({ value, displayName }) => ({
            key: filter.key,
            operator: filter.operator,
            value,
            displayName,
          })),
        )
      }

      return items.map(({ operator, key, value, displayName }) => {
        let label = ''
        switch (operator) {
          case 'is':
            label = `${key} is ${displayName}`
            break
          case 'matches':
            label = `${key} matches ${displayName}`
            break
          case 'isOneOf':
            label = `${key} is ${displayName}`
            break
        }
        return (
          <Chip
            key={`${key}-${operator}-${value}`}
            label={label}
            onDelete={() => onDelete(key, value)}
            size="medium"
            style={{ margin: 8 }}
          />
        )
      })
    })
    .flat()

  return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{...filterViews}</div>
}
