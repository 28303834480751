import type { EdgeClient } from 'common/generated/edgeClient'

export interface IStatusApi {
  listStatus(): ReturnType<EdgeClient['listStatuses']>
}

export class StatusApi implements IStatusApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async listStatus(): ReturnType<EdgeClient['listStatuses']> {
    return this.edgeClient.listStatuses()
  }
}
