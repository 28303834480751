import React from 'react'

import { GridItem, Paper } from '../../common/Form'
import DataSet from '../../common/DataSet'
import { RegionDetails } from '../../../api/region/api'

export interface MetaProps {
  regionDetails: RegionDetails
}

const Meta: React.FunctionComponent<MetaProps> = ({ regionDetails }) => {
  const {
    region: { name, default_region },
    videoScale,
    thumbScale,
  } = regionDetails
  const meta = {
    Name: name,
    Type: default_region ? 'default' : 'external',
    'Video Replicas': videoScale.replicas,
    'Thumb Replicas': thumbScale.replicas,
  }

  return (
    <Paper title="Meta data" collapsible actionsPane={[]}>
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
    </Paper>
  )
}

export default Meta
