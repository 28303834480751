import { Route } from 'react-router-dom'

import { List } from './List'
import { Edit } from './Edit'

import { useRoutes } from '../../store'

export const Nodes = () => {
  const routes = useRoutes()
  return (
    <>
      <Route exact path={routes.kubernetesNodes.route} component={List} />
      <Route exact path={routes.kubernetesNodesUpdate.route} component={Edit} />
    </>
  )
}
