import { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field, FieldAttributes, FormikProps } from 'formik'
import { FormControl, FormHelperTextProps } from '@mui/material'
import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import { debounce, get, omit, upperFirst } from 'lodash'

import { ListResult } from 'common/api/v1/types'
import GridItem, { GridItemProps } from '../GridItem'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import { validate } from '../../../../utils'
import { FilterOptionsState } from '@mui/base/useAutocomplete/useAutocomplete'

type MuiAC<T> = MuiAutocompleteProps<T, any, any, any>
type GenericAutocompleteProps<TEntity> = {
  name: FieldAttributes<MuiAC<TEntity>>['name']
  groupBy?: FieldAttributes<MuiAC<TEntity>>['groupBy']
  formik: FormikProps<any>
  api: (params: PaginatedRequestParams<any>) => Promise<ListResult<TEntity>>
  getOptionLabel: (option: TEntity) => string
  getOptionValue: (option: TEntity) => any
  optionComparator: MuiAC<TEntity>['isOptionEqualToValue']
  getOptionDisabled?: MuiAC<TEntity>['getOptionDisabled']
  label?: TextFieldProps['label']
  required?: TextFieldProps['required']
  disabled?: boolean
  xs?: GridItemProps['xs']
  onClear?: () => void
  renderOption?: MuiAC<TEntity>['renderOption']
  tooltip?: GridItemProps['tooltip']

  // If true, the selected option becomes the value of the input when the Autocomplete loses focus
  // unless the user chooses a different option or changes the character string in the input.
  autoSelect?: boolean

  // Whether the MUI-autocomplete displays the "clear" icon. If true, the input can't be cleared.
  disableClearable?: boolean

  // Message displayed beneath the input text field (not visible when an error message is displayed)
  comment?: string
  commentProps?: Partial<FormHelperTextProps>

  // Only allow the options returned from the API or allow the user to enter their own values
  allowCustomOptions?: boolean
}

type SingleAutocompleteProps<TEntity> = GenericAutocompleteProps<TEntity> & {
  defaultOption?: TEntity | undefined

  // Input type
  type?: TextFieldProps['type']

  // Provide to perform local filtering of options
  localFilterFn?: (options: TEntity[], state: FilterOptionsState<TEntity>) => TEntity[]
}

type MultipleAutocompleteProps<TEntity> = GenericAutocompleteProps<TEntity> & {
  multiple: true
  defaultOption?: TEntity[]

  // Remove the already selected values from the available options
  filterSelectedOptions?: boolean
  // Useful when 'allowCustomOptions' is true
  validators?: Parameters<typeof validate>[0]
}

type AutocompleteProps<TEntity> = SingleAutocompleteProps<TEntity> | MultipleAutocompleteProps<TEntity>

const FormAutocomplete = <TEntity,>(props: AutocompleteProps<TEntity>) => {
  const {
    required = false,
    xs,
    label,
    name,
    groupBy,
    autoSelect,
    disableClearable,
    defaultOption,
    formik,
    api,
    onClear,
    getOptionValue,
    optionComparator,
    getOptionDisabled,
    tooltip,
    comment,
    commentProps,
    allowCustomOptions,
    ...restProps
  } = props

  const type = ('type' in restProps && restProps.type) || undefined
  const localFilterFn = ('localFilterFn' in restProps && restProps.localFilterFn) || undefined
  const multiple = 'multiple' in restProps && restProps.multiple
  const filterSelectedOptions = 'filterSelectedOptions' in restProps && restProps.filterSelectedOptions
  const extraValidators = 'validators' in restProps ? restProps.validators : undefined
  const componentProps = omit(restProps, ['validators', 'filterSelectedOptions', 'type', 'localFilterFn'])

  const validators = extraValidators ?? {}
  validators.presence = required

  const initialized = useRef(false)
  const isMounted = useRef(false)
  const [value, setValue] = useState<TEntity | TEntity[] | null>(defaultOption || (multiple ? [] : null))
  const [inputValue, setInputValue] = useState(
    defaultOption && !multiple ? props.getOptionLabel(defaultOption as TEntity) : '',
  )
  const [options, setOptions] = useState<TEntity[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [apiSearchFilter, setApiSearchFilter] = useState<string | undefined>(undefined)

  const doClear = () => {
    setApiSearchFilter('')
    onClear?.()
  }

  const hasErrors = () => {
    const path = name.split('.')
    const formikErrors = get(formik.errors, path)
    const formikTouched = get(formik.touched, path)
    return formikErrors && formikTouched ? true : false
  }
  const hasError = hasErrors()

  const fetch = useCallback(
    async (filter: string | undefined) => {
      const { items } = await api({ pageNumber: '0', rowsPerPage: '500', filter })
      if (isMounted.current) {
        setOptions(items)
        setIsLoading(false)
      }
      initialized.current = true
    },
    [api],
  )
  const debouncedFetch = useCallback(debounce(fetch, 500), [fetch])

  useEffect(() => {
    isMounted.current = true
    setOptions([])
    setIsLoading(true)

    if (initialized.current) {
      debouncedFetch(apiSearchFilter)
    } else {
      const value = defaultOption ? getOptionValue(defaultOption as TEntity) : null
      if (value !== null && formik.values[name] !== value) {
        formik.setFieldValue(name, value)
      }
      fetch(apiSearchFilter)
    }

    return () => {
      isMounted.current = false
    }
  }, [apiSearchFilter, fetch, debouncedFetch])

  const selectOption = (option: TEntity | TEntity[] | null) => {
    const newValue = multiple ? (option as TEntity[]) : option ? getOptionValue(option as TEntity) : null
    const shouldValidate = true
    formik.setFieldTouched(name, true)
    formik.setFieldValue(name, newValue, shouldValidate)
    setValue(option)
  }

  return (
    <GridItem xs={xs} tooltip={tooltip}>
      <FormControl
        margin="normal"
        fullWidth
        id={`autocomplete-${name}`}
        data-is-loading={isLoading}
        data-num-options={options.length}
        data-selected-value={value}
      >
        <Field
          component={Autocomplete}
          name={name}
          groupBy={groupBy}
          autoSelect={autoSelect}
          filterOptions={localFilterFn}
          filterSelectedOptions={filterSelectedOptions}
          disableClearable={disableClearable}
          value={value}
          defaultValue={defaultOption}
          inputValue={inputValue}
          options={options}
          freeSolo={allowCustomOptions}
          validate={validate(validators)}
          loading={isLoading}
          isOptionEqualToValue={optionComparator}
          getOptionDisabled={getOptionDisabled}
          onChange={(_: any, newOption: TEntity | TEntity[] | null) => {
            selectOption(newOption)
          }}
          onInputChange={(_: any, newInputValue: string, reason: string) => {
            if (reason === 'clear') {
              doClear()
            }
            formik.setFieldTouched(name)
            setInputValue(newInputValue)
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...params}
              name={name}
              type={type}
              label={label || upperFirst(name)}
              variant="outlined"
              required={required}
              error={hasError}
              onChange={(e) => {
                setApiSearchFilter(e.target.value)
              }}
              // Prevent scroll
              onWheel={type === 'number' ? (e) => e.target instanceof HTMLElement && e.target.blur() : undefined}
              onBlur={() => {
                if (!autoSelect && allowCustomOptions && !multiple && inputValue) {
                  // "autoSelect=true" automatically selects the last hovered option when dismissing the autocomplete (by e.g. clicking elsewhere),
                  // overwriting any previously selected option, which is quite unintuitive.
                  // "autoSelect=false" works more as expected. However... When entering a custom value and dismissing the autocomplete,
                  // the entered value will be visible in the text field but not actually selected.
                  // Note: This works only if "allowCustomOptions=true" and "multiple=false" since it then follows that type TEntity is a string.
                  selectOption(inputValue as TEntity)
                } else if (inputValue == '' || !value) {
                  doClear()
                }
              }}
              inputProps={{ ...params.inputProps, autoComplete: 'off' }}
            />
          )}
          {...componentProps}
        />
        <ErrorMessage
          name={name}
          render={(msg) => (
            <FormHelperText error variant="filled">
              {msg}
            </FormHelperText>
          )}
        />
        {!hasError && comment && (
          <FormHelperText sx={commentProps ?? {}} variant="filled">
            {comment}
          </FormHelperText>
        )}
      </FormControl>
    </GridItem>
  )
}

export default FormAutocomplete
