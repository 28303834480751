import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { User, NewUser, ListResult, Role } from 'common/api/v1/types'

import { EnrichedUser, ExistingUserForUpdate, UsersRequestParams } from '../../api/nm-types'
import { initAppliance } from './applianceActions'
import { AppDispatch, ThunkApi } from '../../store'
import { closeAllSnackbars, enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import { clearState, saveState } from '../../localstorage'
import { ApplicationException, ErrorInfo } from '../../components/common/ApplicationException'
import { IUserApi } from '../../api/user/api'
import { RouteComponentProps } from 'react-router-dom'
import { Routes } from '../../utils/routes'

export const loginUser = createAsyncThunk<EnrichedUser, { username: string; password: string; otp?: string }, ThunkApi>(
  'user/login',
  async ({ username, password, otp }, { dispatch, extra: { api } }) => {
    try {
      const user = await api.userApi.loginUser(username, password, otp)
      const queryParams = new URLSearchParams(window.location.search)
      const redirect = queryParams.get('rd')
      if (redirect) window.open(redirect)
      saveState({ userReducer: { user } })
      setTimeout(() => {
        dispatch(initAppliance())
      }, 0) // Run after this thunk has finished to let reducers run and set user
      dispatch(setAuthorizationError(undefined))
      return user
    } catch (err: any) {
      const { errorInfo } = err as { errorInfo: ErrorInfo }
      errorInfo?.errorCode === '401'
        ? dispatch(setAuthorizationError(errorInfo))
        : dispatch(enqueueErrorSnackbar({ error: new ApplicationException(errorInfo) }))
      throw errorInfo
    }
  },
)

export const setAuthorizationError = createAction<ErrorInfo | undefined>('user/setAuthorizationError')

export const createUser = async (
  user: NewUser,
  {
    dispatch,
    userApi,
    history,
    routes,
  }: { dispatch: AppDispatch; userApi: IUserApi; history: RouteComponentProps['history']; routes: Routes },
) => {
  try {
    await userApi.createUser(user)
    history.push(routes.users())
    dispatch(enqueueSuccessSnackbar(`Added new User: ${user.username}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'create user' }))
    throw err
  }
}

export const updateUser = async (
  user: ExistingUserForUpdate,
  {
    dispatch,
    userApi,
    history,
    routes,
  }: { dispatch: AppDispatch; userApi: IUserApi; history: RouteComponentProps['history']; routes: Routes },
) => {
  try {
    await userApi.updateUser(user)
    history.push(routes.users())
    dispatch(enqueueSuccessSnackbar(`Edited User: ${user.username}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update user' }))
    throw err
  }
}

export const removeUser = async (
  user: Pick<User, 'id' | 'username'>,
  {
    dispatch,
    userApi,
    history,
    routes,
  }: { dispatch: AppDispatch; userApi: IUserApi; history: RouteComponentProps['history']; routes: Routes },
) => {
  try {
    await userApi.removeUser(user.id)
    history.push(routes.users())
    dispatch(enqueueSuccessSnackbar(`Removed User: ${user.username}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete user' }))
    throw { error: err, userID: user.id }
  }
}

export const logoutAndNavigateToMainPage = createAsyncThunk<void, void, ThunkApi>(
  'user/logoutAndNavigateToMainPage',
  async (_, { dispatch }) => {
    await dispatch(logoutUser()) // logoutUser needs to run in order for reducers to run before
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(closeAllSnackbars())
    await dispatch(navigateToMainOnUserLogout())
  },
)

export const logoutUser = createAsyncThunk<void, void, ThunkApi>('user/logoutUser', async (_, { extra: { api } }) => {
  await api.userApi.logoutUser()
  clearState()
})

export const impersonateUser = createAsyncThunk<EnrichedUser, User['id'], ThunkApi>(
  'user/impersonateUser',
  async (userId, { dispatch, extra: { api, history, routes } }) => {
    try {
      const user = await api.userApi.impersonateUser(userId)
      dispatch(enqueueSuccessSnackbar(`Logged in as ${user.username}`))
      saveState({ userReducer: { user } })
      history().push(user.role === Role.basic ? routes.stream() : routes.overview())
      return user
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'impersonate user' }))
      throw err
    }
  },
)

export const stopImpersonation = createAsyncThunk<EnrichedUser, void, ThunkApi>(
  'user/stopImpersonation',
  async (_, { dispatch, extra: { api, history, routes } }) => {
    try {
      const user = await api.userApi.stopImpersonation()
      dispatch(enqueueSuccessSnackbar(`Switched back to ${user.username}`))
      saveState({ userReducer: { user } })
      history().push(user.role === Role.basic ? routes.stream() : routes.overview())
      return user
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'switch back to impersonator' }))
      throw err
    }
  },
)

const navigateToMainOnUserLogout = createAsyncThunk<void, void, ThunkApi>(
  'user/navigateToMainOnUserLogout',
  async (_, { extra: { history } }) => {
    history().push('/')
  },
)

export const getUsers = async (
  params: UsersRequestParams,
  { dispatch, userApi }: { dispatch: AppDispatch; userApi: IUserApi },
): Promise<ListResult<EnrichedUser>> => {
  try {
    return await userApi.getUsers(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch users' }))
    throw err
  }
}

export const fetchCurrentUser = createAsyncThunk<EnrichedUser, void, ThunkApi>(
  'user/current-user/fetch',
  async (_, { dispatch, extra: { api } }) => {
    try {
      const user = await api.userApi.getCurrentEnrichedUser()
      saveState({ userReducer: { user } })
      return user
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch current-user' }))
      throw err
    }
  },
)
