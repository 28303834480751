import { PropsWithChildren, useEffect, useRef, useState } from 'react'

/**
 * gracePeriodSeconds: Number of milliseconds to wait before rendering children
 */
interface Props {
  gracePeriodMs: number
}
export default function DelayedAppearance(props: PropsWithChildren<Props>) {
  const [shouldRenderChildren, setShouldRenderChildren] = useState(false)
  const timerRef = useRef<any>()

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setShouldRenderChildren(true)
    }, props.gracePeriodMs)
    return function onUnmount() {
      clearTimeout(timerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{shouldRenderChildren ? props.children : null}</>
}
