import { FunctionComponent, useEffect } from 'react'
import { useMap, Layer, Source } from 'react-map-gl'
import * as GeoJSON from 'geojson'
import { useTheme, Theme } from '@mui/material/styles'

import { Appliance, ApplianceType, Coordinates, GeoAppliance } from 'common/api/v1/types'
import { toMapboxCoords, useUser } from '../../../utils'
import type { PopupProps } from './utils'
import { AppliancesPopup } from './Popup'

export interface CircleData {
  id: number
  coordinates: Coordinates
  appliances: Array<Appliance | GeoAppliance>
}

interface MarkerProps {
  layerId: string
  circles: CircleData[]
  setPopup: (props: PopupProps) => void
  featureEvents: { appliances: EventTarget }
}

export const CircleMarkers: FunctionComponent<MarkerProps> = ({ layerId, circles, featureEvents, setPopup }) => {
  const theme: Theme = useTheme()
  const user = useUser()
  const { current: map } = useMap()
  const features: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: circles.map((circle) => ({
      type: 'Feature',
      id: circle.id,
      properties: { id: circle.id, isCoreNode: circle.appliances[0].type === ApplianceType.core },
      geometry: { type: 'Point', coordinates: toMapboxCoords(circle.coordinates) },
    })),
  }

  useEffect(() => {
    const handleClick = (event: Event) => {
      const id = (event as CustomEvent).detail as number
      const circle = circles.find((circle) => circle.id === id)
      if (circle) {
        setPopup({
          coordinates: circle.coordinates,
          content: <AppliancesPopup appliances={circle.appliances} user={user} />,
        })
      }
    }
    const handleMouseEnter = (event: Event) => {
      const id = (event as CustomEvent).detail as number
      map?.setFeatureState({ id, source: 'appliances' }, { hover: true })
    }
    const handleMouseLeave = (event: Event) => {
      const id = (event as CustomEvent).detail as number
      map?.setFeatureState({ id, source: 'appliances' }, { hover: false })
    }
    featureEvents.appliances.addEventListener('onClick', handleClick)
    featureEvents.appliances.addEventListener('onMouseEnter', handleMouseEnter)
    featureEvents.appliances.addEventListener('onMouseLeave', handleMouseLeave)

    return () => {
      featureEvents.appliances.removeEventListener('onClick', handleClick)
      featureEvents.appliances.removeEventListener('onMouseEnter', handleMouseEnter)
      featureEvents.appliances.removeEventListener('onMouseLeave', handleMouseLeave)
    }
  })

  return (
    <Source id="appliances" type="geojson" data={features}>
      <Layer
        id={layerId}
        type="circle"
        paint={{
          'circle-color': '#ffffff', // bg
          'circle-stroke-color': [
            'case',
            ['boolean', ['get', 'isCoreNode'], false],
            theme.palette.success.light,
            theme.palette.primary.main,
          ],
          'circle-stroke-width': 2,
          'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 2],
        }}
      />
    </Source>
  )
}

interface PolylineProps {
  color: string
  position: [Coordinates, Coordinates]
  width: number
}

/**
 * Line connecting markers
 * @param color
 * @param position
 * @param width
 * @constructor
 */
export const Polyline: FunctionComponent<PolylineProps> = ({ color, position, width }) => (
  <Source
    type="geojson"
    data={{
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [toMapboxCoords(position[0]), toMapboxCoords(position[1])],
          },
        },
      ],
    }}
  >
    <Layer type="line" paint={{ 'line-color': color, 'line-width': width }} />
  </Source>
)
