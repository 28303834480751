import { ListResult, Service, ServiceFilter } from 'common/api/v1/types'
import { singleSortQueryFromPaginatedRequestParams, ServicesRequestParams } from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IServiceApi {
  getServices(params: ServicesRequestParams): Promise<ListResult<Service>>
  getService(id: string): Promise<Service>
  updateService(service: Service): Promise<Service>
}

export class ServiceApi implements IServiceApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  updateService(service: Service): Promise<Service> {
    return this.edgeClient.updateService(service.id, service)
  }

  /**
   * Returns service
   * @param serviceId
   */
  async getService(serviceId: string): Promise<Service> {
    return this.edgeClient.getService(serviceId)
  }

  async getServices({ filter: searchName, ...params }: ServicesRequestParams): Promise<ListResult<Service>> {
    const filter: ServiceFilter = { searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { items, total } = await this.edgeClient.listServices(query)

    return {
      items,
      total,
    }
  }
}
