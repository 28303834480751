import { useState } from 'react'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../common/Tooltip'
import Typography from '@mui/material/Typography'
import { HighlightOff } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import { Output, OutputRecipientList, OutputRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { AutoUpdatingOutputHealthIndicator as HealthIndicator } from '../../common/Indicator'
import { OutputsRequestParams } from '../../../api/nm-types'

const styles = {
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '48px',
    minWidth: '140px',
  },
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
}

const outputListsApi = Api.outputListsApi

const Outputs = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { values, setFieldValue } = useFormikContext<
    OutputRecipientList & Pick<OutputRecipientListUpdate, 'removeOutputs'>
  >()
  const onRemove = (id: Output['id']) => setFieldValue('removeOutputs', values.removeOutputs.concat(id))
  const onCancel = (id: Output['id']) =>
    setFieldValue(
      'removeOutputs',
      values.removeOutputs.filter((outputId) => outputId !== id),
    )

  return (
    <Grid item xs={12}>
      <PaginatedList<OutputsRequestParams, Output>
        api={function (params) {
          return outputListsApi.getOutputs(values.id, true, params)
        }}
        emptyMessage="no outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
        }}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: (output) => <HealthIndicator outputId={output.id} />,
              },
              {
                getValue: ({ id }) => (
                  <Box sx={styles.actions}>
                    {values.removeOutputs.includes(id) ? (
                      <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
                        Cancel remove
                      </Button>
                    ) : (
                      <Tooltip title="Remove from list" placement="top">
                        <IconButton edge="start" aria-label="remove" onClick={() => onRemove(id)}>
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                ),
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default Outputs
