import { AlarmLog, ListResult } from 'common/api/v1/types'
import { IAlarmLogsApi } from './api'
import { MOCK_ALARM_LOGS, MOCK_DELAY_MS } from '../MockData'
import { listResult } from '../../utils'

export class MockAlarmLogsApi implements IAlarmLogsApi {
  getAlarmLogs(): Promise<ListResult<AlarmLog>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(listResult(MOCK_ALARM_LOGS))
      }, MOCK_DELAY_MS),
    )
  }
}
