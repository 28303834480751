import { Route } from 'react-router-dom'

import { List } from './List'
import { Edit } from './Edit'

import { useRoutes } from '../../store'

export const Regions = () => {
  const routes = useRoutes()
  return (
    <>
      <Route exact path={routes.regions.route} component={List} />
      <Route exact path={routes.regionsUpdate.route} component={Edit} />
    </>
  )
}
