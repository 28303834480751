import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material/styles'

import { Link } from './Link'
import { usePageParams } from '../../utils'

const styles = {
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  message: {
    textTransform: 'uppercase',
  },
  buttonWrapper: {
    margin: (theme: Theme) => theme.spacing(2),
  },
  button: {
    margin: (theme: Theme) => theme.spacing(2),
  },
}

export interface MissingContentProps {
  message: string
  buttonText?: string
  url?: string
}

/**
 * Common component to show on empty list returned for entities list page
 * @param message - the message to show
 * @param buttonText - the text for the button that leads to create the entity instance
 * @param url - url for the entity creation form
 */
export const MissingContent = ({ message, buttonText, url }: MissingContentProps) => {
  const [{ filter: filterString }, setPageParams] = usePageParams<{ filter: string | undefined }>()

  if (filterString)
    return (
      <Box sx={styles.centered}>
        <Typography sx={styles.message} variant="h2">
          {`Could not find "${filterString}"`}
        </Typography>
        <Button
          sx={styles.button}
          variant="outlined"
          data-test-id="clear-search-button"
          onClick={() => setPageParams({ filter: undefined })}
        >
          Clear search
        </Button>
      </Box>
    )

  return (
    <Box sx={styles.centered}>
      <Typography sx={styles.message} variant="h2">
        {message}
      </Typography>
      {url && buttonText && (
        <Box sx={styles.buttonWrapper}>
          <Link type="button" to={url}>
            <Button variant="outlined">{buttonText}</Button>
          </Link>
        </Box>
      )}
    </Box>
  )
}
