import type { BrowserEdgeClient } from './network'
import { IInputApi, InputApi } from './inputs/api'
import { IOutputApi, OutputApi } from './outputs/api'
import { MockInputApi } from './inputs/devApi'
import { MockOutputApi } from './outputs/devApi'
import { IUserApi, UserApi } from './user/api'
import { IServiceApi, ServiceApi } from './service/api'
import { MockServiceApi } from './service/devApi'
import { MockUserApi } from './user/devApi'
import { AppliancesApi, IApplianceApi } from './appliances/api'
import { MockApplianceApi } from './appliances/devApi'
import { IPortsApi, PortsApi } from './ports/api'
import { MockPortsApi } from './ports/devApi'
import { IGroupsApi, GroupsApi } from './groups/api'
import { MockGroupsApi } from './groups/devApi'
import { IAlarmApi, AlarmsApi } from './alarms/api'
import { MockAlarmApi } from './alarms/devApi'
import { IAlarmLogsApi, AlarmLogsApi } from './alarmLogs/api'
import { MockAlarmLogsApi } from './alarmLogs/devApi'
import { BitratesApi, IBitratesApi } from './bitrates/api'
import { MockBitratesApi } from './bitrates/devApi'
import { BillingApi, IBillingApi } from './billing/api'
import { UsageApi, IUsageApi } from './usage/api'
import { GraphApi, IGraphApi } from './graph/api'
import { MockGraphApi } from './graph/devApi'
import { ILocationApi, LocationApi } from './location/api'
import { IRegionApi, RegionApi } from './region/api'
import { MockRegionApi } from './region/devApi'
import { IOutputListApi, OutputListApi } from './outputLists/api'
import { MockOutputListApi } from './outputLists/devApi'
import { IGroupListApi, GroupListApi } from './groupLists/api'
import { MockGroupListApi } from './groupLists/devApi'
import { IAuditApi, AuditApi } from './audit/api'
import { MockAuditApi } from './audit/devApi'
import { ISettingsApi, SettingsApi } from './settings/api'
import { MockSettingsApi } from './settings/devApi'
import { IStatusApi, StatusApi } from './status/api'
import { MockStatusApi } from './status/devApi'
import { IImageApi, ImageApi } from './image/api'
import { MockImageApi } from './image/devApi'
import { ITlsApi, TlsApi } from './tls/api'
import { MockTlsApi } from './tls/devApi'
import { IBuildInfoApi, BuildInfoApi } from './buildInfo/api'
import { MockBillingApi } from './billing/devApi'
import { MockUsageApi } from './usage/devApi'
import { MockBuildInfoApi } from './buildInfo/devApi'
import { AddressMappingApi, IAddressMappingApi } from './addressMappings/api'
import { MockAddressMappingApi } from './addressMappings/devApi'
import { IKubernetesApi, KubernetesApi } from './kubernetes/api'
import { MockKubernetesApi } from './kubernetes/devApi'
import { INetworksApi, NetworksApi } from './networks/api'
import { MockNetworksApi } from './networks/devApi'
import { IApiTokenApi, ApiTokenApi } from './apiTokens/api'
import { MockApiTokenApi } from './apiTokens/devApi'
import { ITunnelApi, TunnelApi } from './tunnel/api'
import { MockTunnelApi } from './tunnel/devApi'
import { ITokenApi, TokenApi } from './tokens/api'
import { MockTokenApi } from './tokens/devApi'

export interface IApi {
  addressMappingsApi: IAddressMappingApi
  alarmsApi: IAlarmApi
  alarmLogsApi: IAlarmLogsApi
  appliancesApi: IApplianceApi
  apiTokensApi: IApiTokenApi
  auditApi: IAuditApi
  billingApi: IBillingApi
  bitratesApi: IBitratesApi
  graphApi: IGraphApi
  groupListsApi: IGroupListApi
  groupsApi: IGroupsApi
  imageApi: IImageApi
  inputApi: IInputApi
  locationApi: ILocationApi
  outputApi: IOutputApi
  outputListsApi: IOutputListApi
  portsApi: IPortsApi
  regionApi: IRegionApi
  settingsApi: ISettingsApi
  statusApi: IStatusApi
  tlsApi: ITlsApi
  usageApi: IUsageApi
  userApi: IUserApi
  serviceApi: IServiceApi
  buildInfoApi: IBuildInfoApi
  kubernetesApi: IKubernetesApi
  networksApi: INetworksApi
  tunnelApi: ITunnelApi
  tokenApi: ITokenApi
  isAborted: BrowserEdgeClient['isAborted']
}

export class DevApi implements IApi {
  public readonly addressMappingsApi = new MockAddressMappingApi()
  public readonly apiTokensApi = new MockApiTokenApi()
  public readonly alarmsApi = new MockAlarmApi()
  public readonly alarmLogsApi = new MockAlarmLogsApi()
  public readonly appliancesApi = new MockApplianceApi()
  public readonly auditApi = new MockAuditApi()
  public readonly billingApi = new MockBillingApi()
  public readonly usageApi = new MockUsageApi()
  public readonly bitratesApi = new MockBitratesApi()
  public readonly graphApi = new MockGraphApi()
  public readonly groupListsApi = new MockGroupListApi()
  public readonly imageApi = new MockImageApi()
  public readonly inputApi = new MockInputApi()
  public readonly locationApi = new LocationApi()
  public readonly groupsApi = new MockGroupsApi()
  public readonly outputApi = new MockOutputApi()
  public readonly outputListsApi = new MockOutputListApi()
  public readonly portsApi = new MockPortsApi()
  public readonly regionApi = new MockRegionApi()
  public readonly settingsApi = new MockSettingsApi()
  public readonly statusApi = new MockStatusApi()
  public readonly tlsApi = new MockTlsApi()
  public readonly userApi = new MockUserApi()
  public readonly serviceApi = new MockServiceApi()
  public readonly buildInfoApi = new MockBuildInfoApi()
  public readonly kubernetesApi = new MockKubernetesApi()
  public readonly networksApi = new MockNetworksApi()
  public readonly tunnelApi = new MockTunnelApi()
  public readonly tokenApi = new MockTokenApi()
  isAborted(e: DOMException) {
    return e.name === 'AbortError'
  }
}

export class Api implements IApi {
  readonly addressMappingsApi
  readonly apiTokensApi
  readonly alarmsApi
  readonly alarmLogsApi
  readonly appliancesApi
  readonly auditApi
  readonly billingApi
  readonly usageApi
  readonly bitratesApi
  readonly graphApi
  readonly groupListsApi
  readonly imageApi
  readonly inputApi
  readonly locationApi
  readonly groupsApi
  readonly outputApi
  readonly outputListsApi
  readonly portsApi
  readonly regionApi
  readonly settingsApi
  readonly statusApi
  readonly tlsApi
  readonly tunnelApi
  readonly userApi
  readonly serviceApi
  readonly buildInfoApi
  readonly kubernetesApi
  readonly networksApi
  readonly tokenApi: ITokenApi
  constructor(readonly edgeClient: BrowserEdgeClient) {
    this.addressMappingsApi = new AddressMappingApi(this.edgeClient)
    this.apiTokensApi = new ApiTokenApi(this.edgeClient)
    this.alarmsApi = new AlarmsApi(this.edgeClient)
    this.alarmLogsApi = new AlarmLogsApi(this.edgeClient)
    this.appliancesApi = new AppliancesApi(this.edgeClient)
    this.auditApi = new AuditApi(this.edgeClient)
    this.billingApi = new BillingApi(this.edgeClient)
    this.usageApi = new UsageApi(this.edgeClient)
    this.bitratesApi = new BitratesApi(this.edgeClient)
    this.graphApi = new GraphApi(this.edgeClient)
    this.groupListsApi = new GroupListApi(this.edgeClient)
    this.imageApi = new ImageApi(this.edgeClient)
    this.inputApi = new InputApi(this.edgeClient)
    this.locationApi = new LocationApi()
    this.groupsApi = new GroupsApi(this.edgeClient)
    this.outputApi = new OutputApi(this.edgeClient)
    this.outputListsApi = new OutputListApi(this.edgeClient)
    this.portsApi = new PortsApi(this.edgeClient)
    this.regionApi = new RegionApi(this.edgeClient)
    this.settingsApi = new SettingsApi(this.edgeClient)
    this.statusApi = new StatusApi(this.edgeClient)
    this.tlsApi = new TlsApi(this.edgeClient)
    this.userApi = new UserApi(this.edgeClient)
    this.serviceApi = new ServiceApi(this.edgeClient)
    this.buildInfoApi = new BuildInfoApi(this.edgeClient)
    this.kubernetesApi = new KubernetesApi(this.edgeClient)
    this.networksApi = new NetworksApi(this.edgeClient)
    this.tunnelApi = new TunnelApi(this.edgeClient)
    this.tokenApi = new TokenApi(this.edgeClient)
  }

  isAborted = this.edgeClient.isAborted
}
