import { PaginatedRequestParams } from '../../api/nm-types'
import { FilterBy } from '../common/Filters/FilterView/FilterTemplate'

enum ListRegionFilterKey {
  name = 'name',
}

export function mapRegionFilterToUrlParam(filter: FilterBy): Partial<PaginatedRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListRegionFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToRegionFilter(key: keyof PaginatedRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListRegionFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}
