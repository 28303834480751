import type { EdgeClient } from 'common/generated/edgeClient'
import type { IStatusApi } from './api'

export class MockStatusApi implements IStatusApi {
  listStatus(): ReturnType<EdgeClient['listStatuses']> {
    const items: Awaited<ReturnType<EdgeClient['listStatuses']>>['items'] = [
      { name: 'some-name1', status: 'OK', description: 'some-description1' },
      { name: 'some-name2', status: 'WARN', description: 'some-description2', issues: [{ message: 'some-message2' }] },
      { name: 'some-name3', status: 'ERROR', description: 'some-description3', issues: [{ message: 'some-message3' }] },
    ]

    return Promise.resolve({
      meta: {
        edgeVersion: 'some-edgeVersion',
      },
      items,
      total: items.length,
    })
  }
}
