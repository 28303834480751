import * as uuid from 'uuid'

import { fetch } from '../utils/fetch'
import { EdgeClient } from 'common/generated/edgeClient'
import type { RequestOptions, RequestResult } from 'common/network/request-options'

export class BrowserEdgeClient extends EdgeClient {
  constructor(params: { baseUrl: string; apiKey?: string }) {
    super(params)
  }

  protected async _request<T>({
    method,
    url,
    body,
    optionsOverrides,
  }: Pick<RequestOptions, 'method' | 'url' | 'body'> & { optionsOverrides?: Partial<RequestOptions> }): Promise<
    RequestResult<T>
  > {
    try {
      const res = await request<T>({
        method,
        url,
        body,
        json: true,
        requestId: uuid.v4(),
        throwOnNon200: true,
        ...optionsOverrides,
      })
      return res
    } catch (e) {
      // eslint-disable-next-line no-console
      if (!this.isAborted(e)) console.error(`Error: performing '${method.toUpperCase()}' towards '${url}': `, e)
      throw e
    }
  }

  public isAborted = (e: DOMException) => {
    return e.name === 'AbortError'
  }
}

const request = async <T>(options: RequestOptions): Promise<RequestResult<T>> => {
  // eslint-disable-next-line no-console
  const { url, method, headers, body, json, requestId, signal } = options
  const inferredHeaders: { [header: string]: string } = {}
  if (requestId) {
    inferredHeaders['x-request-id'] = requestId
  }
  if (json) {
    inferredHeaders['content-type'] = 'application/json'
  }

  // The number of milliseconds before the request times out and is aborted
  const timeout = AbortSignal.timeout(20_000)

  return fetch<T>(url, {
    method,
    headers: {
      ...inferredHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
    signal: anySignal(signal ? [signal, timeout] : [timeout]),
  })
}

// TODO: Replace with AbortSignal.any()
// https://caniuse.com/mdn-api_abortsignal_any_static
// https://github.com/whatwg/fetch/issues/905
function anySignal(signals: AbortSignal[]): AbortSignal {
  const controller = new AbortController()

  for (const signal of signals) {
    if (signal.aborted) return signal

    signal.addEventListener('abort', () => controller.abort(signal.reason), {
      signal: controller.signal,
    })
  }

  return controller.signal
}
