import { useState, useCallback, ChangeEvent, FC } from 'react'
import { Field, FieldAttributes, FieldProps, getIn, useFormikContext } from 'formik'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { omit } from 'lodash/fp'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import BackendValidation from './BackendValidation'
import { PasswordField } from './PasswordField'
import { useDebouncedCallback, validate } from '../../../utils'

const disallowNonNumericCharacters = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Disallow all non-numeric characters
  if (isNaN(parseFloat(event.key))) {
    event.preventDefault()
  }
}

const Comp = (props: FieldProps & TextFieldProps) => {
  const fieldError = getIn(props.form.errors, props.field.name)
  const showError = getIn(props.form.touched, props.field.name) && Boolean(fieldError)
  const muiProps: TextFieldProps & Pick<TextInputProps, 'showPassword'> = {
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    ...props.field,
    ...props,
  }

  if (props.type === 'number') {
    // Prevent scroll
    muiProps.onWheel = (e) => e.target instanceof HTMLElement && e.target.blur()
  }

  return (
    <>
      {props.type === 'password' ? <PasswordField {...muiProps} /> : <TextField {...muiProps} />}
      <BackendValidation form={props.form} name={props.field.name} />
    </>
  )
}

interface TextInputProps extends GridItemProps {
  name?: string
  label?: string
  multiline?: boolean
  noNegative?: boolean
  unsignedInt?: boolean
  validators?: Parameters<typeof validate>[0]
  showPassword?: { show: boolean; id: string }
  variant?: TextFieldProps['variant']
  hiddenLabel?: boolean
}

/**
 * Simple text input (string, number, email, password etc.)
 * @param props {
 *   name: formik's name
 *   label?: label to show
 *   multiline?: if it is string field and we need it to be multiline
 *   noNegative?: for number fields we can forbid setting negative values by keyboard arrows, and also it adds validation rule
 *   unsignedInt?: disallow 'e', '+', '-', '.' for number fields
 *   validators?: validateJs validations plus self defined ones from utils/validation
 *   required?: adds validation
 *   showPassword?: If true, the password is visible. It's valid only when type=password.
 * }
 * @constructor
 */
const TextInput: FC<FieldAttributes<TextInputProps>> = (props) => {
  const { validators = {}, variant = 'outlined', hiddenLabel = false, unsignedInt = true } = props
  if (props.required) validators.presence = true
  if (props.noNegative) {
    validators.number = {
      greaterThanOrEqualTo: 0,
      message: 'Can not be negative',
      ...validators.number,
    }
  }
  const onKeyPress =
    props.onKeyPress ?? (props.type === 'number' && unsignedInt) ? disallowNonNumericCharacters : undefined

  return (
    <GridItem {...props}>
      <Field
        label={hiddenLabel ? undefined : props.name}
        component={Comp}
        fullWidth
        variant={variant}
        hiddenLabel={hiddenLabel}
        margin="normal"
        autoComplete="off"
        onKeyPress={onKeyPress}
        validate={validate(validators)}
        {...(props.noNegative ? { inputProps: { min: '0' } } : undefined)}
        {...omitGridProps(omit(['noNegative', 'validators'], props))}
        data-test-id={props.name ? `textinput-${props.name}` : undefined}
      />
    </GridItem>
  )
}

// Performant version of TextInput that persists its inner value and debounces onChange so that less re-renders happen due to changes on formik
export const PerformantTextInput: FC<FieldAttributes<TextInputProps>> = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const [innerValue, setInnerValue] = useState(getIn(values, props.name))

  const debouncedFormikUpdate = useDebouncedCallback((value: string) => {
    setFieldValue(props.name, value)
  }, 200)

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value =
        event.currentTarget.type === 'number' ? event.currentTarget.valueAsNumber : event.currentTarget.value
      // Update inner value immediately but debounce the update to the formik context
      setInnerValue(value)
      debouncedFormikUpdate(value)
    },
    [debouncedFormikUpdate],
  )

  const { validators = {}, unsignedInt = true } = props
  if (props.required) validators.presence = true
  if (props.noNegative) {
    validators.number = {
      greaterThanOrEqualTo: 0,
      message: 'Can not be negative',
      ...validators.number,
    }
  }
  const onKeyPress =
    props.onKeyPress ?? (props.type === 'number' && unsignedInt) ? disallowNonNumericCharacters : undefined

  return (
    <GridItem {...props}>
      <Field
        label={props.name}
        component={Comp}
        fullWidth
        variant="outlined"
        margin="normal"
        autoComplete="off"
        validate={validate(validators)}
        value={innerValue}
        onKeyPress={onKeyPress}
        onChange={handleOnChange}
        {...(props.noNegative ? { inputProps: { min: '0' } } : undefined)}
        {...omitGridProps(omit(['noNegative', 'validators'], props))}
        data-test-id={props.name ? `textinput-${props.name}` : undefined}
      />
    </GridItem>
  )
}

export default TextInput
