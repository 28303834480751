import { Autocomplete } from './Form'
import { useField, useFormikContext } from 'formik'
import { useTheme } from '@mui/material/styles'
import { CIDR_BLOCK_ALLOW_ALL, CIDRToolTip } from '../../texts'
import { whitelistCidrBlockToArray } from 'common/utils'
import { useCallback } from 'react'

function getWarningText(cidrBlocks: string[], isRequired: boolean) {
  if (cidrBlocks.length === 0 && !isRequired) {
    return `Not selecting a value will allow all incoming traffic`
  } else if (cidrBlocks.includes(CIDR_BLOCK_ALLOW_ALL)) {
    return `${CIDR_BLOCK_ALLOW_ALL} allows all incoming traffic`
  }
  return undefined
}
export const WhitelistCidrBlockAutoComplete = ({ name, required }: { name: string; required: boolean }) => {
  const theme = useTheme()
  const [field, meta] = useField(name)
  const initialCidrBlocks: string[] = whitelistCidrBlockToArray(meta.initialValue)
  const cidrBlocks: string[] = whitelistCidrBlockToArray(field.value)
  const warningText = getWarningText(cidrBlocks, required)

  const api = useCallback(() => {
    const options = Array.from(new Set([CIDR_BLOCK_ALLOW_ALL, ...initialCidrBlocks]))
    return Promise.resolve({ items: options, total: options.length })
  }, [])

  return (
    <Autocomplete<string>
      name={name}
      label={`Whitelist CIDR blocks`}
      tooltip={CIDRToolTip(required, 'Input')}
      required={required}
      api={api}
      multiple
      allowCustomOptions={true}
      validators={{
        ipv4CidrBlock: {},
      }}
      filterSelectedOptions={true}
      comment={warningText}
      commentProps={{ color: theme.palette.warning.light }}
      formik={useFormikContext()}
      defaultOption={cidrBlocks}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => option}
      optionComparator={(o1, o2) => o1 == o2}
      autoSelect
      disableClearable
    />
  )
}
