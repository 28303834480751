import { Group, PhysicalPortInfo, PortType } from 'common/api/v1/types'

export enum InterfaceUnavailableReason {
  wrongGroup,
  occupied,
  noAddress,
  activeAlarms,
}

/**
 * Checks if the received interface is available for use by the received input/output, based on ownership, vacancy, alarm status or addresses.
 * @param physicalPort The physical port to check if it is available for use
 * @param requestee The input or output wanting to use the port
 * @return the reason why the received interface is not unavailable, or undefined if the interface is available.
 */
export function whyIsPhysicalPortUnavailable({
  physicalPort,
  requestee,
}: {
  physicalPort: Pick<PhysicalPortInfo, 'owner' | 'occupiedPorts' | 'portType' | 'addresses' | 'isDisabled'>
  requestee?: { groupId?: Group['id'] }
}): { code: InterfaceUnavailableReason; msg: string } | undefined {
  if (requestee?.groupId && requestee.groupId !== physicalPort.owner)
    return {
      code: InterfaceUnavailableReason.wrongGroup,
      msg: 'interface belongs to another group',
    }

  const occupant = physicalPort.occupiedPorts?.[0]
  const canBeUsedByMultipleServices = physicalPort.portType !== PortType.coax
  const isOccupied = occupant && !canBeUsedByMultipleServices
  if (isOccupied)
    return {
      code: InterfaceUnavailableReason.occupied,
      msg: `interface already in use by a "${occupant.portMode}-${
        occupant?.inputId ? 'input' : occupant?.outputId ? 'output' : 'service'
      }"`,
    }

  const hasNoAddresses = physicalPort.portType === PortType.ip && physicalPort.addresses.length === 0
  if (hasNoAddresses)
    return {
      code: InterfaceUnavailableReason.noAddress,
      msg: `interface has no addresses`,
    }

  if (physicalPort.isDisabled)
    return {
      code: InterfaceUnavailableReason.activeAlarms,
      msg: `interface has active alarms`,
    }

  return undefined
}
