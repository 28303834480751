import { merge } from 'lodash/fp'
import { createTheme, ThemeOptions } from '@mui/material/styles'

import { defaultCommon } from './common'
export { NimbraBlue, NimbraGrey } from './common'

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#F5F5F5',
      paper: '#E8E8E8',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: '#757575',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          backgroundColor: 'transparent' as const,
          color: '#000000',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#757575',
        },
      },
    },
  },
}

export const defaultThemeLight = createTheme(merge(defaultCommon, lightThemeOptions))
