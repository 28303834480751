import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { GraphNodeType, Output, Pid } from 'common/api/v1/types'
import DataSet from '../../common/DataSet'
import { pluralize } from '../../../utils'
import { formatBitrate } from 'common/api/v1/helpers'
import { Service as TsService, TransportStream } from 'common/tr101Types'
import { InfoSection } from './InfoSection'
import { shallowEqual, useSelector } from 'react-redux'
import { GlobalState } from '../../../store'
import { isApplianceStandby } from './utils'
import { SelectedGraphItem } from '../Graph/ServiceOverviewGraph'
import { WithRequired } from 'common/util'

export const integer = (val?: number | null) => (val == null ? null : String(Math.round(val)))
export const packets = (value?: number | null) =>
  value === undefined || value === null ? '-' : pluralize(value, 'packet')

const VideoStreams = ({ pids }: { pids: Pid[] }) => {
  const tsInfos = pids
  if (tsInfos.length === 0) return null
  return (
    <>
      <Typography variant="h3">Video streams</Typography>
      {tsInfos.map((pid, i) => {
        return (
          <div key={`${pid.id}-video`}>
            <DataSet
              values={{
                Pid: pid?.id,
                Type: pid?.description,
                'Avg bitrate': formatBitrate(pid?.bitrate?.average),
                Width: pid?.streamInfo?.video?.videoSize?.horizontal,
                Height: pid?.streamInfo?.video?.videoSize?.vertical,
                ['Frame rate']: pid?.streamInfo?.video?.frameRate?.toFixed(2),
                Interlaced: pid?.streamInfo?.video?.interlaced,
              }}
            />
            {i !== tsInfos.length - 1 && <Divider />}
          </div>
        )
      })}
    </>
  )
}

const AudioStreams = ({ pids }: { pids: Pid[] }) => {
  const tsInfos = pids
  if (tsInfos.length === 0) return null
  return (
    <>
      <Typography variant="h3">Audio streams</Typography>
      {tsInfos.map((pid, i) => {
        return (
          <div key={`${pid.id}-audio`}>
            <DataSet
              values={{
                Pid: pid?.id,
                Type: pid?.description,
                'Avg bitrate': formatBitrate(pid?.bitrate?.average),
                'Sample rate': formatSampleRate(pid?.streamInfo?.audio?.sampleRate),
              }}
            />
            {i !== tsInfos.length - 1 && <Divider />}
          </div>
        )
      })}
    </>
  )
}

const OtherStreams = ({ pids }: { pids: Pid[] }) => {
  const tsInfos = pids
  if (tsInfos.length === 0) return null
  return (
    <>
      <Typography variant="h3">Other streams</Typography>
      {tsInfos.map((pid, i) => {
        return (
          <div key={`${pid.id}-other`}>
            <DataSet
              values={{
                Pid: pid?.id,
                Type: pid?.description,
                'Avg bitrate': formatBitrate(pid?.bitrate?.average),
              }}
            />
            {i !== tsInfos.length - 1 && <Divider />}
          </div>
        )
      })}
    </>
  )
}

const GlobalPids = ({ pids }: { pids: Pid[] }) => {
  const tsInfos = pids
  if (tsInfos.length === 0) return null
  return (
    <details style={{ marginBottom: '16px' }}>
      <summary>
        <Typography variant="h3" component="span">
          Global Pids
        </Typography>
      </summary>
      {tsInfos.map((pid, i) => {
        return (
          <div key={`${pid?.id}-global`}>
            <DataSet
              values={{
                Pid: pid?.id,
                Type: pid?.description,
                'Avg bitrate': formatBitrate(pid?.bitrate?.average),
              }}
            />
            {i !== tsInfos.length - 1 && <Divider />}
          </div>
        )
      })}
    </details>
  )
}

const Services = ({ tsInfo }: { tsInfo: TransportStream | undefined }) => {
  const services = tsInfo?.services
  if (!tsInfo || !services || services.length === 0) {
    return null
  }

  const pids = tsInfo.pids?.filter((p) => typeof p !== 'undefined').map((p) => p as Pid) ?? []
  const globalPids: Pid[] = []
  const servicePidMapping = new Map<number, Pid[]>()

  const orderedServices = services.filter((s): s is WithRequired<TsService, 'pids' | 'id'> => s.pids !== undefined) // pids and id should never be undefined
  orderedServices.sort((a, b) => a.id - b.id) // Sort for consistent ordering

  for (const pid of pids.filter((p): p is WithRequired<Pid, 'id'> => p.id !== undefined)) {
    const servicesUsingPid = orderedServices.filter((s) => s.pids.includes(pid.id))
    if (servicesUsingPid.length === 0) {
      // No service include the PID -> it's a global PID
      globalPids.push(pid)
      continue
    }

    for (const service of servicesUsingPid) {
      const servicePids = servicePidMapping.get(service.id) ?? []
      servicePids.push(pid)
      servicePidMapping.set(service.id, servicePids)
    }
  }

  return (
    <>
      <GlobalPids pids={globalPids} />

      <Typography variant="h3">Programs</Typography>
      {orderedServices.map((service, index) => {
        const pidsInService = servicePidMapping.get(service.id)
        return (
          <div key={`${service.id}-service`}>
            <DataSet
              values={{
                Id: service.id,
                Name: service.name ?? '-',
                Type: service.type?.description ?? '-',
                'Avg bitrate': formatBitrate(service?.averageBitrate),
              }}
            />

            {pidsInService?.length ? (
              <details style={{ marginBottom: '16px' }}>
                <summary>
                  <Typography component="span" style={{ fontWeight: 'bold' }}>
                    Contents
                  </Typography>
                </summary>
                <div style={{ display: 'flex', paddingTop: '16px' }}>
                  <div style={{ marginRight: '40px' }}>
                    <VideoStreams pids={pidsInService?.filter(isVideoPid) ?? []} />
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <AudioStreams pids={pidsInService?.filter(isAudioPid) ?? []} />
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <OtherStreams pids={pidsInService?.filter((p) => !isAudioPid(p) && !isVideoPid(p)) ?? []} />
                  </div>
                </div>
              </details>
            ) : null}

            {index !== services.length - 1 && <Divider />}
          </div>
        )
      })}

      <div style={{ marginTop: '12px' }}>
        <Typography variant="body2">Showing TS info for internal channel ID: {tsInfo.channelId}</Typography>
      </div>
    </>
  )
}

function isVideoPid(pid: Pid): boolean {
  return !!pid.streamInfo?.video
}

function isAudioPid(pid: Pid): boolean {
  return !!pid.streamInfo?.audio
}

export function formatSampleRate(sampleRate?: number | null) {
  if (typeof sampleRate !== 'number') {
    return 'N/A'
  }

  return sampleRate.toString()
}

export function pidsForService(pids: Pid[] | undefined, service: TsService) {
  if (!service.pids || !pids) {
    return []
  }
  return pids.filter((p) => service.pids?.includes(p.id!))
}

export const TsInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input } = useSelector(({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer, shallowEqual)
  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  if (!input) {
    return null
  }
  if (!input.tr101290Enabled) {
    return <InfoSection title="TS info disabled"></InfoSection>
  }
  const applianceId = selected.id
  if (isApplianceStandby(applianceId, input, outputs)) {
    return <InfoSection title="TS info not available in standby"></InfoSection>
  }

  const tsInfoMatchesAppliance = (tsInfo: TransportStream) => tsInfo.applianceId === applianceId
  let tsInfo = (input.tsInfo || []).find(tsInfoMatchesAppliance)
  if (!tsInfo) {
    for (const output of outputs) {
      const outputTsInfo = output.tsInfo?.find(tsInfoMatchesAppliance)
      if (outputTsInfo) {
        tsInfo = outputTsInfo
        break
      }
    }
  }
  return (
    <InfoSection title={'TS info'}>
      <div style={{ display: 'flex', paddingTop: '16px' }}>
        <div>
          <Services tsInfo={tsInfo} />
        </div>
      </div>
    </InfoSection>
  )
}

export function isGraphNodeApplianceType(type: GraphNodeType) {
  return [
    GraphNodeType.inputEdgeAppliance,
    GraphNodeType.inputRegionCore,
    GraphNodeType.outputRegionCore,
    GraphNodeType.inputRegionOutputEdgeAppliance,
    GraphNodeType.outputRegionOutputEdgeAppliance,
  ].includes(type)
}

export interface OutputStatistics {
  output: Output
}
