import { useState } from 'react'
import { useDrag } from 'react-dnd'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { alpha, Theme } from '@mui/material/styles'

import Thumbnail from '../../common/Thumbnail'
import SearchInput from '../../common/SearchInput'
import { AutoUpdatingInputHealthIndicator } from '../../common/Indicator'
import Pendable from '../../common/Pendable'
import { useDebouncedFilter, useInputsSelector } from '../../../utils'
import { inputOutputColor } from '../../../utils'
import { EnrichedInput } from '../../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import { Pagination } from '../../common/Table/Pagination'
import useWindowDimensions from '../../../utils/hooks'
import Divider from '@mui/material/Divider'

const LOCALE_STORAGE_PER_PAGE_KEY = 'edge:sm:inputsPerPage'
const headerFooterHeight = 58

const styles = {
  container: {
    height: '100%',
    overflow: 'hidden',
  },
  toolbar: (theme: Theme) => ({
    maxHeight: headerFooterHeight,
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(1, 3),
    },
  }),
  inputList: {
    marginTop: 0,
    marginBottom: 0,
    padding: (theme: Theme) => theme.spacing(0, 3, 3),
    height: `calc(100% - ${headerFooterHeight * 2}px)`,
    overflowY: 'auto',
    alignContent: 'flex-start',
  },
  pagination: {
    height: headerFooterHeight,
  },
  item: {
    flexGrow: 1,
  },
  card: {
    background: (theme: Theme) => theme.palette.common.white,
    cursor: 'grab',
    '&.dragging': {
      opacity: 0.7,
      cursor: 'grabbing',
    },
  },
  health: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: (theme: Theme) => theme.spacing(2, 2, 0, 2),
    top: 0,
    left: 0,
    right: 0,
    paddingBottom: (theme: Theme) => theme.spacing(2),
    color: (theme: Theme) => theme.palette.common.white,
    background: (theme: Theme) => alpha(theme.palette.common.black, 0.5),
    fontSize: (theme: Theme) => theme.typography.subtitle2.fontSize,
  },
  sub: {
    color: (theme: Theme) => theme.palette.text.secondary,
    fontSize: (theme: Theme) => theme.typography.subtitle2.fontSize,
    padding: (theme: Theme) => theme.spacing(0, 2, 2),
  },
}

interface InputProps {
  input: EnrichedInput
}

const InputCard = ({ input }: InputProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'input',
    item: input,
    options: {
      dropEffect: 'copy',
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  return (
    <Grid item sx={styles.item} xs={12} sm={6} md={4} lg={3} xl={2}>
      <div ref={drag} style={{ position: 'relative' }}>
        <Card sx={styles.card} className={cn(isDragging && 'dragging')}>
          <Box>
            <Thumbnail input={input} />
            <Box sx={styles.health} style={{ position: 'absolute', pointerEvents: 'none' }}>
              <AutoUpdatingInputHealthIndicator initialInput={input} />
              <div>{getFormattedTransportStreamContent((input?.tsInfo || [])[0])}</div>
            </Box>
          </Box>
          <CardHeader
            title={
              <Typography component="div" data-test-id="input-title" {...inputOutputColor(input)}>
                {input.name}
              </Typography>
            }
            titleTypographyProps={{ variant: 'subtitle1' }}
            subheader={
              <Typography color={input.adminStatus ? 'primary' : 'secondary'} variant="caption">
                {input.canSubscribe ? 'Full Access' : 'Preview'}
              </Typography>
            }
          />
          {!!input._owner && <Box sx={styles.sub}>Source: {input._owner.name}</Box>}
        </Card>
      </div>
    </Grid>
  )
}

const Inputs = () => {
  const [filter, setFilter] = useState('')
  const debouncedFilter = useDebouncedFilter(filter)
  const [pageNumber, setPageNumber] = useState('0')
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem(LOCALE_STORAGE_PER_PAGE_KEY) || '10')
  const { width } = useWindowDimensions()
  const hidePerPagePaginationOption = width < 600

  const { inputs, total, loading } = useInputsSelector({
    pageNumber,
    rowsPerPage,
    filter: debouncedFilter || undefined,
  })

  return (
    <Pendable id="inputs" pending={loading} cover coverContainerProps={styles.container}>
      <Toolbar sx={styles.toolbar} id="inputs-toolbar">
        <Typography variant="h2">Inputs</Typography>
        <SearchInput
          stretchable
          onChange={(input) => {
            setPageNumber('0')
            setFilter(input)
          }}
        />
      </Toolbar>
      <Grid container spacing={3} sx={styles.inputList} id="inputs-list">
        {inputs.map((input) => (
          <InputCard key={input.id} input={input} />
        ))}
      </Grid>
      <Box sx={styles.pagination}>
        <Divider />
        <Pagination
          total={total}
          page={pageNumber}
          perPage={rowsPerPage}
          changePageCallback={setPageNumber}
          changeRowsPerPageCallback={(rows) => {
            setPageNumber('0')
            localStorage.setItem(LOCALE_STORAGE_PER_PAGE_KEY, rows)
            setRowsPerPage(rows)
          }}
          useUrlSearchParams={false}
          id={'input-pagination'}
          hidePerPageOption={hidePerPagePaginationOption}
          hideJumpToPage={hidePerPagePaginationOption}
        />
      </Box>
    </Pendable>
  )
}

export default Inputs
