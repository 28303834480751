import { useEffect } from 'react'
import { useFormikContext } from 'formik'

// eslint-disable-next-line
// @ts-ignore
const getKeys = (obj: { [key: string | number]: any } | undefined, prefix = '') => {
  return obj
    ? Object.entries(obj).reduce<Array<string>>(
        (acc, [key, value]) => [
          ...acc,
          ...(typeof value === 'string'
            ? [`${prefix ? `${prefix}.` : ''}${key}`]
            : getKeys(
                value as Record<string, any> | Array<Record<string, any>>,
                `${prefix ? `${prefix}.` : ''}${key}`,
              )),
        ],
        [],
      )
    : []
}

/**
 * Helper to focus fields that failed validation. It uses Formik context
 */
const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = getKeys(errors)
      if (keys.length > 0) {
        const selector = `[name="${keys[0]}"]`
        const errorElement = document.querySelector(selector) as HTMLElement
        if (errorElement) {
          const parent = errorElement.closest('.MuiFormControl-root')
          parent && parent.scrollIntoView()
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

export default FocusError
