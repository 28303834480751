import { ApplianceConfiguration } from '../../messages'
import { TunnelType } from './types'

export type InputConfig = {
    [name: string]: ApplianceConfiguration
}

export type ExportedApplianceConfiguration = ApplianceConfiguration

export type EnhancedInputConfig = {
    appliances: { [name: string]: ApplianceConfiguration }
    streamIdToOutputMap: StreamIdToOutputMap
}
export type StreamIdToOutputMap = {
    [streamId: number]: { streamId: number; inputId: string; outputId: string; outputName: string }
}

export const TunnelLabels = {
    [TunnelType.internal]: 'Internal',
    [TunnelType.external]: 'Public',
    [TunnelType.interRegion]: 'Inter Regional',
}
