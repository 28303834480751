import { Route } from 'react-router-dom'

import { Edit } from './Edit'
import { ListView as List } from './List'

import { useRoutes } from '../../store'

export const Users = () => {
  const routes = useRoutes()
  return (
    <>
      <Route path={routes.usersUpdate.route} component={Edit} />
      <Route path={routes.usersNew.route} component={Edit} />
      <Route exact path={routes.users.route} component={List} />
    </>
  )
}
