import { IApiTokenApi } from './api'

export class MockApiTokenApi implements IApiTokenApi {
  /**
   * Creates and ensures a super user API token to be used by the Edge Operator. This needs to be done before installing the Operator in an existing cluster.
   */
  async ensureOperatorToken(): Promise<void> {
    return
  }
}
