import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Tooltip from '../../../common/Tooltip'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Info, MoreVert } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { Appliance, ApplianceInputInfo, ApplianceOutputInfo, EdgeProduct, Role } from 'common/api/v1/types'
import { Api, AppDispatch, GlobalState } from '../../../../store'
import { Link } from '../../../common/Link'
import { Paper } from '../../../common/Form'
import { Table } from '../../../common/Table'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import { InputsRequestParams, PaginatedRequestParams } from '../../../../api/nm-types'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AltRouteIcon from '@mui/icons-material/AltRoute'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import {
  Home as InputLocatedHere,
  // Input as InputLocatedHere,
  Home as OutputLocatedHere,
  ArrowRightAlt as LocatedUpstream,
  ArrowRightAlt as LocatedDownstream,
} from '@mui/icons-material'

import { useRoutes } from '../../../../store'
import { rerouteInput } from '../../../../redux/actions/inputsActions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../../../utils'
import { useHistory } from 'react-router-dom'

const { appliancesApi } = Api

const marginBetweenIconAndName = '12px'

export interface InputsOutputsProps {
  appliance: Appliance
}
const InputsOutputs: React.FunctionComponent<InputsOutputsProps> = ({ appliance }) => {
  const theme: Theme = useTheme()
  const routes = useRoutes()
  const user = useUser()
  const [updateOn, setUpdateOn] = useState(false)
  const refreshLists = () => setUpdateOn(!updateOn)
  const history = useHistory()
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)

  const titleComponent = (
    <div style={{ display: 'flex', paddingBottom: 16 }}>
      <Typography variant="h2" sx={{ width: '50%' }}>
        Inputs
      </Typography>
      <Typography variant="h2" sx={{ width: '50%' }}>
        Outputs
      </Typography>
    </div>
  )
  return (
    <Paper title={titleComponent} collapsible>
      <div style={{ width: '50%' }}>
        <PaginatedList<InputsRequestParams, ApplianceInputInfo>
          api={(params) => appliancesApi.listApplianceInputs(appliance.id, params)}
          updateOn={updateOn}
          hideSearch
          emptyMessage="no inputs found"
          notFoundMessage="no matching inputs"
          List={({ list }) => (
            <Table
              data={list}
              emptyMessageComponent="no inputs found"
              props={{ size: 'small' }}
              noHeader
              config={[
                {
                  getValue: ({ inputName, inputId, inputAdminStatus, applianceName, applianceId }) => {
                    const isUpstreamInput = applianceId !== appliance.id
                    const canReroute = user.role === Role.super && buildInfo?.product === EdgeProduct.edge
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flex: 1,
                          height: '40px',
                        }}
                      >
                        <Link
                          underline="hover"
                          to={routes.inputsUpdate({ id: inputId })}
                          style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                          muiStyle={{
                            display: 'flex',
                            flex: 1,
                            gap: marginBetweenIconAndName,
                            color: inputAdminStatus ? theme.palette.text.primary : theme.palette.text.secondary,
                          }}
                        >
                          <Tooltip
                            title={
                              isUpstreamInput
                                ? `This input is located on upstream appliance ${applianceName} (routed via this appliance)`
                                : 'This input is located on this appliance'
                            }
                          >
                            {isUpstreamInput ? <LocatedUpstream /> : <InputLocatedHere />}
                          </Tooltip>
                          <span style={{ display: 'flex', flex: 1 }}>{inputName}</span>
                        </Link>
                        <IconButton
                          aria-label="service overview button"
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(routes.service({ id: inputId }))
                          }}
                        >
                          <Tooltip title={'Service Overview'} placement="top">
                            <Info />
                          </Tooltip>
                        </IconButton>
                        <ActionMenu
                          inputId={inputId}
                          applianceId={appliance.id}
                          refreshList={refreshLists}
                          disabled={!isUpstreamInput || !canReroute}
                        />
                      </Box>
                    )
                  },
                },
              ]}
            />
          )}
        />
      </div>
      <div style={{ width: '50%' }}>
        <PaginatedList<PaginatedRequestParams, ApplianceOutputInfo>
          api={(params) => appliancesApi.listApplianceOutputs(appliance.id, params)}
          updateOn={updateOn}
          hideSearch
          emptyMessage="no outputs found"
          notFoundMessage="no matching outputs"
          List={({ list }) => (
            <Table
              data={list}
              emptyMessageComponent="no outputs found"
              props={{ size: 'small' }}
              noHeader
              config={[
                {
                  getValue: ({ outputName, outputId, inputId, outputAdminStatus, applianceName, applianceId }) => {
                    const isDownstreamOutput = applianceId !== appliance.id
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flex: 1,
                          height: '40px',
                        }}
                      >
                        <Link
                          underline="hover"
                          style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                          muiStyle={{
                            display: 'flex',
                            flex: 1,
                            gap: marginBetweenIconAndName,
                            color: outputAdminStatus ? theme.palette.text.primary : theme.palette.text.secondary,
                          }}
                          to={routes.outputsUpdate({ id: outputId })}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              height: '40px',
                              alignItems: 'center',
                              gap: marginBetweenIconAndName,
                            }}
                          >
                            <Tooltip
                              title={
                                isDownstreamOutput
                                  ? `This output is located on downstream appliance ${applianceName} (routed via this appliance)`
                                  : 'This output is located on this appliance'
                              }
                            >
                              {isDownstreamOutput ? <LocatedDownstream /> : <OutputLocatedHere />}
                            </Tooltip>
                            {outputName}
                          </div>
                        </Link>
                        <IconButton
                          aria-label="service overview button"
                          disabled={!inputId}
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(routes.service({ id: inputId, outputId }))
                          }}
                        >
                          <Tooltip title={'Service Overview'} placement="top">
                            <Info />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    )
                  },
                },
              ]}
            />
          )}
        />
      </div>
    </Paper>
  )
}

interface ActionMenuProps {
  inputId: string
  applianceId: string
  refreshList: () => void
  disabled: boolean
}

export const ActionMenu = (props: ActionMenuProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const { inputId, applianceId, disabled } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, action: any) => {
    event.stopPropagation()
    setAnchorEl(null)

    dispatch(action)
      .then(() => {
        props.refreshList()
      })
      .catch(() => void 0 /* There will be an error snackbar in this case */)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ display: 'flex', marginLeft: '16px' }}>
      <IconButton
        disabled={disabled}
        aria-label="open appliance input action menu"
        data-test-id="open-appliance-input-action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ marginLeft: '-8px' }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        data-test-id="appliance-input-action-menu"
        data-is-open={open ? 'true' : 'false'}
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '35ch',
          },
        }}
      >
        <MenuItem
          onClick={(e) => handleItemClick(e, rerouteInput({ inputId, avoidApplianceId: applianceId }))}
          data-test-id="reroute-away-from"
        >
          <ListItemIcon>
            <AltRouteIcon />
          </ListItemIcon>
          <ListItemText primary="Reroute away from this appliance" />
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default InputsOutputs
