import { DelayMode } from './rist'

export const DEFAULT_BUFFER_DURATION = 6000
export const DEFAULT_DELAY_MODE = parseInt(process.env.DELAY_MODE || '2') as DelayMode.basedOnOriginTime
export const USE_ORIGIN_TIMING = true
export const SRT_STATS_INTERVAL = 10
export const defaultRegionId = '69920e49-31c4-476a-b6bb-023305262007'
export const defaultRegionName = process.env.EDGE_DEFAULT_REGION_NAME || 'default'
export const EDGE_OPERATOR_API_TOKEN_NAME = 'edge-operator-api-token'
export const EDGE_SUPER_API_TOKEN_SECRET_NAME = 'edge-operator-api-token'
export const TS_ANALYSIS_INTERVAL_SECONDS = 10

// EDGE-4072: Sticky ingress during UI proxy:
// ensure that all communicating parties are routed
// through the same edge-api instance by setting this cookie.
export const COOKIE_APPLIANCE_PROXY = 'applianceproxy'
