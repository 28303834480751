import { Route } from 'react-router-dom'

import Report from './Report'

import { useRoutes } from '../../store'

export const BillingReport = () => {
  const routes = useRoutes()
  return <Route exact path={routes.billing.route} component={Report} />
}
