import MuiSelect from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

import { FilterProps } from './utils'
import { usePageParams } from '../../../utils'

/**
 * Select filter
 * @param paramName - parameter name
 * @param label - input label
 * @param options - options to select from
 */
const Select = ({ paramName, label, options = [] }: FilterProps) => {
  const [params, setPageParams] = usePageParams()
  const value = params[paramName] || ''

  return (
    <FormControl variant="outlined" style={{ minWidth: '150px' }}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        autoWidth
        value={value}
        onChange={({ target }) => setPageParams({ [paramName]: String(target.value) || undefined, pageNumber: '0' })}
        label={label}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map(({ name, value }) => (
          <MenuItem value={value} key={`menu-item-${value}`}>
            {name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
