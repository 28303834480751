import {
  AllocateApplianceRequest,
  CoreVideoApplianceAllocation,
  ListResult,
  PhysicalPort,
  SharedPort,
} from 'common/api/v1/types'
import { IPortsApi } from './api'
import { MOCK_APPLIANCES, MOCK_DELAY_MS, MOCK_GROUPS, MOCK_PORTS, MOCK_REGIONS } from '../MockData'
import { listResult } from '../../utils'
import { EnrichedPhysicalPort } from '../nm-types'
import * as uuid from 'uuid'

export class MockPortsApi implements IPortsApi {
  updatePort(): Promise<Array<SharedPort>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        return resolve(MOCK_APPLIANCES[0].physicalPorts.map(({ id: port, owner }) => ({ port, owner, networks: [] })))
      }, MOCK_DELAY_MS),
    )
  }
  getPort(): Promise<EnrichedPhysicalPort> {
    return new Promise((resolve) =>
      setTimeout(() => resolve({ ...MOCK_PORTS[0], _owner: MOCK_GROUPS[0] }), MOCK_DELAY_MS),
    )
  }
  getPorts(): Promise<ListResult<EnrichedPhysicalPort>> {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve(
            listResult(
              MOCK_PORTS.map((port) => ({
                ...port,
                _owner: MOCK_GROUPS[0],
                _appliance: {
                  ...MOCK_APPLIANCES[0],
                  _owner: MOCK_GROUPS[0],
                },
              })),
            ),
          ),
        MOCK_DELAY_MS,
      ),
    )
  }
  getBarePorts(): Promise<ListResult<PhysicalPort>> {
    return new Promise((resolve) => setTimeout(() => resolve(listResult(MOCK_PORTS)), MOCK_DELAY_MS))
  }

  allocateCoreVideoAppliance(
    _params: AllocateApplianceRequest,
    options?: Parameters<IPortsApi['allocateCoreVideoAppliance']>[1],
  ): Promise<CoreVideoApplianceAllocation> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        if (options?.signal?.aborted) return reject(new DOMException('This operation was aborted', 'AbortError'))

        return resolve({
          id: uuid.v4(),
          appliance: { ...MOCK_PORTS[0].appliance, region: MOCK_REGIONS[0].id },
          createdAt: new Date(),
          expiresAt: new Date(),
        })
      }, MOCK_DELAY_MS),
    )
  }
}
