import { Route } from 'react-router-dom'

import Report from './Report'

import { useRoutes } from '../../store'

export const UsageReport = () => {
  const routes = useRoutes()
  return <Route exact path={routes.usage.route} component={Report} />
}
