import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import { Theme } from '@mui/material/styles'

const styles = {
  layout: (theme: Theme) => ({
    position: 'relative' as const,
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    outline: 0,
    [theme.breakpoints.up(400 + parseInt(theme.spacing(3 * 2).slice(0, -2)))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
  progressBox: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 0,
  },
  progress: {
    margin: (theme: Theme) => theme.spacing(2),
    outline: 0,
  },
}

interface Iprops {
  loading: boolean
}

/**
 * Loading spinner for login page
 * @param loading - whether it is shown
 */
export const Loading = ({ loading = false }: Iprops) => {
  return (
    <Modal open={loading} sx={styles.layout}>
      <Box sx={styles.progressBox}>
        <CircularProgress sx={styles.progress} />
      </Box>
    </Modal>
  )
}
