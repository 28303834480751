import { get } from 'lodash/fp'

import {
  removeInput,
  clearInputs,
  removeInputs,
  draftInputs,
  updateInputRecipients,
  updateInputDistribution,
  getInputs,
  getInput,
  clearInput,
  createInput,
  updateInput,
  Draft,
  registerInputObserver,
  unregisterInputObserver,
  getBareInputs,
  enableInputs,
  disableInputs,
  rerouteInput,
} from '../actions/inputsActions'
import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import { EnrichedInput, EnrichedInputWithPorts } from '../../api/nm-types'
import { createSlice } from '@reduxjs/toolkit'
import { Input } from 'common/api/v1/types'
import { createLoadingReducer } from './shared'

interface State {
  inputs: Array<EnrichedInput>
  total: number
  input?: EnrichedInputWithPorts
  loading: boolean
  saving?: boolean
  rerouting: boolean
  dialogSaving: boolean
  draft: Draft
  formErrors?: Array<{ name: string; reason: string }>
  inputsToObserve: { [inputId: string]: number }
  observedInputs: Array<Input>
}

const initialStateInputs: State = {
  inputs: [],
  total: 0,
  input: undefined,
  loading: false,
  rerouting: false,
  dialogSaving: false,
  draft: { inputs: [] },
  inputsToObserve: {},
  observedInputs: [],
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getInputs, removeInput, removeInputs)

const inputsSlice = createSlice({
  name: 'inputs',
  initialState: initialStateInputs,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getInputs.fulfilled,
        (state, { payload: { items: inputs, total } }): State => ({
          ...state,
          inputs,
          total,
        }),
      )
      .addCase(getInput.fulfilled, (state, { payload: input }): State => ({ ...state, input }))
      .addCase(clearInput, (state): State => ({ ...state, input: undefined, formErrors: undefined }))
      .addCase(
        draftInputs,
        (state, { payload: draft }): State => ({
          ...state,
          draft,
        }),
      )
      .addCase(clearInputs, (state): State => ({ ...state, inputs: [], draft: { inputs: [] } }))
      .addCase(logoutUser.fulfilled, (): State => initialStateInputs)
      .addCase(
        registerInputObserver.pending,
        (
          state,
          {
            meta: {
              arg: { inputId },
            },
          },
        ): State => ({
          ...state,
          inputsToObserve: {
            ...state.inputsToObserve,
            [inputId]: (state.inputsToObserve[inputId] || 0) + 1,
          },
        }),
      )
      .addCase(unregisterInputObserver, (state, { payload: { inputId } }): State => {
        const newObservers = { ...state.inputsToObserve }
        const numberOfObservers = state.inputsToObserve[inputId] - 1
        if (numberOfObservers < 1) {
          delete newObservers[inputId]
        } else {
          newObservers[inputId] = numberOfObservers
        }

        const observedInputIds = Object.keys(newObservers)
        return {
          ...state,
          inputsToObserve: newObservers,
          observedInputs: state.observedInputs.filter((i) => observedInputIds.includes(i.id)),
        }
      })
      .addCase(getBareInputs.fulfilled, (state, { payload: bareInputs }): State => {
        const updateInputMetricsAndTsInfo = <T extends Input>(enrichedInput: T): T => {
          const bareInput = bareInputs.items.find((i) => i.id === enrichedInput.id)
          return bareInput
            ? { ...enrichedInput, metrics: bareInput.metrics, health: bareInput.health, tsInfo: bareInput.tsInfo }
            : enrichedInput
        }

        const observedInputIds = Object.keys(state.inputsToObserve)
        const observedInputs: Input[] = observedInputIds
          .map((id) => bareInputs.items.find((i) => i.id === id) as Input)
          .filter(Boolean)

        return {
          ...state,
          inputs: state.inputs.map(updateInputMetricsAndTsInfo),
          input: state.input ? updateInputMetricsAndTsInfo(state.input) : undefined,
          observedInputs,
        }
      })
      .addCase(enableInputs.fulfilled, (state, { payload: enableInputs }): State => {
        return {
          ...state,
          inputs: state.inputs.map((input) => {
            const enabledInput = enableInputs.find((i) => i.id === input.id)
            if (enabledInput) {
              return { ...input, adminStatus: enabledInput.adminStatus }
            }
            return input
          }),
        }
      })
      .addCase(disableInputs.fulfilled, (state, { payload: disableInputs }): State => {
        return {
          ...state,
          inputs: state.inputs.map((input) => {
            const disabledInput = disableInputs.find((i) => i.id === input.id)
            if (disabledInput) {
              return { ...input, adminStatus: disabledInput.adminStatus }
            }
            return input
          }),
        }
      })
      .addMatcher(
        isOneOf([
          updateInputDistribution.fulfilled,
          updateInputDistribution.rejected,
          updateInputRecipients.fulfilled,
          updateInputRecipients.rejected,
        ]),
        (state): State => ({ ...state, dialogSaving: false }),
      )
      .addMatcher(
        isOneOf([getInputs.pending, removeInput.pending, removeInputs.pending]),
        (state): State => ({ ...state, formErrors: undefined }),
      )
      .addMatcher(
        isOneOf([createInput.pending, updateInput.pending]),
        (state): State => ({ ...state, saving: true, formErrors: undefined }),
      )
      .addMatcher(
        isOneOf([updateInputDistribution.pending, updateInputRecipients.pending]),
        (state): State => ({ ...state, dialogSaving: true }),
      )
      .addMatcher(isOneOf([createInput.pending, updateInput.pending]), (state) => ({
        ...state,
        saving: true,
        formErrors: undefined,
      }))
      .addMatcher(isOneOf([rerouteInput.pending]), (state) => ({
        ...state,
        rerouting: true,
      }))
      .addMatcher(isOneOf([rerouteInput.fulfilled, rerouteInput.rejected]), (state) => ({
        ...state,
        rerouting: false,
      }))
      .addMatcher(isOneOf([createInput.fulfilled, updateInput.fulfilled]), (state) => ({
        ...state,
        saving: undefined,
      }))
      .addMatcher(isOneOf([createInput.rejected, updateInput.rejected]), (state, payload) => ({
        ...state,
        saving: false,
        formErrors: get('errorInfo.origin.data.detail', payload),
      }))
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default inputsSlice.reducer
