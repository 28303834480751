import { createSlice } from '@reduxjs/toolkit'
import { EnhancedInputConfig } from 'common/api/v1/internal'
import { readGraph } from '../actions/graphActions'
import { createLoadingReducer } from './shared'

interface State {
  graph?: EnhancedInputConfig
  loading: boolean
}

const initialState: State = {
  graph: undefined,
  loading: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(readGraph)

const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(readGraph.fulfilled, (state, { payload }) => ({
        ...state,
        graph: payload,
      }))
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default graphSlice.reducer
