import React, { CSSProperties } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom'
import cn from 'classnames'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

const classes = {
  link: ({ underline }: { underline: MuiLinkProps['underline'] }) => ({
    textDecoration: underline === 'always' ? 'underline' : 'none',
    '&:hover': {
      color: 'inherit',
      textDecoration: underline === 'none' ? 'none' : 'underline',
    },
  }),
}

interface Props {
  underline?: MuiLinkProps['underline']
  muiStyle?: CSSProperties
  available?: boolean
}

interface LinkProps extends RouterLinkProps, Props {}

/**
 * Common link component
 * @param underline - when we should show the underline ('none' | 'hover' | 'always')
 * @param children - content of the link
 * @param muiStyle - css properties for the MaterialUI link component
 * @param rest - other properties (for Router link component)
 * @param available - whether we can edit the entity
 * @param to - url to link to
 * @constructor
 */
export const Link: React.FunctionComponent<LinkProps> = ({
  underline = 'none',
  children,
  muiStyle,
  style,
  available = true,
  to,
  ...rest
}) => {
  if (!available || to === '') return <span>{children}</span>
  return (
    <RouterLink {...rest} to={to} style={{ ...classes.link({ underline }), ...(style || {}) }}>
      <MuiLink underline={underline} component="span" style={muiStyle}>
        {children}
      </MuiLink>
    </RouterLink>
  )
}

/**
 * External link component
 */
export const ExternalLink: React.FunctionComponent<MuiLinkProps> = ({
  underline = 'none',
  children,
  className,
  ...rest
}) => {
  return (
    <MuiLink
      rel="noopener noreferrer"
      target="_blank"
      underline={underline}
      sx={classes.link({ underline })}
      className={cn(className)}
      {...rest}
    >
      {children}
    </MuiLink>
  )
}

interface NavLinkProps extends RouterNavLinkProps, Props {}
/**
 * Link for sidebar
 */
export const NavLink: React.FunctionComponent<NavLinkProps> = ({ underline = 'none', children, muiStyle, ...rest }) => {
  return (
    <RouterNavLink {...rest} style={classes.link({ underline })}>
      <MuiLink underline={underline} component="span" style={muiStyle}>
        {children}
      </MuiLink>
    </RouterNavLink>
  )
}
