import { IOutputListApi } from './api'

import { ListResult, Output, OutputRecipientList } from 'common/api/v1/types'

import { listResult } from '../../utils'
import { MOCK_DELAY_MS, MOCK_OUTPUT_LISTS, MOCK_OUTPUTS } from '../MockData'

export class MockOutputListApi implements IOutputListApi {
  createOutputList(): Promise<OutputRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_OUTPUT_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  updateOutputList(): Promise<OutputRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_OUTPUT_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  removeOutputList(): Promise<OutputRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_OUTPUT_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  getOutputList(): Promise<OutputRecipientList> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(MOCK_OUTPUT_LISTS[0])
      }, MOCK_DELAY_MS),
    )
  }
  async getOutputLists(): Promise<ListResult<OutputRecipientList>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(listResult(MOCK_OUTPUT_LISTS))
      }, MOCK_DELAY_MS),
    )
  }

  getOutputs(): Promise<ListResult<Output>> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(listResult(MOCK_OUTPUTS))
      }, MOCK_DELAY_MS),
    )
  }
}
