import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { ListResult } from 'common/api/v1/types'
import Pendable from '../common/Pendable'
import { Pagination, PaginationProps } from './Table'
import SearchInput from '../common/SearchInput'
import { DEFAULT_PAGINATION, useSelfStatePagination } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'
import Divider from '@mui/material/Divider'

const styles = {
  empty: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface PaginatedListProps<TParams extends PaginatedRequestParams, TEntity, TOthers> {
  api: (params: TParams) => Promise<ListResult<TEntity>>
  emptyMessage: string
  hideSearch?: boolean
  notFoundMessage: string
  onListChange?: (values: Partial<ListResult<TEntity> & { loading: boolean } & TParams>) => void
  otherParams?: TOthers
  searchPlaceholder?: string
  updateOn?: boolean
  Header?: React.ReactNode
  List: (props: { list: Array<TEntity> }) => React.ReactNode
  paginationProps?: Pick<PaginationProps, 'hidePerPageOption' | 'hideJumpToPage'>
}

/**
 * We use it to show paginated list of entities if we don't put it in the Redux store but rather have component's own state
 * For example we use it in modals
 * @param api - the api to get the list of entities
 * @param emptyMessage - if the list is empty
 * @param hideSearch - if we don't want to have filter search input on top of the list
 * @param notFoundMessage - if search with filter returned no matching entities
 * @param onListChange - callback to call if the list is changed (mostly we filtered)
 * @param otherParams - other params to call the api with (not pagination parameters)
 * @param searchPlaceholder - the placeholder to show inside empty search string
 * @param updateOn - forces to refetch on its change
 * @param Header - a component to display as a table header
 * @param List - the list component to show the entities (with single parameter 'list': Array<TEntity>)
 * @param paginationProps - configures the Pagination component
 * @constructor
 */
const PaginatedList = <
  TParams extends PaginatedRequestParams,
  TEntity,
  TOthers = Partial<Omit<TParams, keyof PaginatedRequestParams>>,
>({
  api,
  emptyMessage,
  hideSearch,
  notFoundMessage,
  onListChange,
  otherParams,
  searchPlaceholder,
  updateOn,
  Header,
  List,
  paginationProps,
}: PaginatedListProps<TParams, TEntity, TOthers>) => {
  const [params, setParams] = useState<TParams>({
    pageNumber: DEFAULT_PAGINATION.pageNumber,
    rowsPerPage: DEFAULT_PAGINATION.rowsPerPage,
    filter: '',
    ...otherParams,
  } as TParams)

  const { loading, items, total } = useSelfStatePagination({ params, api, updateOn })

  useEffect(() => {
    if (onListChange) {
      onListChange({ loading, items, total, ...params } as Partial<
        ListResult<TEntity> & { loading: boolean } & TParams
      >)
    }
  }, [loading, total])

  if (!loading && !total && !params.filter)
    return (
      <Typography align="center" variant="body1" sx={styles.empty} color="textSecondary">
        {emptyMessage}
      </Typography>
    )

  const hasHeaderContent = Header || !hideSearch
  return (
    <Pendable pending={loading} cover id="self-state-pending">
      {hasHeaderContent && (
        <Box
          sx={{
            top: 0,
            zIndex: 1,
            paddingTop: 2,
            position: 'sticky',
            backgroundColor: 'background.paper',
          }}
          data-testid="paginated-list-header"
        >
          {Header}
          {!hideSearch && (
            <SearchInput
              initialValue={params.filter}
              placeholder={searchPlaceholder || 'Search…'}
              onChange={(input) => setParams({ ...params, filter: input, pageNumber: '0' })}
            />
          )}
          <Divider sx={{ marginTop: 2 }} />
        </Box>
      )}
      {total || loading ? (
        List({ list: items })
      ) : (
        <Typography align="center" variant="body1" sx={styles.empty} color="textSecondary">
          {notFoundMessage}
        </Typography>
      )}
      <Box
        sx={{
          position: 'sticky',
          bottom: -1, // -1 instead of 0 to make it look good in Dialogs (else the list was visible behind the pagination)
          backgroundColor: 'background.paper',
        }}
      >
        <Divider />
        <Pagination
          total={total}
          page={params.pageNumber}
          perPage={params.rowsPerPage}
          changePageCallback={(pageNumber: string) => setParams({ ...params, pageNumber })}
          changeRowsPerPageCallback={(rowsPerPage: string) => setParams({ ...params, rowsPerPage, pageNumber: '0' })}
          hideJumpToPage={paginationProps?.hideJumpToPage}
          hidePerPageOption={paginationProps?.hidePerPageOption}
        />
      </Box>
    </Pendable>
  )
}

export default PaginatedList
