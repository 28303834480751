import { ConnectitTokens, EdgeProduct } from 'common/api/v1/types'
import { ITokenApi } from '../../api/tokens/api'
import { REACT_APP_EDGE_PRODUCT } from '../../env'
import { AppDispatch } from '../../store'
import { enqueueErrorSnackbar } from './notificationActions'

export const getConnectitTokens = async (
  params: object,
  { dispatch, tokenApi }: { dispatch: AppDispatch; tokenApi: ITokenApi },
): Promise<ConnectitTokens | null> => {
  try {
    if (REACT_APP_EDGE_PRODUCT === EdgeProduct.edge) {
      return null
    }
    return await tokenApi.getConnectitTokens(params)
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch tokens' }))
    throw err
  }
}
