import type {
  ListOutputSortableField,
  ListResult,
  Output,
  OutputRecipientList,
  OutputRecipientListFilter,
  OutputRecipientListInit,
  OutputRecipientListSortableField,
  OutputRecipientListUpdate,
} from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import {
  EnrichedOutputRecipientList,
  PaginatedRequestParams,
  singleSortQueryFromPaginatedRequestParams,
} from '../nm-types'

export interface IOutputListApi {
  createOutputList(outputList: OutputRecipientListInit): Promise<OutputRecipientList>
  getOutputs(
    listId: string,
    inList: boolean,
    params: PaginatedRequestParams & { group?: string },
  ): Promise<ListResult<Output>>
  getOutputList(id: OutputRecipientList['id']): Promise<EnrichedOutputRecipientList>
  getOutputLists(
    params: PaginatedRequestParams<OutputRecipientListSortableField> & OutputRecipientListFilter,
  ): Promise<ListResult<EnrichedOutputRecipientList>>
  removeOutputList(id: OutputRecipientList['id']): Promise<OutputRecipientList>
  updateOutputList(id: OutputRecipientList['id'], update: OutputRecipientListUpdate): Promise<OutputRecipientList>
}

export class OutputListApi implements IOutputListApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  createOutputList(outputList: OutputRecipientListInit): Promise<OutputRecipientList> {
    return this.edgeClient.createOutputRecipientList(outputList)
  }
  updateOutputList(id: OutputRecipientList['id'], update: OutputRecipientListUpdate): Promise<OutputRecipientList> {
    return this.edgeClient.updateOutputRecipientList(id, update)
  }
  removeOutputList(id: OutputRecipientList['id']): Promise<OutputRecipientList> {
    return this.edgeClient.deleteOutputRecipientList(id)
  }
  async getOutputList(id: OutputRecipientList['id']): Promise<EnrichedOutputRecipientList> {
    const outputList = await this.edgeClient.getOutputRecipientList(id)
    const group = await this.edgeClient.getGroup(outputList.group)
    return {
      ...outputList,
      _group: group,
    }
  }
  async getOutputLists(
    params: PaginatedRequestParams<OutputRecipientListSortableField> & OutputRecipientListFilter,
  ): Promise<ListResult<EnrichedOutputRecipientList>> {
    const filter: OutputRecipientListFilter = { searchName: params.filter, group: params.group }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { total, items } = await this.edgeClient.listOutputRecipientLists(query)

    const groupIds = Array.from(new Set(items.filter((outputList) => outputList.group).map((output) => output.group)))
    const groups = groupIds.length > 0 ? (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items : []

    return {
      items: items.map((output) => ({
        ...output,
        _group: output.group ? groups.find(({ id }) => id === output.group) : undefined,
      })),
      total,
    }
  }

  /**
   * Returns outputs for this outputList
   * @param listId - groupList id
   * @param inList - true to show those outputs that are already in list, false - for others
   * @param params - pagination parameters
   * @param group - we can only add own group's outputs to output lists
   * @param searchName - term to search for
   */
  getOutputs(
    listId: string,
    inList: boolean,
    { group, filter: searchName, ...params }: PaginatedRequestParams<ListOutputSortableField> & { group?: string },
  ): Promise<ListResult<Output>> {
    const filter: OutputRecipientListFilter = { group, searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return inList
      ? this.edgeClient.listOutputRecipientListOutputs(listId, query)
      : this.edgeClient.listOutputRecipientListOutputsNotInLists(listId, query)
  }
}
