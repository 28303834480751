import { Route } from 'react-router-dom'

import { Graph } from './Graph'

import { useRoutes } from '../../store'

export const Graphs = () => {
  const routes = useRoutes()
  return (
    <>
      <Route exact path={routes.graph.route} component={Graph} />
    </>
  )
}
