import type { MaxBitrates } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IBitratesApi {
  readBitrates(): Promise<MaxBitrates>
}

export class BitratesApi implements IBitratesApi {
  constructor(private readonly edgeClient: EdgeClient) {}
  readBitrates(): Promise<MaxBitrates> {
    return this.edgeClient.getDashboardBitrates()
  }
}
