import { FormikProps } from 'formik'

import {
  Address,
  ApplianceType,
  Input,
  InputAdminStatus,
  IpPortMode,
  OccupiedPort,
  RistProfile,
} from 'common/api/v1/types'

import { Select } from '../../../../common/Form'
import { createDefaultFiledValues, isCoreNode, makeAddressOptions } from '../../../../../utils'
import { get } from 'lodash'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { WhitelistCidrBlockAutoComplete } from '../../../../common/WhitelistCidrBlockAutoComplete'
import { SuggestedLocalPortTextField } from '../../../../common/SuggestedPortTextField'

export enum RistFields {
  profile = 'profile',
  address = 'address',
  port = 'port',
  whitelistCidrBlock = 'whitelistCidrBlock',
}
export const ristDefaults = () =>
  createDefaultFiledValues(Object.keys(RistFields), [], {
    [RistFields.whitelistCidrBlock]: [],
  })

interface RistFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  applianceType: ApplianceType
}

const RistForm = ({ form, addresses, namePrefix, occupiedPorts, adminStatus, applianceType }: RistFormProps) => {
  const addressSelector = `${namePrefix}.${RistFields.address}`
  return (
    <>
      <Select name={`${namePrefix}.${RistFields.profile}`} label="Profile" options={[RistProfile.simple]} required />
      <Select
        name={addressSelector}
        label="Address"
        required
        options={makeAddressOptions(get(form.values, addressSelector), addresses)}
        newLine
        validators={{
          addressIn: { addresses },
        }}
      />
      <SuggestedLocalPortTextField
        name={`${namePrefix}.${RistFields.port}`}
        label={'Port'}
        namePrefix={namePrefix}
        required={true}
        portInfo={{
          mode: IpPortMode.rist,
          isInput: true,
          existingLogicalPortsOnSameNic: occupiedPorts,
          isPortDisabled: adminStatus === InputAdminStatus.off,
        }}
      />

      {!isVaApplianceType(applianceType) && (
        <WhitelistCidrBlockAutoComplete
          name={`${namePrefix}.${RistFields.whitelistCidrBlock}`}
          required={isCoreNode(applianceType)}
        />
      )}
    </>
  )
}

export default RistForm
