import { ImageName, ImageDetails, UpdateImageRequest } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IImageApi {
  getImageDetails(name: ImageName): Promise<ImageDetails>
  setImage(data: UpdateImageRequest): Promise<string>
  deleteImage(name: ImageName): Promise<string>
}

export class ImageApi implements IImageApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getImageDetails(name: ImageName): Promise<ImageDetails> {
    return this.edgeClient.getImage(name)
  }

  setImage(data: UpdateImageRequest): Promise<string> {
    return this.edgeClient.createImage(data)
  }
  deleteImage(name: ImageName): Promise<string> {
    return this.edgeClient.deleteImage(name)
  }
}
