import { Theme } from '@mui/material/styles'

export const SIDE_PADDING = 1

export const styles = {
  itemStyle: {
    background: (theme: Theme) => theme.palette.background.default,
    padding: (theme: Theme) => theme.spacing(1, SIDE_PADDING),
    border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
    transition: 'background .2s linear',
  },
  itemHovered: {
    background: (theme: Theme) => theme.palette.grey[300],
  },
}
