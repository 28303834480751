import { FormikProps } from 'formik'
import { get } from 'lodash'
import {
  Address,
  ApplianceFeatures,
  ApplianceType,
  ApplianceVersion,
  Input,
  IpPortMode,
  ZixiDecryptType,
  ZixiInputPort,
  ZixiMode,
} from 'common/api/v1/types'
import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { supportsZixiAdaptiveFec } from 'common/versions'
import { isVaApplianceType } from 'common/applianceTypeUtil'

export enum ZixiFields {
  decryptKey = 'decryptKey',
  decryptType = 'decryptType',
  fecLatency = 'fecLatency',
  localIp = 'localIp',
  maxFecOverhead = 'maxFecOverhead',
  optimizeFec = 'optimizeFec',
  adaptiveFec = 'adaptiveFec',
  password = 'password',
  pullPort = 'pullPort',
  reducedBitrateDetection = 'reducedBitrateDetection',
  reducedBitrateThreshold = 'reducedBitrateThreshold',
  remotePrimaryIp = 'remotePrimaryIp',
  remoteSecondaryIp = 'remoteSecondaryIp',
  retransmitBuf = 'retransmitBuf',
  streamId = 'streamId',
  unrecoveredPacketsDetection = 'unrecoveredPacketsDetection',
  unrecoveredPacketsThreshold = 'unrecoveredPacketsThreshold',
  zixiMode = 'zixiMode',
}
export const zixiDefaults = () =>
  createDefaultFiledValues(
    Object.keys(ZixiFields),
    [
      ZixiFields.unrecoveredPacketsDetection,
      ZixiFields.reducedBitrateDetection,
      ZixiFields.optimizeFec,
      ZixiFields.adaptiveFec,
    ],
    {
      [ZixiFields.retransmitBuf]: 500,
      [ZixiFields.fecLatency]: 30,
      [ZixiFields.pullPort]: 2077,
      [ZixiFields.decryptType]: ZixiDecryptType.none,
    },
  )
export const getZixiFieldsToSave = (port: ZixiInputPort) => [
  ZixiFields.zixiMode,
  ZixiFields.decryptType,
  ZixiFields.decryptKey,
  ZixiFields.streamId,
  ZixiFields.password,
  ZixiFields.reducedBitrateDetection,
  ZixiFields.unrecoveredPacketsDetection,
  ...(port.reducedBitrateDetection ? [ZixiFields.reducedBitrateThreshold] : []),
  ...(port.unrecoveredPacketsDetection ? [ZixiFields.unrecoveredPacketsThreshold] : []),
  ...(port.zixiMode === ZixiMode.pull
    ? [
        ZixiFields.fecLatency,
        ZixiFields.localIp,
        ZixiFields.maxFecOverhead,
        ZixiFields.optimizeFec,
        ZixiFields.adaptiveFec,
        ZixiFields.pullPort,
        ZixiFields.remotePrimaryIp,
        ZixiFields.remoteSecondaryIp,
        ZixiFields.retransmitBuf,
      ]
    : []),
]

interface ZixiFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  applianceFeatures: ApplianceFeatures
  applianceVersion: ApplianceVersion
}
const ZixiForm = ({
  form,
  addresses,
  namePrefix,
  applianceType,
  applianceFeatures,
  applianceVersion,
}: ZixiFormProps) => {
  const port = get(form.values, namePrefix) as ZixiInputPort
  const isVa = isVaApplianceType(applianceType)
  const localAddressSelector = `${namePrefix}.${ZixiFields.localIp}`
  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [
    { name: 'any', value: '' },
    ...makeAddressOptions(get(form.values, localAddressSelector), addresses),
  ]

  const supportedModes = applianceFeatures.input?.modes.find((m) => m.mode === IpPortMode.zixi)?.subModes ?? []
  const isAdaptiveFecSupported = supportsZixiAdaptiveFec(applianceType, applianceVersion)
  return (
    <>
      <Select
        name={`${namePrefix}.${ZixiFields.zixiMode}`}
        label="Connection mode"
        options={supportedModes}
        required
        tooltip='Use "nimbrava" as remote id'
      />
      <Select
        name={`${namePrefix}.${ZixiFields.decryptType}`}
        label="Decryption type"
        required
        options={Object.values(ZixiDecryptType)}
        newLine
      />
      <TextInput
        name={`${namePrefix}.${ZixiFields.decryptKey}`}
        label="Decryption key"
        multiline
        required={port.decryptType !== ZixiDecryptType.none}
        validators={{
          hexadecimal: {},
        }}
      />
      <TextInput name={`${namePrefix}.${ZixiFields.streamId}`} label="Stream id" required newLine />
      <TextInput name={`${namePrefix}.${ZixiFields.password}`} label="Password" />

      {port.zixiMode === ZixiMode.pull && (
        <>
          <Select
            name={localAddressSelector}
            label="Local address"
            options={addressOptions}
            newLine
            validators={{
              addressIn: { addresses: addressesAndEmptyAddress },
            }}
          />

          <TextInput
            name={`${namePrefix}.${ZixiFields.remotePrimaryIp}`}
            label="Remote host (primary)"
            required
            validators={{ ipOrHostname: {} }}
          />
          <TextInput
            name={`${namePrefix}.${ZixiFields.remoteSecondaryIp}`}
            label="Remote host (secondary)"
            validators={{ ipOrHostname: {} }}
          />
          <TextInput
            name={`${namePrefix}.${ZixiFields.pullPort}`}
            label="Remote UDP port"
            required
            type="number"
            validators={{ port: { isUdp: true } }}
          />
          <TextInput
            name={`${namePrefix}.${ZixiFields.retransmitBuf}`}
            label="Retransmission buffer (ms)"
            type="number"
            noNegative
            newLine
            required
            validators={{
              number: {
                lessThanOrEqualTo: 30000,
                message: 'Must be no more than 30000',
              },
            }}
          />
          {isVa && (
            <TextInput
              name={`${namePrefix}.${ZixiFields.maxFecOverhead}`}
              label="Max fec overhead (%)"
              type="number"
              noNegative
              validators={{
                number: {
                  lessThanOrEqualTo: 100,
                  message: 'Must be no more than 100',
                },
              }}
            />
          )}
          {isVa && (
            <TextInput
              name={`${namePrefix}.${ZixiFields.fecLatency}`}
              label="Fec latency (ms)"
              type="number"
              noNegative
              validators={{
                number: {
                  lessThanOrEqualTo: Math.pow(2, 32) - 1,
                  message: `Must be no more than ${Math.pow(2, 32) - 1}`,
                },
              }}
            />
          )}
          {isAdaptiveFecSupported && (
            <Checkbox
              name={`${namePrefix}.${ZixiFields.adaptiveFec}`}
              label="Adaptive fec"
              tooltip={'Enables FEC congestion avoidance'}
            />
          )}
          {!isAdaptiveFecSupported && isVa && (
            <Checkbox name={`${namePrefix}.${ZixiFields.optimizeFec}`} label="Optimize fec" />
          )}
        </>
      )}

      {isVa && (
        <>
          <Checkbox
            name={`${namePrefix}.${ZixiFields.reducedBitrateDetection}`}
            label="Reduced bitrate alarm"
            newLine
            tooltip="Raise alarm when bitrate is below or equal (kbps)."
          />
          {port.reducedBitrateDetection && (
            <TextInput
              name={`${namePrefix}.${ZixiFields.reducedBitrateThreshold}`}
              label="Bitrate (kbps)"
              type="number"
              noNegative
              validators={{
                number: {
                  lessThanOrEqualTo: Math.pow(2, 64),
                  message: `Must be no more than ${Math.pow(2, 64)}`,
                },
              }}
            />
          )}
          <Checkbox
            name={`${namePrefix}.${ZixiFields.unrecoveredPacketsDetection}`}
            label="Unrecovered packets alarm"
            tooltip="Turn detection of unrecovered packets on or off, including associated alarm. Threshold is in packets per minute. Generates an alarm if unrecovered packets per minute is higher than threshold."
            newLine
          />
          {port.unrecoveredPacketsDetection && (
            <TextInput
              name={`${namePrefix}.${ZixiFields.unrecoveredPacketsThreshold}`}
              label="Threshold (packets/min)"
              type="number"
              noNegative
              validators={{
                number: {
                  lessThanOrEqualTo: Math.pow(2, 64),
                  message: `Must be no more than ${Math.pow(2, 64)}`,
                },
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default ZixiForm
