import { Paper, Select } from '../../../../common/Form'
import {
  MatroxDecoderApplianceSettings,
  MatroxDecoderFramerateFamily,
  MatroxDecoderGenlockSource,
} from 'common/api/v1/types'

interface Props {
  settings: MatroxDecoderApplianceSettings
}

const sourceOptions = Object.values(MatroxDecoderGenlockSource)
const framerateFamilyOptions = Object.values(MatroxDecoderFramerateFamily)

const MatroxDecoderApplianceSettingsComp = (_: Props) => {
  return (
    <Paper title="Matrox Settings">
      <Paper title="Genlock">
        <Select label="Source" name={`settings.genlock.source`} required options={sourceOptions} />
        <Select
          label="Framerate Family"
          name={`settings.genlock.framerateFamily`}
          required
          options={framerateFamilyOptions}
        />
      </Paper>
    </Paper>
  )
}

export default MatroxDecoderApplianceSettingsComp
