import { AlarmLogFilter, AlarmLog, ListResult } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { AlarmLogRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IAlarmLogsApi {
  getAlarmLogs(params: AlarmLogRequestParams): Promise<ListResult<AlarmLog>>
}

export class AlarmLogsApi implements IAlarmLogsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getAlarmLogs({
    fromDate,
    toDate,
    severity,
    searchName,
    ...params
  }: AlarmLogRequestParams): Promise<ListResult<AlarmLog>> {
    const filter: AlarmLogFilter = {
      fromDate: fromDate as unknown as Date,
      toDate: toDate as unknown as Date,
      severity,
      searchName,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listAlarmLogs(query)
  }
}
