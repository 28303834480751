import { IpMappingFilter, IpMappingRead, IpMappingWrite } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { IpMappingsRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface MappingKey {
  privateIp: string
  region: string
}
export interface IAddressMappingApi {
  setIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead[]>
  updateIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead>
  getIpMappings(params: IpMappingsRequestParams): Promise<IpMappingRead[]>
  getIpMapping(mappingKey: MappingKey): Promise<IpMappingRead>
  removeIpMapping(ipMapping: MappingKey): Promise<IpMappingRead[]>
}

export class AddressMappingApi implements IAddressMappingApi {
  constructor(private readonly edgeClient: EdgeClient) {}
  async getIpMapping({ privateIp, region }: { privateIp: string; region: string }): Promise<IpMappingRead> {
    return this.edgeClient.getIpMapping(region, privateIp)
  }
  setIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead[]> {
    return this.edgeClient.createIpMapping(ipMapping)
  }
  updateIpMapping(ipMapping: IpMappingWrite): Promise<IpMappingRead> {
    const { region, privateIp } = ipMapping
    return this.edgeClient.updateIpMapping(region, privateIp, ipMapping)
  }
  getIpMappings({ filter, ...params }: IpMappingsRequestParams): Promise<IpMappingRead[]> {
    const searchFilter: IpMappingFilter = {}
    if (filter) searchFilter.searchName = filter
    const query = singleSortQueryFromPaginatedRequestParams({
      filter: searchFilter,
      paginatedRequestParams: params,
    })
    return this.edgeClient.listIpMappings(query)
  }

  removeIpMapping({ region, privateIp }: MappingKey): Promise<IpMappingRead[]> {
    return this.edgeClient.deleteIpMapping(region, privateIp)
  }
}
