import { useEffect } from 'react'
import { addDays, compareAsc, format } from 'date-fns'
import { AlarmCause, AlarmLog, AlarmSeverityLevels } from 'common/api/v1/types'
import { useAlarmLogsSelector, usePageParams, usePageParamsFilteredSelector } from '../../utils'
import Table, { TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import Filter, { FilterType } from '../common/Filters'
import { DATE_FORMAT_LONG } from 'common/api/v1/helpers'
import { capitalize } from '@mui/material'

interface AlarmLogListFilterProps {
  minDate: Date
  maxDate: Date
}

const AlarmLogList = () => {
  const [pageParams, setPageParams] = usePageParams()
  const { fromDate, toDate } = pageParams

  useEffect(() => {
    if (!fromDate) {
      setPageParams({ fromDate: addDays(new Date(), -1).toISOString() })
    }
  }, [fromDate])

  const minDate = fromDate ? new Date(fromDate) : new Date('1900-01-01')
  const maxDate = toDate ? new Date(toDate) : new Date()
  const validDateRange = compareAsc(minDate, maxDate) === -1

  if (!fromDate) {
    return null
  }

  return (
    <Wrapper name="Alarm history">
      <AlarmLogListFilter minDate={minDate} maxDate={maxDate} />
      {validDateRange && <AlarmLogListTable />}
    </Wrapper>
  )
}

const AlarmLogListFilter = ({ minDate, maxDate }: AlarmLogListFilterProps) => {
  return (
    <Filter
      filters={[
        {
          label: 'From date',
          paramName: 'fromDate',
          type: FilterType.datetime,
          maxDate,
          maxDateMessage: 'Cannot be after "To date"',
        },
        {
          label: 'To date',
          paramName: 'toDate',
          type: FilterType.datetime,
          minDate,
          minDateMessage: 'Cannot be before "From date"',
        },
        {
          label: 'Severity',
          paramName: 'severity',
          type: FilterType.select,
          options: Object.values(AlarmSeverityLevels).map((item) => ({ name: capitalize(item), value: item })),
        },
        {
          label: 'Search',
          paramName: 'searchName',
          type: FilterType.text,
        },
      ]}
    />
  )
}

const AlarmLogListTable = () => {
  const { alarmLogs, total, loading } = usePageParamsFilteredSelector(useAlarmLogsSelector, false)
  const [pageParams] = usePageParams()
  const { pageNumber } = pageParams

  const naText = 'N/A'
  const unicodeDownArrow = '\u2193'
  const tableConfig: TableConfig<AlarmLog> = [
    {
      title: 'Raised',
      getValue: ({ raisedAt }) => (raisedAt ? format(new Date(raisedAt), DATE_FORMAT_LONG) : naText),
    },
    {
      title: `Cleared ${unicodeDownArrow}`,
      getValue: ({ clearedAt }) => (clearedAt ? format(new Date(clearedAt), DATE_FORMAT_LONG) : naText),
    },
    {
      title: 'Appliance',
      getValue: ({ applianceName }) => applianceName || naText,
    },
    {
      title: 'Alarm',
      getValue: ({ alarmCause }) => alarmCause,
    },
    {
      title: 'Message',
      getValue: ({ text, object, objectName, repeatCount }) => {
        const isVALicenseAlarm = !!object?.startsWith('.license')
        const textDetails = isVALicenseAlarm ? ` - ${objectName}` : ''
        const repeatText = repeatCount > 0 ? ` (repeated ${repeatCount} times)` : ''
        return `${text}${textDetails}${repeatText}`
      },
    },
    {
      title: 'Severity',
      getValue: ({ alarmSeverity }) => (alarmSeverity !== 'cleared' ? capitalize(alarmSeverity) : naText),
    },
    {
      title: 'Entity',
      getValue: ({ inputId, inputName, outputId, outputName, applianceId, applianceName, alarmCause, objectName }) => {
        if (inputId) {
          return `input "${inputName || naText}"`
        }
        if (outputId) {
          return `output "${outputName || naText}"`
        }
        if (applianceId) {
          return `appliance "${applianceName || naText}"`
        }
        if (alarmCause === AlarmCause.BACKUP_JOB_FAILED) {
          return `job "${objectName || naText}"`
        }
        return ''
      },
    },
  ]

  return (
    <Table<AlarmLog>
      emptyMessageComponent={<MissingContent message="No log available for the interval" />}
      config={tableConfig}
      data={alarmLogs.map((item, idx) => ({ ...item, alarmId: `${pageNumber || 0}_${idx}${item.alarmId}` }))}
      pending={loading}
      pagination={{ total, useUrlSearchParams: true }}
    />
  )
}

export default AlarmLogList
