/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { Simulation, ForceLink } from 'd3-force'
import { Selection } from 'd3-selection'
import * as d3Force from 'd3-force'
import * as d3Drag from 'd3-drag'
import * as d3Selection from 'd3-selection'

import Wrapper from '../../common/Wrapper'
import { useInputsSelector, useOutputsSelector } from '../../../utils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GlobalState } from '../../../store'
import { GraphLink, GraphNode, createGraphNodesAndLinks } from './parse'
import { readGraph } from '../../../redux/actions/graphActions'
import { EnrichedInput, EnrichedOutput } from '../../../api/nm-types'
import { EnhancedInputConfig } from 'common/api/v1/internal'

const width = 1600
const height = 1200

let simulation: Simulation<GraphNode, GraphLink>
let svg: Selection<SVGSVGElement, any, HTMLElement, any>

function dragStarted(d: GraphNode) {
  if (!d3Selection.event.active) simulation.alphaTarget(0.3).restart()
  d.fx = Math.max(10, Math.min(width - 10, d3Selection.event.subject.x))
  d.fy = Math.max(10, Math.min(height - 10, d3Selection.event.subject.y))
  d.fixed = true
}

function onDrag(d: GraphNode) {
  d.fx = Math.max(10, Math.min(width - 10, d3Selection.event.x))
  d.fy = Math.max(10, Math.min(height - 10, d3Selection.event.y))
}

function dragEnded() {
  if (!d3Selection.event.active) simulation.alphaTarget(0)
}

function doubleClick(d: GraphNode) {
  d.fixed = false
  d.fx = null
  d.fy = null
}

export enum GraphType {
  All = 'all',
  Config = 'config',
  Tunnels = 'tunnels',
}

const onDataLoad = (
  graphInput: EnhancedInputConfig,
  inputs: EnrichedInput[],
  outputs: EnrichedOutput[],
  selectedChannelId: number,
  selectedOutputId: string,
  graphType: GraphType,
) => {
  const graph = createGraphNodesAndLinks(graphInput, inputs, outputs, selectedChannelId, selectedOutputId, graphType)

  simulation.nodes(graph.nodes).on('tick', ticked)

  simulation.force<ForceLink<GraphNode, GraphLink>>('link')?.links(graph.links)

  svg
    .append('svg')
    .selectAll('marker')
    .data(['end']) // Different link/path types can be defined here
    .enter()
    .append('svg:marker') // This section adds in the arrows
    .attr('id', String)
    .attr('viewBox', '0 -5 10 10')
    .attr('refX', 20)
    .attr('markerUnits', 'userSpaceOnUse')
    // .attr("refY", -10)
    .attr('markerWidth', 20)
    .attr('markerHeight', 20)
    .attr('orient', 'auto')
    .append('svg:path')
    .attr('d', 'M0,-5L10,0L0,5')

  const link = svg
    .append('g')
    .selectAll('line')
    .data(graph.links)
    .enter()
    .append('line')
    .attr('class', 'link')
    .attr('marker-end', 'url(#end)')
    .style('stroke', '#aaa')

  const node = svg
    .append('g')
    .attr('class', 'nodes')
    .selectAll('circle')
    .data(graph.nodes)
    .enter()
    .append('circle')
    .attr('r', 6)
    .call(d3Drag.drag<SVGCircleElement, GraphNode>().on('start', dragStarted).on('drag', onDrag).on('end', dragEnded))

  const label = svg
    .append('g')
    .attr('class', 'labels')
    .selectAll('text')
    .data(graph.nodes)
    .enter()
    .append('text')
    .attr('class', 'label')
    .text(function (d: GraphNode) {
      let name = d.name
      if (d.inputName) {
        name += `: ${d.inputName}`
      }
      if (d.outputName) {
        name += `→${d.outputName}`
      }
      return name
    })
    .attr('text-anchor', 'middle')
    .attr('font-weight', (d: GraphNode) => d.fontWeight || 'normal')

  const metadata = svg
    .append('g')
    .attr('class', 'labels')
    .selectAll('text')
    .data(graph.nodes)
    .enter()
    .append('text')
    .attr('class', 'label')
    .attr('text-anchor', 'middle')
    .style('visibility', 'hidden')

  metadata
    .selectAll('tspan')
    .data((d: GraphNode) => {
      return d && d.metadataText ? d.metadataText.split('\n') : []
    })
    .enter()
    .append('tspan')
    .text((d) => d)
    .attr('dy', -22)

  function ticked() {
    const margin = 50
    graph.nodes.forEach(function (d) {
      d.x = Math.max(margin, Math.min(width - margin, d.x || 0))
      d.y = Math.max(margin, Math.min(height - margin, d.y || 0))
    })
    link
      .attr('x1', function (d: any) {
        return d.source.x
      })
      .attr('y1', function (d: any) {
        return d.source.y
      })
      .attr('x2', function (d: any) {
        return d.target.x
      })
      .attr('y2', function (d: any) {
        return d.target.y
      })
      .attr('marker-end', function (d) {
        if (d.hide) {
          return 'url()'
        }
        return 'url(#end)'
      })
      .style('stroke', function (d) {
        if (d.hide) {
          return '#fff'
        }
        return '#aaa'
      })
      .attr('stroke-width', (d) => {
        return d.width
      })

    node
      .attr('r', 20)
      .style('fill', (d) => {
        if (d?.metadata?.type === 'va') {
          return '#fcdfff'
        }
        return '#d3d3d3'
      })
      .style('stroke', (d) => (d.fixed ? '#000000' : '#969696'))
      .style('stroke-width', (d) => (d.fixed ? '2px' : '1px'))
      .attr('cx', function (d) {
        return d.x || 0
      })
      .attr('cy', function (d) {
        return d.y || 0
      })
      .style('opacity', function (d) {
        if (!d.metadata) {
          return 0
        }
        return 1
      })
      .on('dblclick', doubleClick)

    label
      .attr('x', function (d) {
        return d.x || 0
      })
      .attr('y', function (d) {
        return d.y || 0
      })
      .style('font-size', '20px')
      .style('fill', (d) => {
        if (!d.metadata) {
          return '#000'
        }
        if (d.name === 'udp-input') {
          return '#008000'
        } else if (d.name === 'udp-output') {
          return '#006400'
        } else if (d.name && d.name.includes('tr101')) {
          return '#6495ED'
        }
        return '#4393c3'
      })
      .on('click', function (node) {
        metadata
          .filter(function (_d, i) {
            return i === node.index
          })
          .style('visibility', (d) => {
            if (d.hidden || d.hidden === undefined) {
              d.hidden = false
              return 'visible'
            } else {
              d.hidden = true
              return 'hidden'
            }
          })
      })

    metadata
      .attr('x', function (d) {
        return d.x || 0
      })
      .attr('y', function (d) {
        return d.y || 0
      })
      .style('font-size', '20px')
      .style('fill', () => {
        return '#000'
      })
      .selectAll('tspan')
      .attr('x', function (this: any) {
        return d3Selection.select(this.parentNode).attr('x')
      })
  }
}

const setupGraph = (svgRef: any) => {
  svg = d3Selection.select<SVGSVGElement, any>(svgRef)

  svg.selectAll('*').remove()

  svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white')

  simulation = d3Force
    .forceSimulation<GraphNode, GraphLink>()
    .force(
      'link',
      d3Force
        .forceLink<GraphNode, GraphLink>()
        .id(function (d) {
          return d.id.toString()
        })
        .distance(function (d) {
          return d.distance
        }),
    )
    .force('charge', d3Force.forceManyBody().strength(-300))
    .force('center', d3Force.forceCenter(width / 2, height / 2))
}

export const Graph = () => {
  const [state, setState] = useState({
    selectedChannelId: -1,
    selectedOutputId: '-1',
    graphType: GraphType.Config,
  })

  const ref = useRef<SVGSVGElement>(null)
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(readGraph())
  }, [dispatch])
  const { inputs } = useInputsSelector({ pageNumber: '0', rowsPerPage: '1000' })
  const { outputs } = useOutputsSelector({ pageNumber: '0', rowsPerPage: '1000' })

  const graph = useSelector<GlobalState, EnhancedInputConfig | undefined>(
    ({ graphReducer }) => graphReducer.graph,
    shallowEqual,
  )

  useEffect(() => {
    setupGraph(ref.current)
    if (simulation && graph && inputs.length) {
      onDataLoad(graph, inputs, outputs, state.selectedChannelId, state.selectedOutputId, state.graphType)
    }
  }, [graph, inputs.map((i) => i.id).join(','), state])

  return (
    <Wrapper name="Debug overview">
      {inputs && inputs.length && (
        <label style={{ marginRight: 10 }}>
          Input:
          <select
            value={state.selectedChannelId}
            onChange={(change) => {
              setState({
                selectedChannelId: parseInt(change.target.value),
                selectedOutputId: '-1',
                graphType: state.graphType,
              })
            }}
          >
            <option key="all" value={-1}>
              All
            </option>
            {inputs.map((input) => (
              <option key={input.id} value={input.channelId}>
                {input.name}
              </option>
            ))}
          </select>
        </label>
      )}

      {outputs && outputs.length && (
        <label style={{ marginRight: 10 }}>
          Output:
          <select
            value={state.selectedOutputId}
            onChange={(change) => {
              setState({
                selectedChannelId:
                  change.target.value === '-1'
                    ? state.selectedChannelId
                    : inputs.find(
                        (input) => input.id === outputs.find((output) => output.id === change.target.value)?.input,
                      )?.channelId || -1,
                selectedOutputId: change.target.value,
                graphType: state.graphType,
              })
            }}
          >
            <option key="all" value={-1}>
              All
            </option>
            {outputs.map((output) => (
              <option key={output.id} value={output.id}>
                {output.name}
              </option>
            ))}
          </select>
        </label>
      )}

      <label style={{ marginRight: 10 }}>
        Type:
        <select
          value={state.graphType}
          onChange={(change) => {
            setState({
              selectedChannelId: state.selectedChannelId,
              selectedOutputId: state.selectedOutputId,
              graphType: change.target.value as GraphType,
            })
          }}
        >
          {Object.entries(GraphType).map(([key, value]) => (
            <option key={key} value={value}>
              {key}
            </option>
          ))}
        </select>
      </label>

      <br />
      <svg ref={ref} style={{ height, width }} />
    </Wrapper>
  )
}
