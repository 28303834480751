import { Appliance, ApplianceConfiguration, GeoAppliance } from 'common/api/v1/types'

import {
  clearAppliance,
  clearAppliances,
  getAppliance,
  getAppliances,
  getApplianceConfig,
  getGeoAppliances,
  getTotalRegions,
  removeAppliance,
  restartAppliance,
  updateAppliance,
  unregisterApplianceObserver,
  registerApplianceObserver,
  getBareAppliances,
  recreateTunnels,
  clearCachedApplianceConfig,
} from '../actions/applianceActions'
import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import { EnrichedAppliance, EnrichedApplianceWithOwner } from '../../api/nm-types'
import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { createLoadingReducer } from './shared'

interface State {
  appliance?: EnrichedAppliance
  appliances: Array<EnrichedApplianceWithOwner>
  config?: ApplianceConfiguration
  geoAppliances: Array<GeoAppliance>
  error?: SerializedError
  loading: boolean
  restarting?: boolean
  saving?: boolean
  recreatingTunnels?: boolean
  total: number
  totalGeoAppliances: number
  totalRegions?: number
  appliancesToObserve: { [applianceId: string]: number }
}
const initialStateAppliances: State = {
  appliances: [],
  geoAppliances: [],
  loading: false,
  total: 0,
  totalGeoAppliances: 0,
  appliancesToObserve: {},
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(
  getApplianceConfig,
  clearCachedApplianceConfig,
  removeAppliance,
  getAppliance,
  getAppliances,
  getGeoAppliances,
)

const appliancesSlice = createSlice({
  name: 'appliances',
  initialState: initialStateAppliances,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAppliance.pending, (state): State => ({ ...state, saving: true }))
      .addCase(restartAppliance.pending, (state, { meta: { arg } }): State => {
        const appliances = state.appliances.map((appliance) =>
          appliance.id === arg.id ? { ...appliance, _restarting: true } : appliance,
        )
        return { ...state, appliances, restarting: true }
      })
      .addCase(recreateTunnels.pending, (state): State => ({ ...state, recreatingTunnels: true }))
      .addCase(
        getAppliances.fulfilled,
        (state, { payload: { total, items: appliances } }): State => ({ ...state, appliances, total }),
      )
      .addCase(
        getGeoAppliances.fulfilled,
        (state, { payload: { total, items: geoAppliances } }): State => ({
          ...state,
          geoAppliances,
          totalGeoAppliances: total,
        }),
      )
      .addCase(updateAppliance.fulfilled, (state): State => ({ ...state, saving: undefined }))
      .addCase(updateAppliance.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(getAppliance.fulfilled, (state, { payload: appliance }): State => ({ ...state, appliance }))
      .addCase(clearAppliance, (state): State => ({ ...state, appliance: undefined, restarting: undefined }))
      .addCase(clearAppliances, (state): State => ({ ...state, appliances: [] }))
      .addCase(getTotalRegions.fulfilled, (state, { payload: { totalRegions } }): State => ({ ...state, totalRegions }))
      .addCase(getApplianceConfig.fulfilled, (state, { payload: config }) => ({ ...state, config }))
      .addCase(logoutUser.fulfilled, (): State => initialStateAppliances)
      .addCase(
        registerApplianceObserver.pending,
        (
          state,
          {
            meta: {
              arg: { applianceId },
            },
          },
        ) => ({
          ...state,
          appliancesToObserve: {
            ...state.appliancesToObserve,
            [applianceId]: (state.appliancesToObserve[applianceId] || 0) + 1,
          },
        }),
      )
      .addCase(unregisterApplianceObserver, (state, { payload: { applianceId } }) => {
        const s = { ...state }
        s.appliancesToObserve[applianceId]--
        if (!state.appliancesToObserve[applianceId]) {
          delete s.appliancesToObserve[applianceId]
        }
      })
      .addCase(getBareAppliances.fulfilled, (state, { payload: bareAppliances }) => {
        const updateApplianceStatusAndAlarms = <T extends Appliance>(enrichedAppliance: T): T => {
          const bareAppliance = bareAppliances.items.find((i) => i.id === enrichedAppliance.id)
          if (!bareAppliance) return enrichedAppliance
          return {
            ...enrichedAppliance,
            alarms: bareAppliance.alarms,
            health: bareAppliance.health,
            version: bareAppliance.version,
            lastMessageAt: bareAppliance.lastMessageAt,
          }
        }

        return {
          ...state,
          appliances: state.appliances.map(updateApplianceStatusAndAlarms),
          appliance: state.appliance ? updateApplianceStatusAndAlarms(state.appliance) : undefined,
        }
      })
      .addMatcher(isOneOf([restartAppliance.fulfilled]), (state, { meta: { arg }, payload: appliance }): State => {
        const appliances = state.appliances.map((a) =>
          a.id === arg.id ? { ...a, version: appliance.version, _restarting: false } : a,
        )
        return { ...state, appliances, restarting: false }
      })
      .addMatcher(isOneOf([restartAppliance.rejected]), (state, { meta: { arg } }): State => {
        const appliances = state.appliances.map((a) => (a.id === arg.id ? { ...a, _restarting: false } : a))
        return { ...state, appliances, restarting: false }
      })
      .addMatcher(
        isOneOf([recreateTunnels.fulfilled, recreateTunnels.rejected]),
        (state): State => ({ ...state, recreatingTunnels: false }),
      )
      .addMatcher(
        isOneOf([removeAppliance.pending, getAppliance.pending, getAppliances.pending, getGeoAppliances.pending]),
        (state): State => ({ ...state, error: undefined }),
      )
      .addMatcher(
        isOneOf([removeAppliance.rejected, getAppliance.rejected, getAppliances.rejected, getGeoAppliances.rejected]),
        (state, { error }): State => {
          return {
            ...state,
            error,
          }
        },
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default appliancesSlice.reducer
