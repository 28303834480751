import { GetUsageReportFilter, UsageReport } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { singleSortQueryFromPaginatedRequestParams, UsageRequestParams } from '../nm-types'

export interface IUsageApi {
  readUsage(params: UsageRequestParams): Promise<UsageReport>
}

export class UsageApi implements IUsageApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  readUsage({ startDate, endDate, type, format, ...params }: UsageRequestParams): Promise<UsageReport> {
    const filter: GetUsageReportFilter = {
      startDate,
      endDate,
      type,
      format,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.getUsageReport(query)
  }
}
