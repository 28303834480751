import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { PaginatedRequestParams } from '../../api/nm-types'
import {
  ListResult,
  UpdateKubernetesNodePayload,
  KubernetesNode,
  ListKubernetesNodeSortableField,
} from 'common/api/v1/types'
import { withDefaultPagination } from '../../utils'

export const getKubernetesNodes = createAsyncThunk<
  ListResult<KubernetesNode>,
  Partial<PaginatedRequestParams<ListKubernetesNodeSortableField>>,
  ThunkApi
>('kubernetes/getNodes', async (params, { dispatch, extra: { api } }) => {
  try {
    return await api.kubernetesApi.getKubernetesNodes(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch nodes' }))
    throw err
  }
})

export const getKubernetesNode = createAsyncThunk<
  KubernetesNode,
  { regionId: string; name: KubernetesNode['name'] },
  ThunkApi
>('kubernetes/getNode', async ({ regionId, name }, { dispatch, extra: { api } }) => {
  try {
    return await api.kubernetesApi.getKubernetesNode(regionId, name)
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch node' }))
    throw err
  }
})

export const updateKubernetesNode = createAsyncThunk<
  KubernetesNode,
  UpdateKubernetesNodePayload & { regionId: string; name: KubernetesNode['name'] },
  ThunkApi
>('kubernetes/updateNode', async (update, { dispatch, extra: { routes, api, history } }) => {
  try {
    const updatedNode = await api.kubernetesApi.updateKubernetesNode(update.regionId, update.name, update)
    history().push(routes.kubernetesNodes())
    dispatch(enqueueSuccessSnackbar(`Updated Node: ${update.name}`))
    return updatedNode
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update node' }))
    throw err
  }
})

export const getResourceUsage = createAsyncThunk<any, void, ThunkApi>(
  'kubernetes/getResourceUsage',
  async (_, { extra: { api } }) => {
    return api.kubernetesApi.getResourceUsage()
  },
)

export const clearKubernetesNode = createAction('kubernetes/clearNode')
export const clearKubernetesNodes = createAction('kubernetes/clearNodes')
