import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import { Table, TableProps } from './Table'

const styles = {
  right: {
    marginLeft: 'auto',
    '& > *': {
      marginLeft: (theme: Theme) => theme.spacing(2),
    },
  },
}

export * from './MoveToEntity'
export * from './Table'
export * from './Pagination'

interface TableActionsProps {
  actions?: Array<React.ReactNode>
  disableToolbar?: boolean
}

const TableActions = ({ actions, disableToolbar }: TableActionsProps) => {
  if (!actions?.length) return null

  return disableToolbar ? (
    <Box sx={{ textAlign: 'right', marginRight: 1 }}>{actions.map((action) => action)}</Box>
  ) : (
    <Toolbar disableGutters>
      <Box sx={styles.right}>{actions.map((action) => action)}</Box>
    </Toolbar>
  )
}

export interface TableContainerProps<TEntity> extends TableProps<TEntity> {
  actions?: Array<React.ReactNode>
  disableToolbar?: boolean
}
/**
 * Common container for List entities pages table
 * @param actions - buttons on top of the table (for example create entity)
 * @param rest - common table properties
 */
const TableContainer: <T>(props: TableContainerProps<T>) => React.ReactElement<TableContainerProps<T>> = ({
  actions,
  disableToolbar,
  ...rest
}) => {
  return (
    <Grid item xs={12}>
      <TableActions actions={actions} disableToolbar={disableToolbar} />
      <Paper sx={{ padding: (theme) => theme.spacing(3, 2, 1) }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table {...rest} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default TableContainer
