import { Route } from 'react-router-dom'
import { Edit } from './Edit'
import { ListView } from './List'
import { Config } from './Config'

import { useRoutes } from '../../store'

export const Appliances = () => {
  const routes = useRoutes()
  return (
    <>
      <Route path={routes.appliancesUpdate.route} component={Edit} />
      <Route exact path={routes.appliances.route} component={ListView} />
      <Route path={routes.appliancesConfig.route} component={Config} />
    </>
  )
}
