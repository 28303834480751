import { TlsCertRead } from 'common/api/v1/types'
import { ITlsApi } from './api'
import { MOCK_DELAY_MS, MOCK_TLS } from '../MockData'

export class MockTlsApi implements ITlsApi {
  getTls(): Promise<TlsCertRead> {
    return new Promise<TlsCertRead>((resolve) => setTimeout(() => resolve(MOCK_TLS), MOCK_DELAY_MS))
  }
  saveTls(): Promise<TlsCertRead> {
    return new Promise<TlsCertRead>((resolve) => setTimeout(() => resolve(MOCK_TLS), MOCK_DELAY_MS))
  }
}
