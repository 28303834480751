import { ApplianceType } from './api/v1/types'

export type VaApplianceType =
    | ApplianceType.nimbraVA220
    | ApplianceType.nimbraVA225
    | ApplianceType.nimbra410
    | ApplianceType.nimbra412
    | ApplianceType.nimbra414
    | ApplianceType.nimbra414b
    | ApplianceType.nimbraVAdocker

export function isVaApplianceType(applianceType: ApplianceType): applianceType is VaApplianceType {
    return [
        ApplianceType.nimbraVA220,
        ApplianceType.nimbraVA225,
        ApplianceType.nimbra410,
        ApplianceType.nimbra412,
        ApplianceType.nimbra414,
        ApplianceType.nimbra414b,
        ApplianceType.nimbraVAdocker,
    ].includes(applianceType)
}

export function isMatroxApplianceType(
    applianceType: ApplianceType
): applianceType is
    | ApplianceType.matroxMonarchEdgeE4_8Bit
    | ApplianceType.matroxMonarchEdgeE4_10Bit
    | ApplianceType.matroxMonarchEdgeD4
    | ApplianceType.matroxMonarchEdgeS1 {
    return [
        ApplianceType.matroxMonarchEdgeE4_8Bit,
        ApplianceType.matroxMonarchEdgeE4_10Bit,
        ApplianceType.matroxMonarchEdgeD4,
        ApplianceType.matroxMonarchEdgeS1,
    ].includes(applianceType)
}
