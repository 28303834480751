import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Filter from './Filter'
import Summary from './Summary'

const Report = () => {
  return (
    <Wrapper name="Billing">
      <Paper title="Period">
        <Filter />
      </Paper>
      <Paper title="Monthly Billing">
        <Summary />
      </Paper>
    </Wrapper>
  )
}

export default Report
