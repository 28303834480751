import { Route } from 'react-router-dom'

import AlarmLogList from './List'

import { useRoutes } from '../../store'

export const AlarmLogs = () => {
  const routes = useRoutes()
  return <Route exact path={routes.alarmLogs.route} component={AlarmLogList} />
}
