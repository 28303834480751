import { Redirect, Route, Switch } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { Role } from 'common/api/v1/types'
import { Header } from '../streamManager/TopBar'
import { List as StreamManagerMain } from '../streamManager/List/index'
import { Profile } from '../profile/index'
import { useUser } from '../../utils'
import ErrorBoundary from '../common/ErrorBoundary'
import { useRoutes } from '../../store'

export const StreamManagerContainer = () => {
  const user = useUser()
  const routes = useRoutes()

  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <ErrorBoundary>
        <Container component="main" maxWidth={false} disableGutters sx={{ flexGrow: 1, overflowX: 'auto' }}>
          <Switch>
            <Route
              path={routes.streamProfile.route}
              render={() => (
                <Box sx={{ marginBottom: 2, paddingX: 3 }}>
                  <Profile showBack />
                </Box>
              )}
            />
            <Route path={routes.stream.route} component={StreamManagerMain} />

            {user.role === Role.basic ? (
              <Route>
                <Redirect to={routes.stream()} />
              </Route>
            ) : (
              <Redirect exact from="/" to={routes.overview()} />
            )}
          </Switch>
        </Container>
      </ErrorBoundary>
    </Box>
  )
}
