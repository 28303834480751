import { ListResult, Tunnel, TunnelFilter, TunnelOutputInfo } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface ITunnelApi {
  listTunnels(filter: TunnelFilter): Promise<ListResult<Tunnel>>
  listTunnelOutputs(id: number, paginatedRequestParams: PaginatedRequestParams): Promise<ListResult<TunnelOutputInfo>>
}

export class TunnelApi implements ITunnelApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  listTunnels(filter: TunnelFilter): Promise<ListResult<Tunnel>> {
    return this.edgeClient.listTunnels({ filter })
  }

  listTunnelOutputs(id: number, paginatedRequestParams: PaginatedRequestParams): Promise<ListResult<TunnelOutputInfo>> {
    const filter = undefined
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams })
    return this.edgeClient.listTunnelOutputs(id.toString(), query)
  }
}
