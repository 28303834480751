import { IGraphApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import { EnhancedInputConfig } from 'common/api/v1/internal'

export class MockGraphApi implements IGraphApi {
  readGraph(): Promise<EnhancedInputConfig> {
    return new Promise((resolve) =>
      setTimeout(() => {
        return resolve({ streamIdToOutputMap: {}, appliances: {} })
      }, MOCK_DELAY_MS),
    )
  }
}
