import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'

import { AppDispatch, GlobalState } from '../../../store'
import { disableOutputs, DraftActions, draftOutputs } from '../../../redux/actions/outputsActions'
import { pluralize } from '../../../utils'
import { removeOutputs } from '../../../redux/actions/outputsActions'

interface CommonActionsProps {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}

const DeleteDialog = ({ setSelected }: CommonActionsProps) => {
  const { outputs, action } = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.draft, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  if (!outputs.length) return null

  const onCancelClick = () => dispatch(draftOutputs({ outputs: [] }))
  const onOkClick = () => {
    dispatch(draftOutputs({ outputs: [] }))
    if (action === DraftActions.delete) {
      dispatch(removeOutputs(outputs.map(({ id }) => id)))
    }
    if (action === DraftActions.disable) {
      dispatch(disableOutputs(outputs.map(({ id }) => id)))
      setSelected([])
    }
  }

  return (
    <Dialog
      open={outputs.length > 0 && (action === DraftActions.delete || action === DraftActions.disable)}
      data-test-id="confirm-dialog"
    >
      <DialogContent>
        <DialogContentText component="div">
          {`Are you sure you want to ${action} ${
            outputs.length > 1 ? pluralize(outputs.length, 'output') : outputs[0].name
          }?`}
          {outputs.length > 1 && (
            <ul>
              {outputs.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onCancelClick} data-test-id="cancel-btn">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onOkClick} data-test-id="ok-btn">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
