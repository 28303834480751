import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { readAlarmsInBackground } from '../../redux/actions/alarmsActions'
import { Sidebar } from '../common/SidebarMenu'
import { Dashboard } from '../dashboards/serviceProvider'
import { Inputs } from '../inputs'
import { Groups } from '../groups'
import { Users } from '../users'
import { Interfaces } from '../interfaces'
import { Appliances } from '../appliances'
import { Outputs } from '../outputs'
import { Alarms } from '../alarms'
import { BillingReport } from '../billing'
import { Graphs } from '../graph'
import { ServiceOverview } from '../serviceOverview'
import { Audit } from '../audit'
import { Regions } from '../regions'
import { Settings } from '../settings'
import { Status } from '../status'
import { AddressMappings } from '../addressMappings'
import { UsageReport } from '../usage'
import ErrorBoundary from '../common/ErrorBoundary'
import { Nodes } from '../kubernetesNodes'
import { Networks } from '../networks'
import { Services } from '../services'
import { AppDispatch } from '../../store'
import { AlarmLogs } from '../alarmLogs'
import { Profile } from '../profile/index'

import { useRoutes } from '../../store'
import { TunnelDetails } from '../appliances/Edit/ApplianceForm/Tunnels'

export const NetworkManagerContainer = () => {
  const dispatch = useDispatch<AppDispatch>()
  const routes = useRoutes()
  useEffect(() => {
    dispatch(readAlarmsInBackground({}))
  }, [dispatch])

  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'flex' }}>
      <Sidebar />
      <ErrorBoundary>
        <Container component="main" maxWidth={false} sx={{ overflowX: 'auto' }}>
          <Switch>
            <Redirect exact from="/" to={routes.overview.route} />
            <Route path={routes.overview.route} exact component={Dashboard} />
            <Route path={routes.graph.route} exact component={Graphs} />
            <Route path={routes.inputs.route} component={Inputs} />
            <Route path={routes.outputs.route} component={Outputs} />
            <Route path={routes.alarms.route} component={Alarms} />
            <Route path={routes.service.route} component={ServiceOverview} />
            <Redirect exact from={routes.settings.route} to={routes.appliances()} />
            <Route path={routes.appliances.route} component={Appliances} />
            <Route path={routes.users.route} component={Users} />
            <Route path={routes.interfaces.route} component={Interfaces} />
            <Route path={routes.networks.route} component={Networks} />
            <Route path={routes.groups.route} component={Groups} />
            <Route path={routes.billing.route} component={BillingReport} />
            <Route path={routes.usage.route} component={UsageReport} />
            <Route path={routes.audit.route} component={Audit} />
            <Route path={routes.alarmLogs.route} component={AlarmLogs} />
            <Route path={routes.addressMappings.route} component={AddressMappings} />
            <Route path={routes.regions.route} component={Regions} />
            <Route path={routes.globalSettings.route} component={Settings} />
            <Route path={routes.status.route} component={Status} />
            <Route path={routes.kubernetesNodes.route} component={Nodes} />
            <Route path={routes.services.route} component={Services} />
            <Route path={routes.tunnel.route} component={TunnelDetails} />
            <Route path={routes.profile.route} component={Profile} />

            <Route>
              <Redirect to={routes.overview()} />
            </Route>
          </Switch>
        </Container>
      </ErrorBoundary>
    </Box>
  )
}
