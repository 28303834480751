import { Output3gLevel } from 'common/api/v1/types'
import { Select } from '../../../../common/Form'

enum VaSdiFields {
  output3gLevel = 'output3gLevel',
}
export const vaSdiPortDefaults = () => ({ [VaSdiFields.output3gLevel]: Output3gLevel.B })

export const getVaSdiPortFormFields = () => {
  const fields: string[] = [VaSdiFields.output3gLevel]
  return fields
}

interface Props {
  namePrefix: string
}

const VaSdiPortForm = ({ namePrefix }: Props) => {
  return (
    <Select
      label="Output3gLevel"
      name={`${namePrefix}.${VaSdiFields.output3gLevel}`}
      required
      options={Object.values(Output3gLevel)}
    />
  )
}

export default VaSdiPortForm
