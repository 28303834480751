import { Route } from 'react-router-dom'

import Overview from './Overview'

import { useRoutes } from '../../store'

export const ServiceOverview = () => {
  const routes = useRoutes()
  return <Route exact path={routes.service.route} component={Overview} />
}
