import { createAction } from '@reduxjs/toolkit'
import { UrlParamCache } from '../reducers/urlParamReducer'

export const saveUrlParams = createAction(
  'urlParam/saveUrlParams',
  (cacheKey: keyof UrlParamCache, urlParams: UrlParamCache[typeof cacheKey]) => {
    return {
      payload: {
        key: cacheKey,
        urlParams,
      },
    }
  },
)
