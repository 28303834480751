export interface ErrorInfo {
  errorCode: string
  fatal: boolean
  text: string
  details: any
  origin: any
}

export class ApplicationException {
  constructor(public errorInfo: ErrorInfo) {}
}
