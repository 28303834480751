import { CSSProperties } from 'react'
import type { ThemeOptions } from '@mui/material/styles'

export const NimbraGreyLight = {
  50: '#E6EDFA',
  100: '#E1E8F6',
  200: '#DBE2EF',
  300: '#CED5E2',
  400: '#AEB5C2',
  500: '#9198A6',
  600: '#6C7280',
  700: '#59606E',
  800: '#3D4451',
  900: '#202631',
  1000: '#14161A',
}

export const NimbraGreyDark = {
  50: '#E6EDFA',
  100: '#E1E8F6',
  200: '#DBE2EF',
  300: '#CED5E2',
  400: '#AEB5C2',
  500: '#9198A6',
  600: '#6C7280',
  700: '#59606E',
  800: '#3D4451',
  900: '#202631',
  1000: '#14161A',
}

export const NimbraBlueLight = {
  50: '#E0ECFD',
  100: '#B2D1FF',
  200: '#7EAEF7',
  300: '#448AFA',
  400: '#1F71FF',
  500: '#0055FF',
  600: '#0041E5',
  700: '#0730C5',
  800: '#001EAA',
  900: '#052159',
}

export const NimbraBlueDark = {
  50: '#E7EDF6',
  100: '#C1D4F0',
  200: '#9AB4DB',
  300: '#6A93D4',
  400: '#4B7DD2',
  500: '#3366CC',
  600: '#2E55B7',
  700: '#30479C',
  800: '#223488',
  900: '#182746',
}

export const NimbraGrey = NimbraGreyDark
export const NimbraBlue = NimbraBlueDark

const hStyles = [36, 25, 20, 18, 16, 14].reduce<{ [key: string]: CSSProperties }>(
  (acc, item, ind) => ({
    ...acc,
    [`h${ind + 1}`]: {
      fontFamily: "'Roboto slab', sans-serif",
      fontWeight: 'bold',
      fontSize: `${item}px`,
      lineHeight: 1.6,
    },
  }),
  {},
)

export const defaultCommon: ThemeOptions = {
  palette: {
    primary: {
      main: NimbraBlue[500],
      light: NimbraBlue[300],
      dark: NimbraBlue[900],
    },
    secondary: {
      main: NimbraBlue[400],
      light: NimbraBlue[200],
      dark: NimbraBlue[800],
    },
    error: {
      main: '#D4626F',
      dark: '#C0767E',
      light: '#E84E5F',
    },
    success: {
      main: '#79D0B7',
      dark: '#8BBEAF',
      light: '#67E2BF',
    },
    warning: {
      main: '#E8CD5C',
      dark: '#D5C16F',
      light: '#FAD84A',
    },
    contrastThreshold: 3,
    grey: NimbraGrey,
  },
  typography: {
    ...hStyles,
    fontFamily: "'Roboto', sans-serif",
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: NimbraGrey[700],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        docked: {
          width: 240,
        },
        paperAnchorLeft: {
          width: 240,
          zIndex: 'unset' as const,
        },
        paperAnchorRight: {
          width: 240,
          zIndex: 'unset' as const,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${NimbraGreyLight[800]}`,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          zIndex: 'unset' as const,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            textDecoration: 'none',
            '@media (hover: none)': {
              textDecoration: 'none',
            },
          },
        },
      },
    },
  },
}
