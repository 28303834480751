import React, { useEffect, useState } from 'react'
import { matchPath } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { alpha, Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

import { NavLink } from '../Link'

const styles = {
  listItem: {
    padding: (theme: Theme) => theme.spacing(1.2, 2) + ' !important', // FIXME: Remove important
    cursor: 'pointer',
  },
  link: {
    display: 'block',
    outline: 'none',
    transition: (theme: Theme) => theme.transitions.create('background'),
    '&:hover, &:focus': {
      background: (theme: Theme) => alpha(theme.palette.common.white, 0.15),
    },
    [`&.selected, &.selected:hover`]: {
      background: (theme: Theme) => theme.palette.primary.main,
    },
  },
}

const link = ({ theme }: { theme: Theme }) => ({
  display: 'block',
  outline: 'none',
  transition: theme.transitions.create('background'),
  '&:hover, &:focus': {
    background: alpha(theme.palette.common.white, 0.15),
  },
  [`&.selected, &.selected:hover`]: {
    background: theme.palette.primary.main,
  },
})

const StyledNavLink = styled(NavLink)(link)
const LinkWrapper = styled('div')(link)

export interface Item {
  children?: Array<Item | null>
  icon?: React.ReactElement
  url?: string
  href?: string
  name: React.ReactElement | string
  iconColor?: string | false
  divider?: boolean
}
interface MenuItemProps {
  action?: () => void
  id?: string
  item: Item
  location?: string
}

/**
 * Sidebar menu item
 * @param name - text in it
 * @param url - url to lead to, including cached urlParams
 * @param href - if we need to lead somewhere outside of the system
 * @param icon - icon to show
 * @param children - array of subItems
 * @param iconColor - if wee need to set other color for the icon
 * @param location - current location to know if we need to show children expanded
 * @param action - if we need to call some action instead of leading somewhere
 * @param id
 */
const MenuItem = ({
  item: { name, url, href, icon, children, iconColor },
  location = '',
  action,
  id,
}: MenuItemProps) => {
  const shouldBeExpanded =
    children?.some((child) => {
      if (child?.url) {
        const element = document.createElement('a')
        element.href = child.url
        return matchPath(location, { path: element.pathname })
      }
      return false
    }) || false

  const [expanded, setExpanded] = useState(shouldBeExpanded)

  useEffect(() => {
    setExpanded(shouldBeExpanded)
  }, [location])

  const onClick = () => (children ? setExpanded(!expanded) : null)
  const leftPadding = '34px'
  const listItem = (
    <ListItem dense onClick={action} sx={styles.listItem} id={id} component="div">
      {!!icon && <ListItemIcon style={{ minWidth: leftPadding, color: iconColor || 'inherit' }}>{icon}</ListItemIcon>}
      <ListItemText style={{ paddingLeft: icon ? 0 : leftPadding }} primary={name} />
      {!!children && (expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />)}
    </ListItem>
  )
  if (href)
    return (
      <Link href={href} target="_blank" sx={styles.link} underline="none">
        {listItem}
      </Link>
    )
  return url ? (
    <StyledNavLink to={url} activeClassName="selected">
      {listItem}
    </StyledNavLink>
  ) : (
    <>
      <LinkWrapper tabIndex={0} onClick={onClick}>
        {listItem}
      </LinkWrapper>
      {!!children && (
        <Collapse in={expanded}>
          {children.map((child, ind) => {
            const key = child?.url || `${name}-${ind}`
            return (
              !!child && (
                <React.Fragment key={key}>
                  <MenuItem item={child} />
                  {child.divider && <Divider variant="middle" />}
                </React.Fragment>
              )
            )
          })}
        </Collapse>
      )}
    </>
  )
}

export default MenuItem
