import { DispatchProp } from 'react-redux'
import { format } from 'date-fns'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Tooltip from '../../common/Tooltip'

import { Input, ListOutputSortableField, Output, Role } from 'common/api/v1/types'

import { TableConfig } from '../../common/Table'

import { hasAccessToAppliance, inputOutputColor, isEditableGroup, outputType } from '../../../utils'
import { EnrichedOutput, EnrichedUser } from '../../../api/nm-types'
import { DATE_FORMAT_LONG, DATE_FORMAT_SHORT, formatOutputStreamContents } from 'common/api/v1/helpers'
import { Link } from '../../common/Link'
import { AutoUpdatingOutputHealthIndicator } from '../../common/Indicator'
import Thumbnail from '../../common/Thumbnail'
import { OutputListDialog } from '.'

import { ActionMenu } from './ActionMenu'
import { Routes } from '../../../utils/routes'

interface GetConfigProps {
  selected: Array<Input['id']>
  outputs: Array<Output>
  handleSelect: (id: Output['id']) => void
  handleSelectAll: () => void
  showDialog: (output: Output, type: OutputListDialog) => void
  dispatch: DispatchProp['dispatch']
  routes: Routes
  user: EnrichedUser
}

const getConfig: (props: GetConfigProps) => TableConfig<EnrichedOutput, ListOutputSortableField> = ({
  selected,
  outputs,
  handleSelect,
  handleSelectAll,
  showDialog,
  dispatch,
  routes,
  user,
}) => [
  {
    title: (
      <Checkbox
        indeterminate={selected.length > 0 && selected.length < outputs.length}
        checked={selected.length === outputs.length}
        onChange={handleSelectAll}
        inputProps={{ 'aria-label': 'select all outputs' }}
        data-test-id={'select-all'}
      />
    ),
    getValue: ({ id }) => (
      <Checkbox
        checked={selected.includes(id)}
        data-test-id="select-output"
        onChange={() => {
          handleSelect(id)
        }}
        onDoubleClick={(e) => e.stopPropagation()}
      />
    ),
    props: {
      padding: 'checkbox',
    },
  },
  {
    title: 'status',
    getValue: (output) => <AutoUpdatingOutputHealthIndicator outputId={output.id} />,
  },
  {
    title: 'preview',
    getValue: (output) => (
      <div data-test-id="thumbnail">
        <Thumbnail input={output._input} outputId={output.id} />
      </div>
    ),
    props: {
      padding: 'none',
      sx: { padding: 1, width: '100px' },
      noTypography: true,
    },
    headerProps: {},
  },
  {
    title: 'name',
    getValue: ({ name, adminStatus }) => <Typography {...inputOutputColor({ adminStatus })}>{name}</Typography>,
    sorting: {
      byParameter: ListOutputSortableField.outputName,
    },
  },

  {
    title: 'input',
    getValue: (output) => {
      const isInputOwner = user.group === output._input?.owner || user.role === Role.super
      return (
        output._input?.id && (
          <Link to={routes.inputsUpdate({ id: output._input.id })} available={isInputOwner} underline="hover">
            <Typography variant="subtitle1" {...inputOutputColor(output)}>
              {output._input?.name}
            </Typography>
          </Link>
        )
      )
    },
    sorting: {
      byParameter: ListOutputSortableField.inputName,
    },
  },
  {
    title: 'appliance',
    getValue: ({ appliances, adminStatus }) =>
      appliances.map((a) => (
        <Link
          key={a.id}
          to={routes.appliancesUpdate({ id: a.id })}
          available={hasAccessToAppliance(a, user)}
          underline="hover"
        >
          <Typography {...inputOutputColor({ adminStatus })} variant="body2">
            {a.name}
          </Typography>
        </Link>
      )),
    sorting: {
      byParameter: ListOutputSortableField.applianceName,
    },
  },
  {
    title: 'protocol',
    getValue: ({ ports }) => <Chip size="small" label={outputType(ports[0])} />,
    sorting: {
      byParameter: ListOutputSortableField.protocol,
    },
  },
  {
    title: 'format',
    getValue: ({ _input, adminStatus, ports }) => {
      if (!_input) return 'N/A'
      const { tsInfo } = _input
      const formattedOutputStreamContents = formatOutputStreamContents({ ports, tsInfo })
      return (
        <Tooltip
          title={JSON.stringify((tsInfo || [])[0]?.services?.[0]?.type?.description) || 'no info'}
          placement="top"
        >
          <Typography {...inputOutputColor({ adminStatus })} variant="body2">
            {formattedOutputStreamContents}
          </Typography>
        </Tooltip>
      )
    },
  },
  ...(user.role === Role.super
    ? [
        {
          title: 'owner',
          sorting: {
            byParameter: ListOutputSortableField.ownerGroupName,
          },
          getValue: ({ _group }: EnrichedOutput) => (
            <Link
              to={routes.groupsUpdate({ id: _group?.id })}
              available={!!_group?.id && isEditableGroup(_group?.id, user)}
              underline="hover"
            >
              <Typography variant="body2" color="textSecondary">
                {!!_group && _group.name}
              </Typography>
            </Link>
          ),
        },
      ]
    : []),
  {
    title: 'created',
    getValue: ({ createdAt, adminStatus }) => (
      <Tooltip title={format(new Date(createdAt), DATE_FORMAT_LONG)} placement="top">
        <Typography {...inputOutputColor({ adminStatus })} variant="body2">
          {format(new Date(createdAt), DATE_FORMAT_SHORT)}
        </Typography>
      </Tooltip>
    ),
    sorting: {
      byParameter: ListOutputSortableField.creationDate,
    },
  },
  {
    title: '',
    getValue: (row) => <ActionMenu output={row} dispatch={dispatch} showDialog={showDialog} />,
  },
]

export default getConfig
