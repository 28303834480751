import { Route } from 'react-router-dom'

import AuditList from './List'

import { useRoutes } from '../../store'

export const Audit = () => {
  const routes = useRoutes()
  return <Route exact path={routes.audit.route} component={AuditList} />
}
