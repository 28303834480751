import React from 'react'
import { useHistory } from 'react-router-dom'
import { DispatchProp, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '../../common/Tooltip'
import { Settings, VideocamOutlined, VideoLibrary, Delete, Info, MoreVert, Stop, PlayArrow } from '@mui/icons-material'

import { EnrichedOutput } from '../../../api/nm-types'
import { styles } from '../../../Common'
import { Output, OutputAdminStatus } from '../../../../../common/src/api/v1/types'
import { DraftActions, draftOutputs, enableOutputs } from '../../../redux/actions/outputsActions'
import { OutputListDialog } from '.'
import { AppDispatch } from 'src/store'

import { useRoutes } from '../../../store'

interface ActionMenuProps {
  output: EnrichedOutput
  dispatch: DispatchProp['dispatch']
  showDialog: (output: Output, type: OutputListDialog) => void
}

interface ToggleAdminStatusButtonProps {
  output: EnrichedOutput
  handleItemClick: (event: React.MouseEvent<HTMLElement>) => void
}

const ToggleAdminStatusButton = ({ output, handleItemClick }: ToggleAdminStatusButtonProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    handleItemClick(event)

    if (output.adminStatus === OutputAdminStatus.on) {
      dispatch(draftOutputs({ action: DraftActions.disable, outputs: [output] }))
    } else {
      dispatch(enableOutputs([output.id]))
      dispatch(draftOutputs({ outputs: [] }))
    }
  }

  return (
    <MenuItem onClick={onClick} data-test-id="toggle-admin-status-output" aria-label="toggle admin status">
      <ListItemIcon>{output.adminStatus === OutputAdminStatus.on ? <Stop /> : <PlayArrow />}</ListItemIcon>
      <ListItemText primary={output.adminStatus === OutputAdminStatus.on ? 'Disable' : 'Enable'} />
    </MenuItem>
  )
}

export const ActionMenu = (props: ActionMenuProps) => {
  const { output, dispatch, showDialog } = props
  const routes = useRoutes()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, route?: string) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (route) {
      history.push(route)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        onClick={(e) => handleItemClick(e, routes.service({ id: output._input?.id || '', outputId: output.id }))}
        data-test-id={`service-overview-btn`}
        disabled={!output._input}
      >
        <Tooltip title={'Service Overview'} placement="top">
          <Info />
        </Tooltip>
      </IconButton>
      <IconButton
        aria-controls="output-action-menu"
        aria-haspopup="true"
        data-test-id="open-output-action-menu"
        onClick={handleClick}
        sx={{ marginLeft: '-8px' }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        data-test-id="output-action-menu"
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '35ch',
          },
        }}
      >
        <MenuItem
          onClick={(e) => handleItemClick(e, routes.outputsUpdate({ id: output.id }))}
          data-test-id="edit-output"
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleItemClick(e)
            showDialog(output, OutputListDialog.selectInput)
          }}
          data-test-id="switch-input"
        >
          <ListItemIcon>
            <VideocamOutlined />
          </ListItemIcon>
          <ListItemText primary="Switch input" />
        </MenuItem>
        <ToggleAdminStatusButton output={output} handleItemClick={handleItemClick} />
        <MenuItem
          onClick={(e) => {
            handleItemClick(e)
            showDialog(output, OutputListDialog.addToOutputList)
          }}
          data-test-id="add-to-list"
        >
          <ListItemIcon>
            <VideoLibrary />
          </ListItemIcon>
          <ListItemText primary="Add to list" />
        </MenuItem>
        <MenuItem
          data-testid="delete-output"
          sx={(theme) => ({ ...styles.error(theme), '&:hover': styles.error })}
          onClick={(e) => {
            handleItemClick(e)
            dispatch(draftOutputs({ outputs: [output], action: DraftActions.delete }))
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </Box>
  )
}
