import { BillingReport } from 'common/api/v1/types'

import { BillingPayload } from '../../redux/actions/billingActions'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IBillingApi {
  readBilling(params: BillingPayload): Promise<BillingReport>
}

export class BillingApi implements IBillingApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  readBilling(params: BillingPayload): Promise<BillingReport> {
    return this.edgeClient.getBillingReport({
      startDate: params.startDate.toISOString(),
      endDate: params.endDate.toISOString(),
    })
  }
}
