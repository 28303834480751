import { InvalidParameter } from '../errors'
import { jsonStringify } from '../serialization'

export function isHttpSuccess(code: number) {
    return code >= 200 && code <= 299
}

export enum HttpStatusCode {
    Ok = 200,
    Created = 201,
    NoContent = 204,
    Found = 302,
    NotModified = 304,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    UnsupportedMediaType = 406,
    Conflict = 409,
    PayloadTooLarge = 413,
    ServerError = 500,
    NotImplemented = 501,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    OriginIsUnreachable = 523, // Cloudflare extension
}

export class HttpError extends Error {
    constructor(
        public status: HttpStatusCode,
        public type: string,
        public title: string,
        public detail: string | InvalidParameter[] = ''
    ) {
        super(
            `HTTP ${status} ${title}${
                detail ? ` (${typeof detail === 'string' ? detail : jsonStringify(detail)})` : ''
            }`
        )
    }

    toJSON() {
        const { status, title, detail, type } = this
        return { status, title, detail, type }
    }

    toString() {
        return jsonStringify(this.toJSON())
    }
}

export class GenericHttpError extends Error {
    constructor(public status: HttpStatusCode, public body: string | Record<string, any>, public headers: any) {
        super()
    }
}
