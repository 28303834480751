export function arrayToChunks<T>(array: T[], chunkSize: number) {
  const chunks: T[][] = []
  const numberOfChunks = Math.ceil(array.length / chunkSize)
  for (let i = 0; i < numberOfChunks; i++) {
    const begin = i * chunkSize
    const end = begin + chunkSize
    const chunk = array.slice(begin, end)
    chunks.push(chunk)
  }
  return chunks
}

export function distinct<T extends string | number | boolean>(original: T[]): T[] {
  return original.reduce((acc, element) => {
    if (!acc.includes(element)) {
      acc.push(element)
    }
    return acc
  }, [] as T[])
}
