import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../../common/Tooltip'
import { Delete, PlayArrow, Stop } from '@mui/icons-material'

import { Input, InputAdminStatus, Role, User } from 'common/api/v1/types'
import { pluralize, usePageParams, useUser } from '../../../utils'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { DraftActions, draftInputs, enableInputs } from '../../../redux/actions/inputsActions'
import { ReduxSelectedFilters } from '../../common/Filters/FilterView/SelectedFilters'
import { FilterPopup } from '../../common/Filters/FilterView/AddFilterPopup'
import Button from '@mui/material/Button'
import { makeListInputFilter, mapInputFilterToUrlParam, mapUrlParamToInputFilter } from './listInputFilter'
import { FilterBy, filtersToUrlParams, urlParamsToFilters } from '../../common/Filters/FilterView/FilterTemplate'
import { InputsRequestParams } from '../../../api/nm-types'

interface CommonActionsProps {
  selected: Array<Input['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  user: User
  inputs: Input[]
}

const BatchEnableButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const inputs = useSelector((state: GlobalState) => state.inputsReducer.inputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedInputs = inputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedInputs.some(({ adminStatus }) => adminStatus === InputAdminStatus.off)

  const onEnableClick = () => {
    dispatch(enableInputs(selected))
    dispatch(draftInputs({ inputs: [] }))
    setSelected([])
  }

  return (
    <Tooltip title={'Enable selected inputs'}>
      <span>
        <IconButton aria-label="Enable" disabled={!enable} onClick={onEnableClick} data-test-id="batch-enable">
          <PlayArrow />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const BatchDisableButton = ({ selected }: Pick<CommonActionsProps, 'selected'>) => {
  const inputs = useSelector((state: GlobalState) => state.inputsReducer.inputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedInputs = inputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0

  const onDisableClick = () => {
    dispatch(draftInputs({ action: DraftActions.disable, inputs: selectedInputs }))
  }

  return (
    <Tooltip title={'Disable selected inputs'}>
      <span>
        <IconButton aria-label="Disable" disabled={!enable} onClick={onDisableClick} data-test-id="batch-disable">
          <Stop />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ inputs, selected, setSelected, user }: CommonActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const { group } = useUser()
  const inputsMap = inputs.reduce<{ [key: string]: Input }>((acc, item) => ({ ...acc, [item.id]: item }), {})

  const showButtons = user.role === Role.super || selected.every((id) => inputsMap[id]?.owner === group)

  const onDeleteClick = () =>
    dispatch(draftInputs({ action: DraftActions.delete, inputs: selected.map((id) => inputsMap[id]) }))

  const [filterPopupAnchor, setFilterPopupAnchor] = React.useState<HTMLElement | undefined>(undefined)
  const closePopup = () => {
    setFilterPopupAnchor(undefined)
  }
  const closePopupAndSaveFilters = (savedFilters: FilterBy[]) => {
    closePopup()
    populateUrlWithSelectedFilters(savedFilters)
  }

  const [_, setUrlParams] = usePageParams<Omit<InputsRequestParams, 'rowsPerPage' | 'asc' | 'desc'>>()
  const { cachedUrlParams } = useSelector(({ urlParamReducer }: GlobalState) => urlParamReducer, shallowEqual)
  const cachedRequestParams = cachedUrlParams['inputs']
  const appliedFilters = cachedRequestParams ? urlParamsToFilters(cachedRequestParams, mapUrlParamToInputFilter) : []
  const populateUrlWithSelectedFilters = (filters: FilterBy[]) => {
    const urlParams = filtersToUrlParams(filters, appliedFilters, mapInputFilterToUrlParam)
    setUrlParams({ ...urlParams, pageNumber: '0' }) // Reset page to 0 when applying filters
  }

  return (
    <Toolbar disableGutters>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button variant="contained" onClick={(e) => setFilterPopupAnchor(e.currentTarget)}>
          Add filter
        </Button>
        <FilterPopup
          popupAnchor={filterPopupAnchor}
          onApplyClicked={closePopupAndSaveFilters}
          onCancelClicked={closePopup}
          initialFilters={appliedFilters}
          availableFilters={makeListInputFilter({
            groupApi: Api.groupsApi,
            regionApi: Api.regionApi,
            applianceApi: Api.appliancesApi,
          })}
        />
        <ReduxSelectedFilters
          urlParamCacheKey={'inputs'}
          mapUrlParamToFilterFn={mapUrlParamToInputFilter}
          mapFilterToUrlParamFn={mapInputFilterToUrlParam}
        />
      </div>

      <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {!!selected.length && (
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            {pluralize(selected.length, 'input')} selected
          </Typography>
        )}
        {!!selected.length && showButtons && (
          <div style={{ marginLeft: 8 }}>
            <Tooltip title={'Delete selected inputs'}>
              <span>
                <IconButton aria-label="batch delete" data-test-id="batch-delete" onClick={onDeleteClick}>
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
            <BatchDisableButton selected={selected} />
            <BatchEnableButton selected={selected} setSelected={setSelected} />
          </div>
        )}
      </div>
    </Toolbar>
  )
}

export default CommonActions
