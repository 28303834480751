import { GlobalState } from './store'

const EDGE_STATE_KEY = 'edge-state'
/**
 * Loading save on page reopen
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(EDGE_STATE_KEY)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
    return undefined
  }
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
}

/**
 * Saving state to use on page reopen
 * @param state: GlobalState - the state to save
 */
export const saveState = (state: DeepPartial<GlobalState>) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(EDGE_STATE_KEY, serializedState)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
  }
}

export const clearState = () => {
  localStorage.removeItem(EDGE_STATE_KEY)
}

export const getItem = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
  }
  return null
}
